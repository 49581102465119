const artikelHiddenColumns = [
  'inkoop_prijs',
  'btw',
  'leveranciersnummer',
  'leverancier_artikelnr',
  'besteleenheid',
  'inhoud',
  'vraag',
  'breuk',
  'besteld',
  'voorraad'
];

export default artikelHiddenColumns;