import React, { Fragment } from 'react';
import { Table, FormControl, InputGroup, Badge } from 'react-bootstrap';
import { FaGlobeEurope, FaPhone } from 'react-icons/fa';
import InspectButton from '../../Helpers/InspectButton';
import mergeRefs from '../../Helpers/mergeRefs';

const BesteladviesTable = ({ leveranciers, advies, register, adviesState, setAdviesState }) => {
  const toggleRoot = () => {
    setAdviesState((prevState) => {
      const leveranciers = prevState.leveranciers.map(leverancier => {
        leverancier.checked = !prevState.checked;
        leverancier.indeterminate = false;
        leverancier.artikelen.map(artikel => {
          artikel.checked = !prevState.checked;
          return artikel;
        });
        return leverancier;
      });
      return({
        ...prevState,
        checked: !prevState.checked,
        indeterminate: false,
        leveranciers
      });
    });
  };

  const toggleLeverancier = leveranciersNummer => {
    setAdviesState(prevState => {
      let count = 0;
      const leveranciers = prevState.leveranciers.map(leverancier => {
        if (leverancier.id === leveranciersNummer) {
          const newCheckedState = !leverancier.checked;
          leverancier.checked = newCheckedState;
          leverancier.indeterminate = false;
          // eslint-disable-next-line no-unused-vars
          let countArtikelen = 0;
          leverancier.artikelen.map(artikel => {
            artikel.checked = newCheckedState;

            if (artikel.checked)
              countArtikelen++;

            return artikel;
          });
        }

        if (leverancier.checked || leverancier.indeterminate)
          count++;

        return leverancier;
      });

      return ({
        ...prevState,
        checked: count === leveranciers.length,
        indeterminate: count < leveranciers.length && count > 0,
        leveranciers
      })
    });
  };

  const toggleArtikel = artikelId => {
    // toggle and indeterminate state example
    // https://chakra-ui.com/docs/form/checkbox#indeterminate
    //
    setAdviesState(prevState => {
      let count = 0;
      const leveranciers = prevState.leveranciers.map(leverancier => {
        let countArtikelen = 0;
        leverancier.artikelen.map(artikel => {
          if (artikel.id === parseInt(artikelId))
            artikel.checked = !artikel.checked;

          if (artikel.checked)
            countArtikelen++;

          return artikel;
        });

        leverancier.checked = countArtikelen === leverancier.artikelen.length;
        leverancier.indeterminate = countArtikelen > 0 && countArtikelen < leverancier.artikelen.length;

        if (leverancier.checked || leverancier.indeterminate)
          count++;

        return leverancier;
      });

      return ({
        ...prevState,
        checked: count === leveranciers.length,
        indeterminate: count < leveranciers.length && count > 0,
        leveranciers
      })
    });
  }

  const handleCheck = event => {
    const { name, value } = event.target;
    if (name === 'root')
      toggleRoot();

    if (name === 'leverancier')
      toggleLeverancier(value);

    if (name === 'artikel')
      toggleArtikel(value);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setAdviesState(prevState => {
      const leveranciers = prevState.leveranciers.map(leverancier => {

        leverancier.artikelen.map(artikel => {
          if (artikel.id === parseInt(name))
            artikel.aantal = value;

          return artikel;
        });

        return leverancier;
      });

      const nextState = {
        ...prevState,
        leveranciers
      };

      return (nextState);
    });
  }

  return (
    <Table striped bordered hover size="md">
      <colgroup>
         <col span={4}/>
         <col span={1} style={{ borderRight: "3px double #ccc" }} />
         <col span={3} />
         <col span={1} style={{ borderRight: "3px double #ccc" }} />
       </colgroup>
      <thead className="thead-dark">
        <tr>
          <th>
            <input
              type="checkbox"
              name={`root`}
              checked={adviesState && adviesState.checked}
              value={adviesState && adviesState.checked}
              ref={el => el && (el.indeterminate = adviesState.indeterminate)}
              onChange={e => handleCheck(e)}
            />
          </th>
          <th>#</th>
          <th>Omschrijving</th>
          <th>Besteleenheid</th>
          <th>Nodig <small>(stuks)</small></th>
          <th>Voorraad</th>
          <th>Besteld</th>
          <th>Breuk</th>
          <th>Totaal</th>
          <th>Advies</th>
        </tr>
      </thead>
      <tbody>
        {adviesState.leveranciers && adviesState.leveranciers.map((leverancier, index) => {
          let leverancierData = null;
          if (leveranciers) {
            leverancierData = leveranciers.find(lev => lev.nummer === leverancier.id);
          }
          return leverancierData ? (
            <Fragment key={`leverancier-${index}`}>
              <tr className="table-secondary">
                <td colSpan={2} className="align-middle">
                  <input
                    type="checkbox"
                    name={`leverancier`}
                    value={leverancier.id}
                    checked={leverancier.checked}
                    ref={mergeRefs(register, el => el && (el.indeterminate = leverancier.indeterminate))}
                    onChange={e => handleCheck(e)}
                  />
                  <input
                    type="hidden"
                    name={`leveranciers[${leverancier.id}]`}
                    value={leverancier.checked}
                    ref={register}
                  />
                </td>
                <td colSpan={8} className="align-middle">
                  <InspectButton
                    variant="link"
                    to={`/leveranciers/edit/${leverancier.id}`}
                  >{leverancierData.naam}</InspectButton>
                  {' '}
                  {leverancierData.website && (
                    <a href={leverancierData.website}>
                      <Badge
                        variant="info"
                      ><FaGlobeEurope /> Website</Badge>
                    </a>
                  )}
                  {leverancierData.telefoonnummer && (
                    <>
                    {' '}
                    <Badge
                      variant="info"
                    ><FaPhone /> {leverancierData.telefoonnummer}</Badge>
                    </>
                  )}
                </td>
              </tr>
              {leverancier.artikelen && leverancier.artikelen.map((artikel, aIndex) => {
                return (
                  <BesteladviesTableRow
                    key={`${aIndex}-artikel`}
                    artikel={artikel}
                    register={register}
                    handleCheck={e => handleCheck(e)}
                    handleInputChange={e => handleInputChange(e)}
                  />
                )
              })}
            </Fragment>
          ) : (<Fragment key={index} />)
        })}
      </tbody>
    </Table>
  );
};

const BesteladviesTableRow = ({ artikel, register, handleCheck, handleInputChange }) => {
  return (
    <tr>
      <td className="align-middle">
        <input
          type="checkbox"
          name="artikel"
          checked={artikel.checked}
          value={artikel.artikel.id}
          ref={register}
          onChange={handleCheck}
        />
      </td>
      <td className="align-middle">
        <InspectButton
          variant="outline-primary"
          to={`/artikelen/show/${artikel.artikel.artikelnr}`}
        >#{artikel.artikel.artikelnr}</InspectButton>
      </td>
      <td className="align-middle">{artikel.artikel.omschrijving}</td>
      <td className="align-middle">{artikel.artikel.besteleenheid} à {artikel.artikel.inhoud}</td>
      <td className="align-middle">{artikel.artikel.vraag !== '0' ? artikel.artikel.vraag : "-"}</td>
      <td className="align-middle">{artikel.artikel.voorraad === 0 ? '-' : artikel.artikel.voorraad}</td>
      <td className="align-middle">{artikel.artikel.besteld === 0 ? '-' : artikel.artikel.besteld}</td>
      <td className="align-middle">{artikel.artikel.breuk === 0 ? '-' : artikel.artikel.breuk}</td>
      <td className="align-middle">{artikel.artikel.totaal === 0 ? '-' : artikel.artikel.totaal}</td>
      <td className="align-middle">
        <InputGroup>
          <FormControl
            name={artikel.artikel.id}
            placeholder={'-'}
            defaultValue={artikel.artikel.besteladvies_besteleenheid}
            onChange={handleInputChange}
            ref={register}
            autoComplete={'off'}
          />
          <InputGroup.Append>
            <InputGroup.Text>{artikel.artikel.besteleenheid} à {artikel.artikel.inhoud}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </td>
    </tr>
  )
};

export default BesteladviesTable;