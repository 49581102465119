const apiOperators = [
  {
    operator: 'Bevat',
    key: 'LIKE'
  },
  {
    operator: '=',
    key: 'eql'
  },
  {
    operator: '!=',
    key: 'neql'
  },
  {
    operator: '<',
    key: 'lt'
  },
  {
    operator: '<=',
    key: 'lte'
  },
  {
    operator: '>',
    key: 'gt'
  },
  {
    operator: '>=',
    key: 'gte'
  }
];

export default apiOperators;