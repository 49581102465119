import { FaTruck } from 'react-icons/fa';
import { formatCurrency, getBtwStringShorthand } from '../../../../Helpers/Helpers';
import InspectButton from '../../../../Helpers/InspectButton';

const tableColumns = [
  {
    Header: 'Artikelnr',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/artikelen/show/${row.artikelnr}`}
            variant="outline-primary"
          >{row.artikelnr}</InspectButton>
        </>
      );
    },
    sortType: 'basic',
    id: 'artikelnr'
  },
  {
    Header: 'Omschrijving',
    accessor: 'omschrijving',
    sortType: 'basic',
    id: 'omschrijving'
  },
  {
    Header: 'Prijs (inkoop)',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.inkoop_prijs)}</>
      )
    },
    sortType: 'basic',
    id: 'inkoop_prijs'
  },
  {
    Header: 'Prijs (verkoop)',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.verkoop_prijs)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoop_prijs'
  },
  {
    Header: 'BTW',
    accessor: (row, i) => {
      return (
        <>{getBtwStringShorthand(row.btw)}</>
      )
    },
    sortType: 'basic',
    id: 'btw'
  },
  {
    Header: 'Leverancier',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            icon={<FaTruck />}
            to={`/leveranciers/edit/${row.leverancier.nummer}`}
            variant="outline-primary"
          >{row.leverancier.naam}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'leverancier_bedrijfsnaam'
  },
  {
    Header: 'Leveranciersnummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            icon={<FaTruck />}
            to={`/leveranciers/edit/${row.leverancier.nummer}`}
            variant="outline-primary"
          >#{row.leverancier.nummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'leveranciersnummer'
  },
  {
    Header: 'Artikelnr (leverancier)',
    accessor: (row, i) => {
      return (
        <>{row.leverancier_artikel_nummer}</>
      )
    },
    sortType: 'basic',
    id: 'leverancier_artikelnr'
  },
  {
    Header: 'Verpakking',
    accessor: (row, i) => {
      return (
        <>
          {row.besteleenheid} à {row.inhoud}
        </>
      )
    },
    disableSortBy: true,
    id: 'besteleenheid_inhoud'
  },
  {
    Header: 'Besteleenheid',
    accessor: (row, i) => {
      return (
        <>{row.besteleenheid}</>
      )
    },
    sortType: 'basic',
    id: 'besteleenheid'
  },
  {
    Header: 'Inhoud',
    accessor: (row, i) => {
      return (
        <>{row.inhoud}</>
      )
    },
    sortType: 'basic',
    id: 'inhoud'
  },
  {
    Header: 'Voorraad',
    accessor: (row, i) => {
      return (
        <>{row.voorraad === 0 ? '-' : row.voorraad}</>
      )
    },
    sortType: 'basic',
    id: 'voorraad'
  },
  {
    Header: 'Nodig',
    accessor: (row, i) => {
      return (
        <>{row.vraag === 0 ? '-' : row.vraag}</>
      )
    },
    sortType: 'basic',
    id: 'vraag'
  },
  {
    Header: 'Breuk',
    accessor: (row, i) => {
      return (
        <>{row.breuk === 0 ? '-' : row.breuk}</>
      )
    },
    sortType: 'basic',
    id: 'breuk'
  },
  {
    Header: 'Besteld',
    accessor: (row, i) => {
      return (
        <>{row.besteld === 0 ? '-' : row.besteld}</>
      )
    },
    sortType: 'basic',
    id: 'besteld'
  }
];

export default tableColumns;