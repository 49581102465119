import React from 'react';
import {
  ButtonGroup,
  DropdownButton
} from 'react-bootstrap';
import InpakopdrachtApiService from '../../Services/InpakopdrachtService';
import PalletApiService from '../../Services/PalletService';
import VrachtbriefApiService from '../../Services/VrachtbriefService';
import VerkooporderApiService from '../../Services/VerkooporderService';
import OrderDropdownItems from './Actions/OrderDropdownItem';
import OfferteApiService from '../../Services/OfferteService';

const DropdownActionsVerkooporder = ({ refresh, ...props }) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      title="Print &amp; download"
      id="acties"
    >
      <OrderDropdownItems
        id={props.inpakopdrachten
          && props.inpakopdrachten[props.inpakopdrachten.length - 1]
          ? props.inpakopdrachten[props.inpakopdrachten.length - 1].id
          : null
        }
        api={InpakopdrachtApiService}
        label={'Inpakopdracht'}
        hideDivider
      />
      <OrderDropdownItems
        id={props.inpakopdrachten
          && props.inpakopdrachten[props.inpakopdrachten.length - 1]
          ? props.inpakopdrachten[props.inpakopdrachten.length - 1].id
          : null
        }
        api={PalletApiService}
        label={'Palletformulieren'}
      />
      <OrderDropdownItems
        id={props.vrachtbrieven
          && props.vrachtbrieven[props.vrachtbrieven.length - 1]
          ? props.vrachtbrieven[props.vrachtbrieven.length - 1].id
          : null
        }
        api={VrachtbriefApiService}
        label={'Vrachtbrief'}
        disabled={props.verkooporder.status < 14}
      />

      <OrderDropdownItems
        id={props.verkooporder.id}
        api={VerkooporderApiService}
        label={'Orderbevestiging'}
      />
      <OrderDropdownItems
        id={props.verkooporder.offerte
          ? props.verkooporder.offerte.offertenummer
          : null
        }
        api={OfferteApiService}
        label={'Offerte'}
        hideDivider
      />
    </DropdownButton>
  );
};

export default DropdownActionsVerkooporder;