import React, { useContext } from 'react';
import {
  Card,
  AccordionContext,
  useAccordionToggle,
  Accordion
} from 'react-bootstrap';
import {
  FaCaretDown,
  FaCaretUp
} from 'react-icons/fa';

const ContextAwareToggle = ({ children, eventKey, callback, element=Card.Header }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle
      as={element}
      eventKey="0"
      className="d-flex justify-content-between"
      onClick={decoratedOnClick}
    >
      {children}
      {isCurrentEventKey ? (
        <FaCaretUp />
      ) : (
        <FaCaretDown />
      )}
    </Accordion.Toggle>
  );
};

export default ContextAwareToggle;