import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExchangeAlt, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import useShowing from '../../Helpers/Hooks/useShowing';
import DefaultModal from '../../Helpers/DefaultModal';

import apiService from '../../Services/VoorraadService'
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import StockLocationDetails from './StockLocationDetails';
import filledLocationFilter from '../../Helpers/Filters/filledLocationFilter';
import locationQuantityReducer from '../../Helpers/Reducers/locationQuantityReducer';

const MutateStock = ({
  product,
  mutations,
  locationsWithQuantity,
  setLocationsWithQuantity = () => {},
  updateMutations = () => {}
}) => {
  const {
    notifications,
    addErrorNotification,
    addSuccessNotification,
    removeNotifications,
  } = useAlertNotifications();

  const initialNewMutation = {
    mutator: 'increase',
    quantity: '',
    location: '',
    move_to_location: '',
  };

  const { isShowing, toggle } = useShowing();
  const [newMutation, setNewMutation] = useState(initialNewMutation);

  const [mutator, setMutator] = useState({
    description: 'bijboeken',
    icon: faPlus
  });

  useEffect(() => {
    if (mutations) {
      setLocationsWithQuantity(Object.entries(mutations.reduce(locationQuantityReducer, {})).filter(filledLocationFilter));
    }
  }, [mutations]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setNewMutation(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
    updateBtnDescription(name, value);
  };

  const updateBtnDescription = (name, value) => {
    if (name === 'mutator') {
      if (value === 'increase') {
        setMutator({
          ...mutator,
          description: 'bijboeken',
          icon: faPlus
        });
      }
      if (value === 'decrease') {
        setMutator({
          ...mutator,
          description: 'afboeken',
          icon: faMinus
        });
      }
      if (value === 'move') {
        setMutator({
          ...mutator,
          description: 'verplaatsen',
          icon: faExchangeAlt
        });
      }
    }
  }

  // TODO move this to edit artikel component
  const handleSubmit = () => {
    if (newMutation.quantity <= 0) {
      addErrorNotification(`Het ingevulde aantal is onjuist. Min getallen of 0 (nul) zijn niet toegestaan`);
      return;
    }

    apiService.mutateStock(product.id, newMutation)
      .then(response => {
        updateMutations(response.data.voorraad_mutations);
        const newMutations = response.data.voorraad_mutations ?? [];
        setLocationsWithQuantity(Object.entries(newMutations.reduce(locationQuantityReducer, {})).filter(filledLocationFilter));
        addSuccessNotification('Mutatie is succesvol toegevoegd');
      })
      .catch(e => {
        addErrorNotification('Er is iets mis gegaan bij het toevoegen van de mutatie.', e.response.status);
      });
  };

  return (
    <>
      <Button
        size={'sm'}
        variant={'outline-danger'}
        onClick={() => {
          toggle();
          setNewMutation(initialNewMutation);
          removeNotifications();
        }}
      ><FontAwesomeIcon icon={faEdit} /></Button>
      <DefaultModal
        isShowing={isShowing}
        hide={toggle}
        closeButton
        headerClass={'bg-dark text-light'}
        header={<span><FontAwesomeIcon icon={mutator.icon} /> Voorraad mutatie voor artikel #{product.artikelnr} {mutator.description}</span>}
        body={
          <>
            <AlertNotifications
              notifications={notifications}
              showAmount={1}
            />
            <Form>
              <Form.Group as={Form.Row}>
                <Form.Label column md={3}>Handeling:</Form.Label>
                <Col md={9}>
                  <Form.Control
                    as={'select'}
                    custom
                    required
                    name={'mutator'}
                    value={newMutation.mutator}
                    onChange={handleInputChange}
                  >
                    <option value="increase">(+) Ophogen/bijboeken</option>
                    <option value="decrease">(-) Verlaag/afboeken</option>
                    <option value="move">Verplaatsen</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column md={3}>Aantal:</Form.Label>
                <Col md={9}>
                  <InputGroup>
                    <Form.Control
                      type={'text'}
                      placeholder={0}
                      required
                      value={newMutation.quantity}
                      name={'quantity'}
                      onChange={handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ST</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Control.Feedback
                    type={'text'}
                    className={'text-muted'}
                  >Verplicht veld, aantal stuks, 1 of hoger</Form.Control.Feedback>
                </Col>
              </Form.Group>
              {newMutation.mutator === 'move' && (
                <Form.Group as={Form.Row}>
                  <Form.Label column md={3}>Van locatie:</Form.Label>
                  <Col md={9}>
                    <Form.Control
                      type={'text'}
                      placeholder={'...'}
                      required
                      value={newMutation.from_location}
                      name={'from_location'}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Form.Row}>
                <Form.Label column md={3}>{newMutation.mutator === 'move' ? 'Naar locatie' : 'Locatie'}:</Form.Label>
                <Col md={9}>
                  <Form.Control
                    type={'text'}
                    placeholder={'...'}
                    required
                    value={newMutation.destination_location}
                    name={'destination_location'}
                    onChange={handleInputChange}
                  />
                </Col>
              </Form.Group>
            </Form>
            {locationsWithQuantity && (
              <Row>
              <Col md={3}>
                Locatie | voorraad
              </Col>
              <Col md={9}>
                {locationsWithQuantity.map(
                  (entry, index) => <StockLocationDetails key={index} location={entry[0] ?? null} quantity={entry[1] ?? null}/>
                )}
              </Col>
            </Row>
            )}
          </>
        }
        footer={
          <>
            <Button
              variant={'secondary'}
              onClick={() => toggle(!isShowing)}
            >Sluiten</Button>
            <Button
              variant={'success'}
              onClick={handleSubmit}
            ><FontAwesomeIcon icon={mutator.icon} /> Vooraad {mutator.description}</Button>
          </>
        }
      />
    </>
  );
};

export default MutateStock;