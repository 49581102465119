const hiddenColumns = [
  'adres',
  'postcode',
  'land',
  'webshop',
  'website',
  'emailadres',
  'emailadres_order',
  'telefoonnummer',
  'mobielnummer',
  'contactpersoon',
  'aantal_artikelen'
];

export default hiddenColumns;