const hiddenColumns = [
  'klantnummer',
  'offerte_id',
  'mededeling',
  'offertenummer',
  'verkoopprijs_totaal_btw_hoog',
  'verkoopprijs_totaal_btw_laag',
  'verkoopprijs_totaal_btw_nul',
  'verkoopprijs_totaal_incl',
];

export default hiddenColumns;