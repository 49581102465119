const setToken = (token) => {
  localStorage.setItem('token', token);
};

const getToken = () => {
  if (hasToken()) {
    return JSON.parse(localStorage.getItem('token'));
  }

  return null;
};

const removeToken = () => {
  localStorage.removeItem('token');
};

const hasToken = () => {
  return !!localStorage.getItem('token');
};

const clear = () => {
  localStorage.clear();
};

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const hasUser = () => {
  return !!localStorage.getItem('user');
};

const removeUser = () => {
  localStorage.removeItem('user');
};

const isLoggedIn = () => {
  return getUser() && getToken();
};

const logout = () => {
  removeToken();
  removeUser();
  addLastLogout();
  if (typeof window !== 'undefined') {
    window.dispatchEvent(new Event('local-storage'));
  }
};

const addLastLogout = () => {
  localStorage.setItem('last-logout', JSON.stringify({
    date: Date.now()
  }));
};

export default {
  getToken,
  setToken,
  removeToken,
  hasToken,
  setUser,
  getUser,
  hasUser,
  removeUser,
  clear,
  logout,
  isLoggedIn,
};