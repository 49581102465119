import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import menuItems from '../../menuItems';

const SidebarGroup = ({ title, children }) => {
  return (
    <>
      {title && (
      <li className={`
        sidebar-heading
        d-flex
        justify-content-between
        align-items-center
        px-2
        mt-1
        mb-0
        text-muted
      `}>
        {title}
      </li>
      )}
      {children}
    </>
  );
};

const SidebarNavItem = ({ label, pathTo, icon, exact }) => {
  return (
    <li className="nav-item">
      <LinkContainer
        exact={exact}
        to={pathTo}
      >
        <Nav.Link className="nav-link">
          <span className="feather">{icon}</span> {" "}
          <span className="link-text">{label}</span>
        </Nav.Link>
      </LinkContainer>
    </li>
  );
};

const Sidebar = ({ bgImage = '' }) => {
  return (
    <nav
      id="sidebarMenu"
      className={`
        col-md-3 col-lg-2 d-md-block bg-light sidebar collapse
      `}
      style={{backgroundImage: `url(${bgImage})`}}
    >
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
        {menuItems && menuItems.map((group, index) => {
          return (
            <SidebarGroup key={`group-${index}`} title={group.groupName}>
              {group.items && group.items.map((item, i) => {
                return (
                  <SidebarNavItem
                    key={`nav-item-${index}-${i}`}
                    label={item.label}
                    icon={item.icon}
                    pathTo={item.pathTo}
                    exact={item.exact}
                  />
                )
              })}
            </SidebarGroup>
          );
        })}
      </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
