import ajax, { api } from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';

const update = (id, data) => {
  return ajax.put(`${api}/vrachtbrieven/${id}`, data);
};

const create = (data) => {
  return ajax.post(`${api}/vrachtbrieven`, data);
};

const get = (id) => {
  return ajax.get(`${api}/vrachtbrieven/${id}`);
};

const getAll = () => {
  return ajax.get(`${api}/vrachtbrieven`);
};

const getBase64PDF = (id) => {
  return ajax.get(`${api}/vrachtbrieven/pdf/base64/${id}`);
};

const downloadPDF = async (id, filename) => {
  const response = await ajax.get(`${api}/vrachtbrieven/pdf/download/${id}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id) => {
  try {
    const response = await getBase64PDF(id);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
};

export default {
  create,
  update,
  get,
  getAll,
  getBase64PDF,
  downloadPDF,
  printPDF
};