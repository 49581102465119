import {
  Switch,
  Route
} from 'react-router-dom';

import ArtikelList from './Components/Artikelen/ArtikelList';
import AddArtikel from './Components/Artikelen/AddArtikel';
import ShowArtikel from './Components/Artikelen/ShowArtikel';
import EditArtikel from './Components/Artikelen/EditArtikel';

import LeverancierList from './Components/Leveranciers/LeverancierList';
import AddLeverancier from './Components/Leveranciers/AddLeverancier';
import EditLeverancier from './Components/Leveranciers/EditLeverancier';

import KlantList from './Components/Klanten/KlantList';
import AddKlant from './Components/Klanten/AddKlant';
import EditKlant from './Components/Klanten/EditKlant';

import AddPakket from './Components/Pakketten/AddPakket';
import PakkettenList from './Components/Pakketten/PakkettenList';
import PakketSamenStellen from './Components/Pakketten/PakketSamenstellen';

import OfferteList from './Components/Offertes/OfferteList';
import EditOfferte from './Components/Offertes/EditOfferte';
import ViewOfferte from './Components/Offertes/ViewOfferte';
import MutateOfferte from './Components/Offertes/MutateOfferte';

import EditVerkooporder from './Components/Verkooporders/EditVerkooporder';
import AddVerkooporder from './Components/Verkooporders/AddVerkooporder';
import VerkooporderList from './Components/Verkooporders/VerkooporderList';
import MutateVerkooporder from './Components/Verkooporders/MutateVerkooporder';

import BesteladviesList from './Components/Inkoop/BesteladviesList';
import InkooporderHistoryList from './Components/Inkoop/InkooporderHistoryList';
import InkooporderView from './Components/Inkoop/InkooporderView';
import VoorraadList from './Components/Voorraad/VoorraadList';
import PalletList from './Components/Pallets/PalletList';

import OrdersOpVoorraadList from './Components/Planning/OrdersOpVoorraadList';
import OrdersNietOpVoorraadList from './Components/Planning/OrdersNietOpVoorraadList';
import InpakopdrachtenList from './Components/Planning/InpakopdrachtenList';
import EditInpakopdracht from './Components/Planning/EditInpakopdracht';

import NotFound from './Components/NotFound';
import Dashboard from './Components/Dashboard';
import VrachtbriefList from './Components/Vrachtbrieven/VrachtbriefList';

import GeneralInfoView from './Components/GeneralInfo/GeneralInfoView';
import SidebarBackgroundSelector from './Components/UserConfig/SidebarBackgroundSelector';
import Test from './Components/Test';

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Dashboard />
    </Route>

    <Route exact path="/test">
      <Test />
    </Route>

    <Route path="/algemene-informatie" component={GeneralInfoView} />

    <Route path="/artikelen/show/:artikelnr?">
      <ShowArtikel />
    </Route>
    <Route path="/artikelen/edit/:artikelnr?">
      <EditArtikel />
    </Route>
    <Route path="/artikelen/add/:artikelnr?">
      <AddArtikel />
    </Route>
    <Route path="/artikelen">
      <ArtikelList />
    </Route>

    <Route path="/leveranciers/edit/:nummer" component={EditLeverancier} />
    <Route path="/leveranciers/add" component={AddLeverancier} />
    <Route path="/leveranciers" component={LeverancierList} />

    <Route path="/klanten/edit/:nummer" component={EditKlant} />
    <Route path="/klanten/add" component={AddKlant} />
    <Route path="/klanten" component={KlantList} />

    <Route path="/pakketten/add" component={AddPakket} />
    <Route path="/pakketten/samenstellen/:id?" component={PakketSamenStellen} />
    <Route path="/pakketten" component={PakkettenList} />

    <Route path="/offertes/mutate/:id" component={MutateOfferte} />
    <Route path="/offertes/edit/:offertenummer?" component={EditOfferte} />
    <Route path="/offertes/view/:offertenummer" component={ViewOfferte} />
    <Route path="/offertes" component={OfferteList} />

    <Route path="/verkooporders/mutate/:id" component={MutateVerkooporder} />
    <Route path="/verkooporders/edit/:ordernummer" component={EditVerkooporder} />
    <Route path="/verkooporders/add/:offertenummer" component={AddVerkooporder} />
    <Route path="/verkooporders" component={VerkooporderList} />

    <Route path="/besteladvies" component={BesteladviesList} />

    <Route path="/inkoop/order/:ordernummer" component={InkooporderView} />
    <Route path="/inkoop" component={InkooporderHistoryList} />

    <Route path="/voorraad" component={VoorraadList} />
    <Route path="/pallets" component={PalletList} />

    <Route path="/planning/orders-op-voorraad" component={OrdersOpVoorraadList} />
    <Route path="/planning/orders-niet-op-voorraad" component={OrdersNietOpVoorraadList} />
    <Route path="/planning/inpakopdrachten/:id" component={EditInpakopdracht} />
    <Route path="/planning/inpakopdrachten" component={InpakopdrachtenList} />
    <Route path="/planning/vrachtbrieven" component={VrachtbriefList} />

    <Route path="/config/sidebar" component={SidebarBackgroundSelector} />

    <Route>
      <NotFound />
    </Route>
  </Switch>
);

export default Routes;