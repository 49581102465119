import ajax, { api } from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';

import { getPaginatedUrl } from '../Helpers/Helpers';


const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl(`${api}/pallets`, page, perPage, search, sort, operator);
};

const getBase64PDF = (id) => {
  return ajax.get(`/pallets/pdf/base64/${id}`);
}

const downloadPDF = async (id, filename) => {
  const response = await ajax.get(`/pallets/pdf/download/${id}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id) => {
  try {
    const response = await getBase64PDF(id);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
}

export default {
  getAllUrl,
  getBase64PDF,
  downloadPDF,
  printPDF
};