const searchKeys = [
  {
    value: 'nummer',
    label: 'Leveranciersnummer'
  },
  {
    value: 'naam',
    label: 'Naam'
  },
  {
    value: 'adres',
    label: 'Adres'
  },
  {
    value: 'postcode',
    label: 'Postcode'
  },
  {
    value: 'plaatsnaam',
    label: 'Plaatsnaam'
  },
  {
    value: 'land',
    label: 'Land (NL, DE, BE, etc.)'
  },
  {
    value: 'contactpersoon',
    label: 'Contactpersoon'
  },
  {
    value: 'telefoonnummer',
    label: 'Telefoonnummer'
  },
  {
    value: 'mobielnummer',
    label: 'Mobielnummer'
  },
  {
    value: 'emailadres_order',
    label: 'Emailadres (order)'
  },
  {
    value: 'emailadres',
    label: 'Emailadres (contact)'
  },
  {
    value: 'website',
    label: 'Website'
  },
  {
    value: 'webshop',
    label: 'Webshop (0 = nee, 1 = ja)'
  }
];

export default {
  default: 'nummer',
  searchKeys
};