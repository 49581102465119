import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "react-bootstrap";
import useLocalStorage from "../../Helpers/Hooks/useLocalStorage";

const LogoutToast = () => {
  const [lastLogout, setLastLogout] = useLocalStorage('last-logout', null);
  const delayTimeInMs = 15 * 1000 * 6;

  const getWhenMessage = () => {
    const elapsedTime = Date.now() - lastLogout.date;
    const elapsedTimeInSeconds = Math.floor(elapsedTime / 1000);
    const elapsedTimeInMinutes = Math.floor(elapsedTime / 1000 / 60);

    if (elapsedTime > delayTimeInMs) {
      close();
    }

    if (elapsedTimeInSeconds > 1 && elapsedTimeInSeconds < 60) {
      return 'afgelopen minuut';
    } else if (elapsedTimeInMinutes >= 1) {
      return `${elapsedTimeInMinutes} minuten geleden`;
    }

    return `nu net`;
  };

  const close = () => {
    setLastLogout(null);
  }

  if (!lastLogout) {
    return null;
  }

  return (
    <>
      <Toast
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            className="mr-2"
            onClose={() => close()}
            delay={delayTimeInMs}
            autohide
          >
            <Toast.Header>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-auto" />{' '}
              <strong className="mr-auto">Mededeling</strong> {' '}
              <small>{getWhenMessage()}</small> {' '}
            </Toast.Header>
            <Toast.Body style={{minWidth: 250}}>
              Je bent uitgelogd.
            </Toast.Body>
          </Toast>
    </>
  );
};

export default LogoutToast;