import React from 'react';

import './LoadingText.css';

const LoadingText = ({ text = 'Laden' }) => {
  return (
    <h3>
      {text}
      <span className="loading-dot dot-0">.</span>
      <span className="loading-dot dot-1">.</span>
      <span className="loading-dot dot-2">.</span>
    </h3>
  );
};

export default LoadingText;