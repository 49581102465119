export const GetLeveranciersNaam = (leveranciers, nummer) => {
  if (leveranciers) {
    const leverancier = leveranciers.find(x => x.nummer === nummer);
    if (leverancier !== 'undefined') {
      return leverancier.naam;
    }
  }
  return nummer;
};

export const GetBtwString = (btw) => {
  if (btw === 0) return 'Nul';
  if (btw === 1) return 'Laag';
  if (btw === 2) return 'Hoog';

  return '-';
};

export const getBtwStringShorthand = (btw) => {
  if (btw === 0) return 'N';
  if (btw === 1) return 'L';
  if (btw === 2) return 'H';

  return '-';
};

export const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n - 1) : str;
};

export const hellip = () => {
  return { __html: '&hellip;' };
};

export const nbsp = () => {
  return { __html: '&nbsp;' };
};

export const euro = () => {
  return { __html: '&euro;' };
};

export const formatUrl = (url) => {
  if (!url) {
    return '-';
  }

  return (
    url
      .replace('https://', '')
      .replace('http://', '')
  );
};

export const formatCurrency = (num, useDecimalPoint = true) => {
  if (!useDecimalPoint) {
    return (
      parseFloat(num)
        .toFixed(2) // always two decimal digits
        .replace('.', ',') // replace decimal point character with comma
    );
  }
  return (
    parseFloat(num)
      .toFixed(2) // always two decimal digits
      .replace('.', ',') // replace decimal point character with comma
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  );
};

export const serverPriceFormatToEURFormat = (num) => {
  return `${(num ?? 0.00).toFixed(2)}`.replace(/\./g, ',');
}

export const EURPriceFormatToServerFormat = (num) => {
  return `${num ?? '0,00'}`.replace(/,/g, '.');
}

export const unformatCurrency = num => {
  return (
    num
      .toFixed(2)
      // .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  )
};

export const camelSentence = str => {
  return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => {
    return chr.toUpperCase();
  });
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
};

export const getPageFromUrl = (url) => {
  var paramsString = new URL(url);
  var searchParams = new URLSearchParams(paramsString.search);

  // return searchParams;
  // //Iterate the search parameters.
  for (let p of searchParams) {
    return p[1];
  }
  return '1';
};

export function laravelToJsDate(laravelDate) {
  const t = laravelDate.split(/[- : T .]/);
  return new Date(`${t[0]}-${t[1]}-${t[2]} ${t[3]}:${t[4]}:${t[5]}`);
}

// https://flaviocopes.com/how-to-determine-date-is-today-javascript/
export const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() == today.getDate()
    && someDate.getMonth() == today.getMonth()
    && someDate.getFullYear() == today.getFullYear();
};

export const makeId = () => {
  let id = '';
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < 12; i++) {
    id = `${id}${characters.charAt(Math.floor(Math.random() * characters.length))}`;
  }
  return id;
}

export const getPaginatedUrl = (endpoint = '/', page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  let url = `${endpoint}?page=${page}&perPage=${perPage}`;

  if (search) {
    if (search.key !== '') {
      url = `${url}&searchKey=${search.key}`;
    }
    if (search.query !== '' || search.query === '0' || search.query === 0) {
      url = `${url}&query=${search.query}`;
    }
  }

  if (sort && sort.key) {
    url = `${url}&sortKey=${sort.key ? sort.key : 'artikelnr'}`;
    url = `${url}&sortDir=${sort.dir ? sort.dir : 'asc'}`;
  }

  url = `${url}&operator=${operator ?? 'LIKE'}`;

  return url;
}