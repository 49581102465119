import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaHandshake } from "react-icons/fa"

const OfferteConfirmButton = ({ offertenummer, label = '' }) => {
  let history = useHistory();
  if (!offertenummer) {
    return <></>;
  }
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<Tooltip id="button-tooltip">Geef akkoord en maak verkooporder</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          ref={ref}
          variant="success"
          {...triggerHandler}
          onClick={() => history.push(`/verkooporders/add/${offertenummer}`)}
        ><FaHandshake />{label && (<>{' '}{label}</>)}</Button>
      )}
    </OverlayTrigger>
  );
};

export default OfferteConfirmButton;