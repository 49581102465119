import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ListGroup, ButtonGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  FaFileDownload,
  FaPrint
} from 'react-icons/fa';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/InkoopService';
import LeveranciersApiService from '../../Services/LeverancierService';
import BesteladviesTable from './BesteladviesTable';

const BesteladviesList = () => {
  const initialState = {
    checked: false,
    indeterminate: false,
    leveranciers: []
  };
  const [advies, setAdvies] = useState([]);
  const [adviesState, setAdviesState] = useState(initialState);
  const [leveranciers, setLeveranciers] = useState([]);
  const [inkooporders, setInkooporders] = useState([]);
  const { register, handleSubmit, reset } = useForm();

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  }

  useEffect(() => {
    const data = [];
    Object.entries(advies).forEach(entry => {
      const [leverancier, artikelen] = entry;
      const subData = {
        id: leverancier,
        leverancier: leveranciers,
        artikelen: artikelen.map(artikel => {
          return {
            checked: false,
            id: artikel.id,
            aantal: artikel.besteladvies_besteleenheid,
            leverancier: leverancier,
            artikel: artikel
          };
        }),
        checked: false,
        indeterminate: false
      };
      data.push(subData);
    });
    setAdviesState({ ...adviesState, 'leveranciers': data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advies]);

  const handleShow = () => setShowModal(true);

  const onSubmit = fieldData => {
    const bestellingen = [];
    if (adviesState.leveranciers) {
      adviesState.leveranciers.filter(leverancier => leverancier.artikelen?.length > 0)
        .forEach(leverancier => {
          const artikelen = leverancier.artikelen
            .filter(artikel => artikel.checked && artikel.aantal > 0)
            .map(artikel => {
              return {
                artikelId: artikel.id,
                aantal: artikel.aantal
              }
            })
            .filter(artikel => { return artikel !== undefined })
          const bestelling = {
            leveranciersNummer: leverancier.id,
            artikelen: artikelen
          }
          if (bestelling.artikelen.length > 0) {
            bestellingen.push(bestelling);
          }
      });
      bestel(bestellingen);
    }
    handleShow();
    reset();
  };

  const bestel = (bestellingen) => {
    // ask for permission to bestel first
    ApiService.bestel(bestellingen)
      .then(response => {
        // open popup with links to
        setInkooporders(response.data);
        retrieveFromApi();
        setAdviesState(initialState);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.getAll()
      .then(response => {
        setAdvies(response.data);
        LeveranciersApiService.getAll()
          .then(response => {
            setLeveranciers(response.data);
          })
          .catch(e => {
            console.log(e);
          });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Container fluid>
      <form onSubmit={handleSubmit(onSubmit)}>
      <BodyTitleBar title="Besteladvies">
        <Button
          type="submit"
          variant="outline-success"
        >Maak inkooporders</Button>
      </BodyTitleBar>
      <Row>
        <Col>
          <BesteladviesTable
            adviesState={adviesState}
            setAdviesState={nextState => setAdviesState(nextState)}
            leveranciers={leveranciers}
            advies={advies}
            register={register}
          />
        </Col>
      </Row>
      </form>
      <Modal
        size="lg"
        show={showModal}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bestelronde</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
          {inkooporders && inkooporders.map((io, index) => {
            return (
              <ListGroup.Item
                key={index}
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
              >
                <strong>Inkooporder-{io.ordernummer}.pdf</strong>
                <ButtonGroup>
                  <Button
                    onClick={() => ApiService.downloadPDF(io.ordernummer, `Inkooporder-${io.ordernummer}.pdf`)}>
                    <FaFileDownload />
                  </Button>
                  <Button onClick={() => ApiService.printPDF(io.ordernummer)}>
                    <FaPrint />
                  </Button>
                </ButtonGroup>
              </ListGroup.Item>
            )
          })}
          {inkooporders.length === 0 && (
            <p>Er zijn geen bestellingen geplaatst.</p>
          )}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >Sluit</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BesteladviesList;