import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const SearchInfoCard = ({ info, hide }) => {
  const listGroupClass = "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center";
  return (
    <Card className="mb-3" border={'light'}>
      <Card.Header
        className={`
        bg-dark
        text-light
        d-flex
        justify-content-between
        flex-wrap
        flex-md-nowrap
        align-items-center
      `}>
        <span>Zoek informatie</span>
        {hide && (
          <span
            onClick={hide}
            style={{cursor: 'pointer'}}
          ><FaTimes /></span>
        )}
      </Card.Header>

      {info && (
        <ListGroup variant="flush">
          <ListGroup.Item className={listGroupClass}>
            <strong>Gevonden items</strong>
            <span>{info.itemsFound}</span>
          </ListGroup.Item>
          <ListGroup.Item className={listGroupClass}>
            <strong>Pagina</strong>
            <span>{info.currentPage} / {info.pages}</span>
          </ListGroup.Item>
          <ListGroup.Item className={listGroupClass}>
            <strong>Items per pagina</strong>
            <span>{info.perPage}</span>
          </ListGroup.Item>
        </ListGroup>
      )}
    </Card>
  );
};

export default SearchInfoCard;