import fileDownload from 'js-file-download';
import ajax, { api } from '../Helpers/http-common';
import { getPaginatedUrl } from '../Helpers/Helpers';

const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl('/artikelen', page, perPage, search, sort, operator);
};

const getAll = (page = 1, perPage = 10, searchKey = '', query = '', sortKey = null, sortDir = 'asc', operator = 'LIKE') => {
  const url = getAllUrl(page, perPage, { key: searchKey, query }, { key: sortKey, dir:sortDir }, operator);
  return ajax.get(url);
};

const getStats = () => {
  return ajax.get(`${api}/statistics/artikelen`);
}
const get = id => {
  return ajax.get(`/artikelen/${id}`);
};

const getSurrounding = artikelnr => {
  return ajax.get(`${api}/artikelen/surrounding/${artikelnr}`);
};

const create = data => {
  return ajax.post('/artikelen', data);
};

const update = (id, data) => {
  return ajax.put(`/artikelen/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/artikelen/${id}`);
};

const removeAll = () => {
  return ajax.delete('/artikelen');
};

const findBy = (key, value, page) => {
  return ajax.get(`/artikelen/find/${key}/${value}?page=${page}`);
};

const getSearchKeys = () => {
  return ajax.get('/artikelen/searchkeys');
};

const paged = (page, amount) => {
  return ajax.get(`/artikelen/paged/${page}/${amount}`);
};

const getCalculatedVerkoopprijs = async (prijs, btw = 2, aantal = 1, config = {}) => {
  return ajax.get(`${api}/artikelen/calculate-verkoopprijs/${prijs}/${btw}/${aantal}`, config);
};

const downloadExport = async (filename, filetype) => {
  const response = await ajax.get(`${api}/export/artikelen/${filetype}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const uploadImport = (data) => {
  let formData = new FormData();
  formData.append('file', data.file);
  formData.append('clean', data.clean);
  formData.append('update', data.update);

  return ajax.post(
    `${api}/import/artikelen`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export default {
  getStats,
  getAll,
  get,
  getSurrounding,
  create,
  update,
  remove,
  removeAll,
  findBy,
  getSearchKeys,
  paged,
  getAllUrl,
  getCalculatedVerkoopprijs,
  downloadExport,
  uploadImport,
};