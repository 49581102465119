import ajax, { api } from '../Helpers/http-common';

const getNextDeliveries = () => {
    return ajax.get(`${api}/widget/next_deliveries`);
};

const getSupplierSoldStatistics = () => {
    return ajax.get(`${api}/widget/supplier_sold_statistics`);
}

const WidgetApiService = {
    getNextDeliveries,
    getSupplierSoldStatistics,
};

export default WidgetApiService;