import React, { useState, useEffect, Fragment } from 'react';
import { Button, Col, Container, Row, Table, InputGroup, FormControl, Form, Alert } from 'react-bootstrap';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaFileDownload, FaPrint, FaPallet, FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import ApiService from '../../Services/InpakopdrachtService';
import PalletApiService from '../../Services/PalletService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';

import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import InspectButton from '../../Helpers/InspectButton';
import SamenstellingBesteleenheidAantalListCard from '../Verkooporders/Cards/SamenstellingBesteleenheidAantalListCard';

const EditInpakopdracht = () => {
  let { id } = useParams();
  const {
    notifications,
    removeNotifications,
    addGenericErrorNotification,
    addSuccessNotification
  } = useAlertNotifications();

  const initialState = {
    mededeling: '',
    pallets: [],
    verkooporder: {
      ordernummer: '...'
    }
  };

  const [inpakopdracht, setInpakopdracht] = useState(initialState);
  const [bezorgadressen, setBezorgadressen] = useState([]);

  const { register, handleSubmit } = useForm();

  const onSubmit = fieldData => {
    if (!id) return;
    const result = [];
    if (fieldData.locaties) {
      fieldData.locaties.forEach((p, i) => {
          const locatie = p.filter(x => x)[0];
          if (locatie) {
            result.push({
              'pallet_id': i,
              'locatie': locatie
            });
          }
      });
    }

    const mededeling = fieldData['mededeling'];
    const data = {
      'mededeling': mededeling,
      'pallets': result,
    };
    ApiService.update(id, data)
      .then(response => {
        setInpakopdracht(response.data);
        sortPalletsOnBezorgadres(response.data.pallets);
        addSuccessNotification('Inpakopdracht aangepast.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    if (!id) return;
    ApiService.get(id)
      .then(response => {
        setInpakopdracht(response.data);
        sortPalletsOnBezorgadres(response.data.pallets);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const sortPalletsOnBezorgadres = (pallets) => {
    const result = pallets.reduce((p, curr) => {
      if (!p[curr.bezorgadres_id]) p[curr.bezorgadres_id] = [];
      p[curr.bezorgadres_id].push(curr);
      return p;
    }, {});
    setBezorgadressen(result);
  };

  return (
    <Container fluid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BodyTitleBar title={`Inpakopdracht #${inpakopdracht.verkooporder.ordernummer}`}>
          <ButtonGroup>
            <DropdownButton
              variant="primary"
              as={ButtonGroup}
              title={<FaFileDownload />}
            >
              <Dropdown.Item
                onClick={() => ApiService.downloadPDF(inpakopdracht.id, `Inpakopdracht-${inpakopdracht.id}.pdf`)}
              ><FaFileDownload /> Inpakopdracht</Dropdown.Item>
              <Dropdown.Item
                onClick={() => PalletApiService.downloadPDF(inpakopdracht.id, `Inpakopdracht-${inpakopdracht.id}.pdf`)}
              ><FaFileDownload /> Palletformulieren</Dropdown.Item>
            </DropdownButton>
            {" "}
            <DropdownButton
              variant="primary"
              as={ButtonGroup}
              title={<FaPrint />}
            >
              <Dropdown.Item
                onClick={() => ApiService.printPDF(inpakopdracht.id)}
              ><FaPrint /> Inpakopdracht</Dropdown.Item>
              <Dropdown.Item
                onClick={() => PalletApiService.printPDF(inpakopdracht.id)}
              ><FaPrint /> Palletformulieren</Dropdown.Item>
            </DropdownButton>
            <Button
              type="submit"
              variant='success'
            ><FaSave /> Opslaan</Button>
          </ButtonGroup>
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          removeNotifications={removeNotifications}
        />
        <Form.Group as={Row}>
          <Col sm={4} md={2}>
            Verkooporder
          </Col>
          <Col sm={8} md={8}>
            <InspectButton
              variant="outline-primary"
              to={`/verkooporders/edit/${inpakopdracht.verkooporder.ordernummer}`}
            >#{inpakopdracht.verkooporder.ordernummer}</InspectButton>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={2}>Mededeling</Form.Label>
          <InputGroup className="col-md-10 col-sm-8">
            <Form.Control
              type="text"
              name={'mededeling'}
              defaultValue={inpakopdracht.mededeling}
              ref={register()}
            />
            <InputGroup.Append>
              <InputGroup.Text>!</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <hr />
        <Row>
          {bezorgadressen && Object.entries(bezorgadressen).length > 0 ? (
              <Col sm={12} md={7}>
                <Table striped bordered>
                  <thead className="thead-dark">
                    <tr>
                      <th>Bezorgadres</th>
                      <th>Palletnummer</th>
                      <th>Locatie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(bezorgadressen).map((entry, index) => {
                      return entry[1].map((pallet, i) => {
                          return (
                            <Fragment key={`${index}-${i}`}>
                              {i === 0 && (
                                <tr className="table-secondary">
                                  <td colSpan={3}>{i === 0 ? pallet.bezorgadres.adres : ''}</td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                </td>
                                <td>{pallet.id}</td>
                                <td>
                                  <InputGroup>
                                    <FormControl
                                      name={`locaties[${pallet.id}][${pallet.bezorgadres.id}]`}
                                      defaultValue={pallet.locatie}
                                      ref={register({
                                        validate: {
                                          required: (value) => {
                                            if (!value) {
                                              return "Verplicht veld";
                                            }
                                            return true;
                                          }
                                        }
                                      })}
                                    />
                                    <InputGroup.Append>
                                      <InputGroup.Text><FaPallet /></InputGroup.Text>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </td>
                              </tr>
                            </Fragment>
                          );
                      })
                    })}
                  </tbody>
                </Table>
              </Col>
            ) : (
              <Col sm={12}>
                <Alert variant="info">
                  Er zijn geen pallets toegewezen aan bezorgadressen.
                </Alert>
              </Col>
            )
          }
          <Col sm={12} md={
            bezorgadressen && Object.entries(bezorgadressen).length > 0 ? 5 : 12
          }>
            <SamenstellingBesteleenheidAantalListCard
              artikelen={inpakopdracht.artikelen}
              pakketAantal={inpakopdracht.verkooporder.pakket_aantal}
              orderId={inpakopdracht.verkooporder.id}
              truncate={bezorgadressen && Object.entries(bezorgadressen).length > 0}
            />
          </Col>
        </Row>

      </form>
    </Container>
  )
};

export default EditInpakopdracht;