import React, { useState } from 'react';
import { Card, Button, Row, Col, Form, FormControl, InputGroup } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { ArtikelenTable } from './PakketSamenstellingTab';

const QuickAddTab = ({
  onAdd,
  pakketId,
  artikelen,
  onIncrement = () => {},
  onDecrement = () => {},
  onDetach = () => {}
}) => {
  const [artikelnr, setArtikelnr] = useState('');

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (artikelnr !== '') {
        onAdd(artikelnr);
        setArtikelnr('');
      }
    }
  };

  const handleChange = e => {
    setArtikelnr(e.target.value);
  };

  const handleClick = e => {
    if (artikelnr !== '') {
      onAdd(artikelnr);
      setArtikelnr('');
    }
  };

  return (
    <>
      <Row>
        <Col sm={12} md={4}>
          <Card>
            <Card.Header>Snel toevoegen</Card.Header>
            <Card.Body>
              <Form.Group as={Row}>
                <Form.Label column md={4}>Artikelnr</Form.Label>
                <Col md={8}>
                <InputGroup>
                  <FormControl
                    type="text"
                    onKeyPress={handleKeyPress}
                    value={artikelnr}
                    onChange={handleChange}
                    name="quickadd-artikelnr"
                    autoComplete="off"
                  />
                </InputGroup>
                </Col>
              </Form.Group>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
              <Button
                onClick={handleClick}
                variant="primary"
              ><FaPlus /> Toevoegen <span className="font-small">(enter)</span></Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col sm={12} md={8}>
          <ArtikelenTable
            size="sm"
            artikelen={artikelen?.filter(a => a.pivot?.aantal > 0)}
            pakketId={pakketId}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onDetach={onDetach}
          />

          <ArtikelenTable
            size="sm"
            artikelen={artikelen?.filter(a => a.pivot?.aantal <= 0)}
            pakketId={pakketId}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            onDetach={onDetach}
          />
        </Col>
      </Row>
    </>
  );
};

export default QuickAddTab;