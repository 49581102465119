const apiSortKeys = [
  'nummer',
  'naam',
  'adres',
  'postcode',
  'plaatsnaam',
  'land',
  'contactpersoon',
  'telefoonnummer',
  'mobielnummer',
  'emailadres_order',
  'emailadres',
  'website',
  'webshop'
];

export default apiSortKeys;