import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CardColumns, Container } from 'react-bootstrap';
import BodyTitleBar from '../Helpers/Layouts/BodyTitleBar';
import AantalKlantenWidget from './Widgets/AantalKlantenWidget';
import AantalPakkettenWidget from './Widgets/AantalPakkettenWidget';
import AantalArtikelenWidget from './Widgets/AantalArtikelenWidget';
import AantalVerkoopordersWidget from './Widgets/AantalVerkoopordersWidget';
import AantalOffertesWidget from './Widgets/AantalOffertesWidget';
import NextDeliveriesWidget from './Widgets/NextDeliveriesWidget';
import SupplierSoldStatisticsWidget from './Widgets/SupplierSoldStatisticsWidget';
import QuoteWidget from './Widgets/QuoteWidget';

const Dashboard = () => (
  <Container fluid>
    <BodyTitleBar title="Dashboard" />
    <CardColumns>
      <QuoteWidget />
      <AantalVerkoopordersWidget />
      <AantalKlantenWidget />
      <AantalOffertesWidget />
      <AantalPakkettenWidget />
      <AantalArtikelenWidget />
    </CardColumns>
    <CardColumns>
      <NextDeliveriesWidget />
      <SupplierSoldStatisticsWidget />
    </CardColumns>
  </Container>
);

export default Dashboard;