import React, { useState, useEffect } from 'react';
import {
    Table,
  Container,
  Row,
  Col,
  Card,
  Accordion,
  Button,
} from 'react-bootstrap';

import ApiService from '../../Services/KlantService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import InspectButton from '../../Helpers/InspectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPhone, faEdit, faFileArchive, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import VerkooporderApiService from '../../Services/VerkooporderService';

const KlantRowCard = ({ klant }) => {
  return (
    <Row className="mt-2 mb-2">
      <Col>
        <Card className='mb-2'>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>{klant.bedrijfsnaam}</Card.Title>
            <InspectButton
              icon={<FontAwesomeIcon icon={faEdit} />}
              iconPosition='left'
              variant='danger'
              to={`/klanten/edit/${klant.nummer}`}
            >Bewerken</InspectButton>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={4}>
                Klantnummer:
              </Col>
              <Col md={8}>
                <span>
                  <InspectButton
                    variant="outline-primary"
                    to={`/klanten/edit/${klant.nummer}`}
                  >#{klant.nummer}</InspectButton>
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                Contactpersoon:
              </Col>
              <Col md={8}>
                <span>{klant.contactpersoon ?? 'Niemand'}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Accordion defaultActiveKey='1'>
            {/* <Card>
              <ContextAwareToggle eventKey="0">Bezorgadressen</ContextAwareToggle>

              <Accordion.Collapse eventKey="0">
                <div>Bezorgadressen hier</div>
              </Accordion.Collapse>
            </Card> */}
            <Card>
              <ContextAwareToggle eventKey="1">Verkooporders</ContextAwareToggle>
              <Accordion.Collapse eventKey="1">
                <table className="table table-condensed table-responsive" style={{ display: "table" }} >
                  <thead>
                    <tr>
                      <th>Ordernummer</th>
                      <th>Bezorgdatum</th>
                      <th>Aantal pakketten</th>
                      <th>Betaald</th>
                    </tr>
                  </thead>
                  <tbody>

                  {klant.verkooporders && klant.verkooporders.map((verkooporder, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <InspectButton
                            variant="outline-primary"
                            to={`/verkooporders/edit/${verkooporder.ordernummer}`}
                          >#{verkooporder.ordernummer}</InspectButton>
                        </td>
                        <td>{verkooporder.bezorgdatum}</td>
                        <td>{verkooporder.pakket_aantal}</td>
                        <td>{verkooporder.betaald ? 'Ja' : 'Nee'}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </Accordion.Collapse>

              <ContextAwareToggle eventKey="2">Offertes</ContextAwareToggle>

              <Accordion.Collapse eventKey="2">
                <table className="table table-condensed table-responsive" style={{ display: "table" }} >
                  <thead>
                    <tr>
                      <th>Offertenummer</th>
                      <th>Aantal pakketten</th>
                    </tr>
                  </thead>
                  <tbody>

                  {klant.offertes && klant.offertes.map((offerte, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <InspectButton
                            variant="outline-primary"
                            to={`/offertes/edit/${offerte.offertenummer}`}
                          >#{offerte.offertenummer}</InspectButton></td>
                        <td>{offerte.pakket_aantal}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </Accordion.Collapse>
              <ContextAwareToggle eventKey="3">Pakketten</ContextAwareToggle>

              <Accordion.Collapse eventKey="3">
                <table className="table table-condensed table-responsive" style={{ display: "table" }} >
                  <thead>
                    <tr>
                      <th>Pakket naam</th>
                      <th>Aanmaakdatum</th>
                    </tr>
                  </thead>
                  <tbody>
                  {klant.pakketten && klant.pakketten.map((pakket, index) => {
                    const dt = new Date(pakket.created_at)
                    const aanmaakdatum = `${dt.getDate()}-${dt.getMonth()}-${dt.getFullYear()}`
                    return (
                      <tr key={index}>
                        <td>
                          <InspectButton
                            variant="outline-primary"
                            to={`/pakketten/samenstellen/${pakket.id}`}
                          >{pakket.naam}</InspectButton></td>
                        <td>{aanmaakdatum}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Card>
      </Col>

    </Row>
  );
};

const ContactDetails = ({ contact }) => {
  return (
    (contact.telefoonnummer || contact.emailadres) ? (
      <>
        {contact.telefoonnummer && (
          <div>
            <FontAwesomeIcon icon={faPhone} /> {contact.telefoonnummer}
          </div>
        )}
        {contact.emailadres && (
          <div>
            <FontAwesomeIcon icon={faAt} /> {contact.emailadres}
          </div>
        )}
      </>
    ) : '-'
  );
}

const KlantList = () => {
  const [klanten, setKlanten] = useState([]);
  const [currentKlant, setCurrentKlant] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchKey, setSearchKey] = useState("nummer");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    retrieveKlanten();
  }, []);

  const onChangeSearchValue = e => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
  };

  const onChangeSearchKey = e => {
    const searchKey = e.target.value;
    setSearchKey(searchKey);
  };

  const retrieveKlanten = () => {
    ApiService.getAll()
      .then(response => {
        setKlanten(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const setActiveKlant = (klant, index) => {
    setCurrentKlant(klant);
    setCurrentIndex(index);
  };

  const findBy = () => {
    ApiService.findBy(searchKey, searchValue)
    .then(response => {
      setKlanten(response.data);
    })
    .catch(e => {
      console.log(e);
    })
  };

  return (
    <>
      <Container fluid>
        <BodyTitleBar title="Klanten" btnTo="/klanten/add" btnTitle="Klant toevoegen"/>
      <Row>
        <Col>
            <div className="list row">
              <div className="col">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zoek ..."
                    value={searchValue}
                    onChange={onChangeSearchValue}
                  />
                  <select
                    className="custom-select"
                    id="searchKey"
                    onChange={onChangeSearchKey}
                    defaultValue={searchKey}
                    name="searchKey"
                  >
                    <option value="nummer">Klantnummer</option>
                    <option value="bedrijfsnaam">Bedrijfsnaam</option>
                    <option value="contactpersoon">Contactpersoon</option>
                    <option value="emailadres">E-mail (contactpersoon)</option>
                    <option value="mobielnummer">Telefoonnummer (mobiel)</option>
                    <option value="factuur_emailadres">E-mail (factuur)</option>
                    <option value="telefoonnummer">Telefoonnummer (vast)</option>
                    <option value="factuur_adres">Adres</option>
                    <option value="factuur_postcode">Postcode</option>
                    <option value="factuur_plaatsnaam">Plaatsnaam</option>
                    <option value="factuur_land">Land</option>
                  </select>
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={findBy}
                    >Zoek</button>
                  </div>
                </div>
              </div>
            </div>
            {/* {klanten && klanten.map((klant, index) => (
              <KlantRowCard klant={klant} key={index} />
            ))} */}
            <div className="row">
              <div className={`col-md-${currentKlant ? 7 : 7}`}>
                <Table striped hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th style={{ width: '15%' }}>Nummer</th>
                      <th style={{ width: '45%' }}>Bedrijfsnaam</th>
                      <th style={{ width: '40%' }}>Contactgegevens</th>
                    </tr>
                  </thead>
                  <tbody>
                    {klanten && klanten.map((klant, index) => {

                      return (
                        <tr
                        className={`${(index === currentIndex ? "table-active" : "")}`}
                        onClick={() => setActiveKlant(klant, index)}
                        key={index}
                      >
                        <td>
                          <InspectButton
                            variant="outline-danger"
                            icon={<FontAwesomeIcon icon={faEdit} />}
                            to={`/klanten/edit/${klant.nummer}`}
                          >#{klant.nummer}</InspectButton>
                        </td>
                        <td>{klant.bedrijfsnaam}</td>
                        <td>
                          <ContactDetails contact={klant} />
                        </td>
                      </tr>
                    )}
                      )
                    }

                  </tbody>
                </Table>
              </div>
              <Col md={5}>
              {currentKlant ? (
                <KlantRowCard klant={currentKlant} />
              ) : (
                <Card className="text-white bg-info sticky-top">
                  <Card.Header>
                    Informatie
                </Card.Header>
                  <Card.Body>
                    Voor meer informatie selecteer een klant.
                </Card.Body>
                </Card>
              )}
              </Col>

            </div>
        </Col>
      </Row>
    </Container>
      </>
    );
};

export default KlantList;