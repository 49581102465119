import React, { useState } from 'react';
import { Col, Container, Row, ButtonGroup, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';
import ArtikelListCardInfo from './ArtikelListCardInfo';
import SearchInfoCard from '../../Helpers/PaginatedTable/SearchInfoCard';
import api from '../../Services/ArtikelService';
import ExportImportComponent from '../../Helpers/ExportImportComponent';

import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/artikelData';

const ArtikelList = () => {
  const [reset, setReset] = useState(false);
  const [currentArtikel, setCurrentArtikel] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchInfo, setSearchInfo] = useState({
    itemsFound: 0,
    pages: 0,
    currentPage: 0,
    from: 0,
    to: 0,
    perPage: 0,
    showSearchInfo: true
  });

  let history = useHistory();

  const setActiveArtikel = (artikel, index) => {
    setCurrentArtikel(artikel);
    setCurrentIndex(index);
  };

  return (
    <Container fluid>
      <BodyTitleBar title="Artikelen">
        <ButtonGroup>
          <Button
            variant="outline-primary"
            onClick={() => history.push('/artikelen/add')}
          ><FaPlus /> Artikel toevoegen</Button>
          <ExportImportComponent
            api={api}
            onImportSuccess={() => {
              setReset(true);
            }}
          />
        </ButtonGroup>
      </BodyTitleBar>
      <Row>
        <Col sm={12} md={12} lg={8}>
          <PaginatedTable
            pageSizes={[10, 30, 50, 100, 250]}
            defaultPageSize={250}
            api={api}
            tableColumns={tableColumns}
            hiddenColumns={hiddenColumns}
            searchKeys={searchKeys}
            apiSortKeys={apiSortKeys}
            onRowSelect={(dataObj, index) => setActiveArtikel(dataObj, index)}
            showSearchInfo={searchInfo.showSearchInfo}
            setSearchInfo={info => setSearchInfo(info)}
            getRowProps={row => {
              let variant = row.original.id === currentIndex ? 'table-active' : '';
              return ({
                className: variant,
                style: {
                  cursor: 'context-menu'
                }
              });
            }}
            forceReset={reset}
            setForceReset={(resetState) => {
              setActiveArtikel(null, -1);
              setReset(resetState);
            }}
            localStorageId={'products_list'}
          />
        </Col>
        <Col sm={12} md={12} lg={4}>
          {searchInfo.showSearchInfo && (
            <SearchInfoCard
            info={searchInfo}
              hide={() => setSearchInfo(prevState => {
                return {
                  ...prevState,
                  showSearchInfo: false
                };
              })}
            />
          )}
          <ArtikelListCardInfo
            artikel={currentArtikel}
            onClose={() => setActiveArtikel(null, -1)}
          />
        </Col>
      </Row>
    </Container>
    );
};

export default ArtikelList;