import React, { useState } from 'react';
import { Button, Modal, Form, Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ApiService from '../../Services/VrachtbriefService';
import { FaPlus } from 'react-icons/fa';

const AddVrachtbriefButton = ({ label = 'Maak vrachtbrief', verkooporderId, as, onSuccess, onError = () => {}, ...props}) => {
  const [show, setShow] = useState(false);
  const { register, handleSubmit } = useForm();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = data => {
    data.verkooporder_id = verkooporderId;
    handleClose();
    ApiService.create(data)
      .then(response => {
        onSuccess(response);
      })
      .catch(e => {
        onError(e);
      });
  }

  const Item = (props) => {
    if (as === Dropdown.Item) {
      return <Dropdown.Item {...props}><FaPlus /> {props.children}</Dropdown.Item>
    }

    return <Button {...props}><FaPlus /> {props.children}</Button>
  }

  return (
    <>
      <Item onClick={handleShow} {...props}>{label}</Item>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Vrachtbrief aanmaken</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group>
                <Form.Label>Mededeling</Form.Label>
                <Form.Control
                  type="text"
                  id="mededeling"
                  defaultValue=""
                  name="mededeling"
                  ref={register}
                />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Sluit</Button>
            <Button variant="primary" type="submit">Maak</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddVrachtbriefButton;