const apiSortKeys = [
  'id',
  'naam',
  'ordernummer',
  'offertenummer',
  'artikelen_aantal',
  'pakket_aantal',
  'betaald',
  'bezorgdatum',
  'klantnummer',
  'current_status',
  'verkoopprijs_totaal',
  'verkoopprijs_totaal_btw_hoog',
  'verkoopprijs_totaal_btw_laag',
  'verkoopprijs_totaal_btw_nul',
  'verkoopprijs_totaal_incl'
];

export default apiSortKeys;