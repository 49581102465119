import ajax, { api } from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';

const getBase64PDF = (id) => {
  return ajax.get(`${api}/samenstelling/verkooporder/pdf/base64/${id}`);
};

const downloadPDF = async (id, filename) => {
  const response = await ajax.get(`${api}/samenstelling/verkooporder/pdf/download/${id}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id) => {
  try {
    const response = await getBase64PDF(id);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
};

export default {
  getBase64PDF,
  downloadPDF,
  printPDF
}