import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaGift, FaGifts, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import ApiService from '../../Services/PakketService';

const AantalPakkettenWidget = () => {
  const [aantal, setAantal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    ApiService.getStatistics()
      .then(response => {
        setAantal(response.data.aantal);
        setLoading(false);
        setFailed(false);
      })
      .catch(e => {
        setFailed(true);
        setLoading(false);
      });
  }, []);

  let history = useHistory();

  const redirect = () => {
    history.push(`/pakketten/add`);
  }
  const redirectTo = () => {
    history.push('/pakketten');
  }

  if (failed && !loading) {
    return (
      <Card
        bg="danger"
        text="light"
        className="text-center"
      >
        <Card.Body>
          Pakketten tellen is mislukt.
        </Card.Body>
      </Card>
    );
  }

  if (loading && !failed) {
    return (
      <Card
        bg="secondary"
        text="light"
        className="text-center"
      >
        <Card.Body>
          <>Pakketten tellen...</>
        </Card.Body>
      </Card>
    );
  }

  if (!loading && aantal === 0) {
    return (
      <Card className="text-center">
        <Card.Header>
          <span><FaGift /> Samengestelde pakketten</span>
        </Card.Header>
        <Card.Body>
          <Button
            onClick={redirect}
          ><FaGift /> Voeg het eerste pakket toe</Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <span><FaGift /> Samengestelde pakketten</span>
        <Button
          variant="primary"
          onClick={redirect}
        ><FaPlus /></Button>
      </Card.Header>
      <Card.Body
        className="text-center"
        style={{ cursor: 'pointer' }}
        onClick={redirectTo}
      >
        <h1><FaGifts /> {aantal}</h1>
      </Card.Body>
    </Card>
  );
};

export default AantalPakkettenWidget;