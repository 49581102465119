import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Tabs,
  Tab,
  InputGroup,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap';
import { FaCog, FaTrash, FaEdit } from 'react-icons/fa';

import KlantApiService from '../../Services/KlantService';
import ApiService from '../../Services/PakketService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ConfirmDeleteAllModal from './ConfirmDeleteAllArtikelenFromPakketModal';
import OfferteTable from '../Offertes/OfferteTable';
import VerkooporderTable from '../Verkooporders/VerkooporderTable';
import PaginatedArtikelenTab from './Tabs/PaginatedArtikelenTab';
import PakketSamenstellingTab from './Tabs/PakketSamenstellingTab';
import CreateOfferteButton from '../Offertes/CreateOfferteButton';
import QuickAddTab from './Tabs/QuickAddTab';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import DuplicatePakketModal from './DuplicatePakketModal';
import { formatCurrency } from '../../Helpers/Helpers';
import sortByCustomerName from '../../Helpers/Sorts/sortByCustomerName';

const PakketSamenstellen = () => {
  const defaultActiveKey = 'samenstelling-tab';
  const initialKlant = {
    bedrijfsnaam: ''
  };
  const initialPakketState = {
    id: null,
    naam: '',
    klantnummer: '',
    klant: initialKlant,
    verkoopprijs_totaal: '0,00',
    verkoopprijs_totaal_incl: '0,00',
    verkoopprijs_totaal_btw_hoog: '0,00',
    verkoopprijs_totaal_btw_laag: '0,00',
    verkoopprijs_totaal_btw_nul: '0,00',
    fixed_verkoopprijs: '',
    artikelen: []
  };

  const [key, setKey] = useState(defaultActiveKey);
  const [offertes, setOffertes] = useState([]);
  const [verkooporders, setVerkooporders] = useState([]);
  const [klanten, setKlanten] = useState([]);
  const [currentPakket, setCurrentPakket] = useState(initialPakketState);
  const [aantalArtikelen, setAantalArtikelen] = useState(0);
  const [aantalInactiveArtikelen, setAantalInactiveArtikelen] = useState(0);
  const [placeholderPakketnaam, setPlaceholderPakketnaam] = useState('...');
  const [loading, setLoading] = useState(true);
  const [stopJump, setStopJump] = useState(true);

  const {
    notifications,
    addGenericErrorNotification,
    addErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  let { id } = useParams();
  let history = useHistory();

  const redirect = (pakketId) => {
    history.push(`/pakketten/samenstellen/${pakketId}`);
    setLoading(true);
    resetPakketSamenstellen();
    retrieveFromApi();
  }

  const resetPakketSamenstellen = () => {
    setStopJump(true);
    setKey(defaultActiveKey);
    setOffertes([]);
    setVerkooporders([]);
    // setCurrentPakket(initialPakketState);
    handleUpdatedPakket(initialPakketState);
    setAantalInactiveArtikelen(0);
    setAantalArtikelen(0);
    setPlaceholderPakketnaam('...');
  }

  useEffect(() => {
    // setCurrentPakket(initialPakketState);
    handleUpdatedPakket(initialPakketState);
    retrieveFromApi();
  }, []);

  const handleKlantChange = (event) => {
    const { name, value } = event.target;
    const klant = klanten.find(k => k.nummer === value);
    if (klant) {
      setPlaceholderPakketnaam(klant ? klant.bedrijfsnaam : '...');
      setCurrentPakket(prevState => {
        return {
          ...prevState,
          'klantnummer': value,
          'naam': klant.bedrijfsnaam,
          'klant': klant
        };
      });
    } else {
      setPlaceholderPakketnaam('...');
      setCurrentPakket({ ...currentPakket, [name]: value});
    }
  };

  useEffect(() => {
    if (!loading) {
      if (currentPakket.artikelen) {
        const aantal = currentPakket.artikelen.reduce((sum, x) => sum + parseInt(x.pivot.aantal), 0);
        setAantalArtikelen(aantal);

        const aantalInactive = currentPakket.artikelen.filter(a => a.pivot?.aantal === 0).length;
        setAantalInactiveArtikelen(aantalInactive);

        if (stopJump) {
          setStopJump(false);
          if (aantal === 0) {
            setKey('artikelen-tab');
          }
        }
      }
    }
  }, [currentPakket]);

  const retrieveFromApi = () => {
    if (!id) {
      addErrorNotification((
        <>
          Er is geen pakket om samen te stellen. Ga naar <Link to={'/pakketten'}>alle pakketten</Link> en probeer het opnieuw.
        </>
      ), 'Er ging wat mis.', false);
      return;
    }
      ApiService.get(id)
        .then(response => {
          resetPakketSamenstellen();
          setLoading(false);
          // setCurrentPakket(response.data);
          handleUpdatedPakket(response.data);
          setOffertes(response.data.offertes);
          setVerkooporders(response.data.verkooporders);
        })
        .catch(e => {
          if (e.response?.status === 404) {
            addErrorNotification((
              <>
                Er is geen bestaand pakket gevonden om samen te stellen. Ga naar <Link to={'/pakketten'}>alle pakketten</Link> en probeer het opnieuw.
              </>
            ), 404);
          } else {
            addGenericErrorNotification(e);
          }
          setLoading(false);
        });
      KlantApiService.getAll()
        .then(response => {
          setKlanten(response.data.sort(sortByCustomerName));
        })
        .catch(e => {
          addGenericErrorNotification(e);
        });
  };

  const addArtikelUsingArtikelnr = (pakketId, artikelnr) => {
    const data = {
      artikelnr: artikelnr,
      aantal: 1
    };
    ApiService.addArtikelToPakket(pakketId, data)
      .then(response => {
        // setCurrentPakket(response.data);
        handleUpdatedPakket(response.data);
        addSuccessNotification(`Artikel ${artikelnr} succesvol toegevoegd.`);
      })
      .catch(e => {
        if (e.response.status === 404) {
          addErrorNotification(`Artikel met artikelnummer "${artikelnr}" kon niet gevonden worden.`, 404);
        } else {
          addGenericErrorNotification(e);
        }
      });
  };

  const updateArtikelAantal = (artikelId, pakketId, aantal) => {
    const data = {
      artikelId: artikelId,
      aantal: aantal
    };
    ApiService.addArtikelToPakket(pakketId, data)
      .then(response => {
        // setCurrentPakket(response.data);
        handleUpdatedPakket(response.data);
        addSuccessNotification('Handeling succesvol doorgevoerd.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const detachArtikel = (pakketId, artikelId) => {
    ApiService.detachArtikel(pakketId, artikelId)
      .then(response => {
        // setCurrentPakket(response.data);
        handleUpdatedPakket(response.data);
        addSuccessNotification('Artikel is succesvol verwijderd.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  };

  const detachAllArtikelen = (pakketId) => {
    ApiService.detachAllArtikelen(pakketId)
      .then(response => {
        // setCurrentPakket(response.data);
        handleUpdatedPakket(response.data);
        addSuccessNotification('Alle artikelen zijn succesvol verwijderd uit het pakket.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  };

  const detachAllUnusedArtikelen = (pakketId) => {
    ApiService.detachAllUnusedArtikelen(pakketId)
      .then(response => {
        // setCurrentPakket(response.data);
        handleUpdatedPakket(response.data);
        addSuccessNotification('Alle ongebruikte artikelen zijn succesvol verwijderd uit het pakket.');
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  };


  const handleSubmit = () => {
    updatePakket();
  };

  const handleUpdatedPakket = (newState) => {
    let fixedVerkoopprijs = newState.fixed_verkoopprijs ? newState.fixed_verkoopprijs : '0,00';
    fixedVerkoopprijs = parseFloat(fixedVerkoopprijs);
    fixedVerkoopprijs = formatCurrency(fixedVerkoopprijs, false);
    const finalState = {
      ...newState,
      fixed_verkoopprijs: fixedVerkoopprijs
    };
    setCurrentPakket(finalState);
  }

  const updatePakket = () => {
    const dto = {
      klantnummer: currentPakket.klantnummer,
      naam: currentPakket.naam,
      fixed_verkoopprijs: currentPakket.fixed_verkoopprijs?.replace(',', '.')
    };
    if (currentPakket.klant) {
      if (currentPakket.klant.nummer !== currentPakket.klantnummer) {
        addErrorNotification('De twee velden voor klant en klantnummer komen niet overeen.');
        return;
      }
    }

    ApiService.update(currentPakket.id, dto)
      .then(response => {
        addSuccessNotification('Pakket succesvol aangepast.');
        handleUpdatedPakket(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const duplicatePakket = data => {
    ApiService.duplicate(currentPakket.id, data)
      .then(response => {
        history.push(`/pakketten/samenstellen/${response.data.id}`);
        handleUpdatedPakket(response.data);
        setOffertes(response.data.offertes);
        setVerkooporders(response.data.verkooporders);
        addSuccessNotification('Pakket is succesvol gedupliceerd. U kunt direct aan de slag in het nieuwe pakket.');
      })
      .catch(e => {
        addErrorNotification('Er is iets mis gegaan bij het dupliceren van het pakket.', e.response.status);
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentPakket({ ...currentPakket, [name]: value });
  };

  const switchTab = (key) => {
    setKey(key);
  };

  const tabs = [
    {
      key: 'samenstelling-tab',
      title: 'Pakket samenstelling',
      tab: (
        <PakketSamenstellingTab
          pakket={currentPakket}
          onDetach={(pakketId, artikelId) => detachArtikel(pakketId, artikelId)}
          onIncrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, 1)}
          onDecrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, -1)}
          switchToArtikelenTab={() => switchTab('artikelen-tab')}
          switchToQuickAddTab={() => switchTab('quick-add-tab')}
          detachAllUnusedArtikelen={() => detachAllUnusedArtikelen(currentPakket.id)}
          detachAllArtikelen={() => detachAllArtikelen(currentPakket.id)}
        />
      )
    },
    {
      key: 'artikelen-tab',
      title: 'Artikelen toevoegen',
      tab: (
        <PaginatedArtikelenTab
          pakketId={currentPakket.id}
          pakket={currentPakket}
          onDetach={(pakketId, artikelId) => detachArtikel(pakketId, artikelId)}
          onIncrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, 1)}
          onDecrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, -1)}
        />
      )
    },
    {
      key: 'quick-add-tab',
      title: 'Snel toevoegen',
      tab: (
        <QuickAddTab
          onAdd={(artikelnr) => addArtikelUsingArtikelnr(currentPakket.id, artikelnr)}
          artikelen={currentPakket.artikelen}
          pakketId={currentPakket.id}
          pakket={currentPakket}
          onDetach={(pakketId, artikelId) => detachArtikel(pakketId, artikelId)}
          onIncrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, 1)}
          onDecrement={(artikelId, pakketId) => updateArtikelAantal(artikelId, pakketId, -1)}
        />
      )
    },
    {
      key: 'offerte-tab',
      title: 'Offertes',
      tab: (
        <OfferteTable offertes={offertes} />
      )
    },
    {
      key: 'verkooporder-tab',
      title: 'Verkooporders',
      tab: (
        <VerkooporderTable verkooporders={verkooporders}/>
      )
    }
  ];

  return (
    <>
      <BodyTitleBar title="Pakket samenstellen">
        {id && (
          <ButtonGroup>
            <Button
              onClick={() => handleSubmit()}
            ><FaEdit /> Aanpassen</Button>
            <CreateOfferteButton
              variant={'success'}
              pakketId={currentPakket.id}
              pakket={currentPakket}
              refreshParent={() => retrieveFromApi()}
              checkDisabled={aantalArtikelen === 0}
            />
            {' '}
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="secondary"
                id="acties"
              ><FaCog /></Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmDeleteAllModal
                  disabled={aantalArtikelen === 0}
                  onConfirm={() => detachAllArtikelen(currentPakket.id)}
                />
                <Dropdown.Item
                  disabled={aantalInactiveArtikelen === 0}
                  onClick={() => detachAllUnusedArtikelen(currentPakket.id)}
                ><FaTrash /> Verwijder inactief</Dropdown.Item>
                <Dropdown.Divider />
                <DuplicatePakketModal
                  pakket={currentPakket}
                  klanten={klanten}
                  onSubmit={(data) => duplicatePakket(data)}
                  disabled={aantalArtikelen === 0}
                />
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>
        )}
      </BodyTitleBar>
      <AlertNotifications
        notifications={notifications}
        showAmount={1}
        removeNotifications={removeNotifications}
      />
      {id && (
        <Container fluid>
          <Row>
            <Col>
              <Form>
                <Form.Group as={Form.Row}>
                  <Form.Label column sm={2}>Pakket naam</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder={placeholderPakketnaam}
                      id="naam"
                      required
                      value={currentPakket.naam}
                      onChange={handleInputChange}
                      name="naam"
                      autoComplete="off"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Form.Label column md={2}>Klantnummer</Form.Label>
                  <Col md={3}>
                    <Form.Control
                      type="text"
                      placeholder="...."
                      required
                      value={currentPakket.klantnummer}
                      onChange={handleKlantChange}
                      name="klantnummer"
                      autoComplete="off"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Form.Label column md={2}>Klant</Form.Label>
                  <Col md={5}>
                    <select
                      className="custom-select"
                      onChange={handleKlantChange}
                      value={currentPakket.klantnummer}
                      name="klantnummer"
                    >
                      <option value="" disabled>Kies een klant</option>
                      {klanten.map((klant) => {
                        return (
                          <option
                            key={klant.nummer}
                            value={klant.nummer}>{klant.bedrijfsnaam}
                          </option>
                        )
                      })}
                    </select>
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Form.Label column sm={2}>Huidige verkoopprijs</Form.Label>
                  <InputGroup className="col-md-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="euro">&euro;</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control disabled type="text" value={formatCurrency(parseFloat(currentPakket.verkoopprijs_totaal))} />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Form.Label column sm={2}>Verkoopprijs</Form.Label>
                  <InputGroup className="col-md-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="euro">&euro;</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="0,00"
                      value={currentPakket.fixed_verkoopprijs}
                      name="fixed_verkoopprijs"
                      id="fixed_verkoopprijs"
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Form.Row}>
                  <Form.Label column sm={2}>Aantal artikelen</Form.Label>
                  <Col sm={3}>
                    <Form.Control
                      disabled
                      type="text"
                      value={aantalArtikelen}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Tabs
            id="controlled-tab-test"
            defaultActiveKey={defaultActiveKey}
            activeKey={key}
            onSelect={(k) => switchTab(k)}
          >
            {tabs && tabs.map((tab, i) => (<Tab
              key={i}
              className='pt-1'
              eventKey={tab.key}
              title={tab.title}
            >
              {tab.tab}
            </Tab>))}
          </Tabs>
        </Container>
      )}
    </>
  );
};

export default PakketSamenstellen;