import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavItem = (props, ref) => {
  const { icon, children, pathTo, open, setOpen } = props;
  return (
    <li className="topbar-nav-item" ref={ref}>
      {pathTo ? (
        <Link
          to={pathTo}
          className="topbar-icon-button"
          onClick={() => setOpen && setOpen()}
        >
          {icon}
        </Link>
      ) : (
        <Button
          variant="link"
          href="#"
          className="topbar-icon-button"
          onClick={(e) => {
            e.preventDefault();
            setOpen && setOpen()
          }}
        >{icon}</Button>
      )}

      {open && children}
    </li>
  );
};

export default forwardRef(NavItem);