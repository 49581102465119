import React from 'react';
import {
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';
import InpakopdrachtApiService from '../../Services/InpakopdrachtService';
import PalletApiService from '../../Services/PalletService';
import VrachtbriefApiService from '../../Services/VrachtbriefService';
import VerkooporderApiService from '../../Services/VerkooporderService';
import OrderDropdownItems from '../Verkooporders/Actions/OrderDropdownItem';

const DropdownActionsPallet = ({ verkooporder, inpakopdracht, vrachtbrieven, ...props }) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      title="Print &amp; download"
      id="acties"
      {...props}
    >
      <OrderDropdownItems
        id={inpakopdracht.id}
        api={InpakopdrachtApiService}
        label={'Inpakopdracht'}
        {...props}
        hideDivider
      />
      <OrderDropdownItems
        id={inpakopdracht.id}
        api={PalletApiService}
        label={'Palletformulieren'}
        {...props}
      />
      <OrderDropdownItems
        id={vrachtbrieven
          && vrachtbrieven[vrachtbrieven.length - 1]
          ? vrachtbrieven[vrachtbrieven.length - 1].id
          : null}
        api={VrachtbriefApiService}
        label={'Vrachtbrief'}
        disabled={verkooporder.status < 14}
        {...props}
      />

      <OrderDropdownItems
        id={verkooporder.id}
        api={VerkooporderApiService}
        label={'Orderbevestiging'}
        {...props}
        hideDivider
      />
    </DropdownButton>
  );
};

export default DropdownActionsPallet;