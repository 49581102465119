const searchKeys = [
  {
    value: 'id',
    label: 'Palletnummer'
  },
  {
    value: 'locatie',
    label: 'Locatie'
  }
];

export default {
  default: 'id',
  searchKeys
};