import React from 'react';
import {
  Alert,
} from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const ToegewezenPakketten = ({ control, total, totalAdressen,...props }) => {
  const bezorgadressen = useWatch({
    control,
    name: 'bezorgadressen',
    defaultValue: '0'
  });

  let amountAdressen = 0;
  let amount = 0;
  Object.entries(bezorgadressen).map(entry => {
    const [id, aantal] = entry;
    amountAdressen += aantal > 0 ? 1 : 0;
    amount += aantal ? parseInt(aantal) : 0;
  });

  let variant = 'danger';
  if (total === '' || (total === 0 || total === '0') || total < 0 || total < '0') {
    variant = 'danger';
  } else if (amount < total || amount > total) {
    variant = 'warning';
  } else {
    variant = 'success';
  }

  return (
    <Alert
      {...props}
      variant={variant}
    >
      {(amount === total && total > 0)
        ? (<><FaThumbsUp /> {" "}</>)
        : (<><FaThumbsDown /> {" "}</>)
      }
      <strong>{amount}</strong> / <strong>{total}</strong> {" "}
      pakketten toegewezen aan <strong>{amountAdressen}</strong> / <strong>{totalAdressen}</strong> bezorgadressen.
    </Alert>
  );

};

export default ToegewezenPakketten;