const hiddenColumns = [
  'inkoop_prijs',
  'leveranciersnummer',
  'leverancier_artikelnr',
  'besteleenheid_inhoud',
  'besteleenheid',
  'inhoud',
  'vraag',
  'breuk',
  'besteld',
  'voorraad',
  // 'aantal',
  // 'actions'
];

export default hiddenColumns;