import React, { useState } from 'react';
import {
  Card,
  Alert,
  Table,
  FormControl,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import {
  FaTruckLoading,
  FaPlus,
  FaEyeSlash,
  FaEye,
} from 'react-icons/fa';

import AddBezorgadres from '../Klanten/AddBezorgadres';

const BezorgadresFormPartRow = ({ bezorgadres, register, control, status }) => {
  const [defaultValue, setDefaultValue] = useState(bezorgadres.pivot ? bezorgadres.pivot.aantal : 0);
  const [palletAantal, setPalletAantal] = useState((bezorgadres.pivot && bezorgadres.pivot.pallet_aantal)
    ? bezorgadres.pivot.pallet_aantal
    : 0
  );
  return (
    <tr
      className={defaultValue > 0 ? 'table-primary' : ''}
    >
      <td className="align-middle">{bezorgadres.adres}</td>
      <td className="align-middle">{bezorgadres.postcode}</td>
      <td className="align-middle">{bezorgadres.plaatsnaam}</td>
      <td className="align-middle">
        <FormControl
          type="text"
          defaultValue={defaultValue === 0 ? '' : defaultValue}
          placeholder={defaultValue === 0 ? defaultValue : 0}
          name={`bezorgadressen[${bezorgadres.id}]`}
          id={`bezorgadressen[${bezorgadres.id}]`}
          ref={register}
          control={control}
          onChange={(e) => setDefaultValue(e.target.value)}
          readOnly={status >= 6}
          plaintext={status >= 6}
          autoComplete="off"
        />
      </td>
      <td className="align-middle">
        <FormControl
          type="text"
          defaultValue={palletAantal === 0 ? '' : palletAantal}
          placeholder={palletAantal === 0 ? palletAantal : 0}
          name={`pallets[${bezorgadres.id}]`}
          id={`pallets[${bezorgadres.id}]`}
          ref={register}
          readOnly={status >= 11}
          plaintext={status >= 11}
          // control={control}
          onChange={(e) => setPalletAantal(e.target.value)}
          autoComplete="off"
        />
      </td>
    </tr>
  );
};

const BezorgadresFormPart = ({ bezorgadressen, onAddBezorgadres, register, control, status }) => {
  if (bezorgadressen && bezorgadressen.length > 0) {
    return (
      <>
        <Card>
          <Card.Header
            className={`bg-dark text-light d-flex justify-content-between
            flex-wrap flex-md-nowrap align-items-center`}
          >
            <span><FaTruckLoading /> Pakketverdeling over bezorgadressen</span>
            <ButtonGroup>
              <AddBezorgadres
                label={
                  <>
                    <FaPlus /> {" bezorgadres"}
                  </>
                }
                klant={null}
                onAdd={bezorgadres => onAddBezorgadres(bezorgadres)}
              />
            </ButtonGroup>
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Adres</th>
                  <th>Postcode</th>
                  <th>Plaats</th>
                  <th>Aantal pakketten</th>
                  <th>Aantal pallets</th>
                </tr>
              </thead>
              <tbody>
                {bezorgadressen.map((bezorgadres, index) => {
                  return (
                    <BezorgadresFormPartRow
                      status={status}
                      key={index}
                      bezorgadres={bezorgadres}
                      register={register}
                      control={control}
                    />
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
  return (
    <Alert variant="info">
      Er zijn geen bezorgadressen gevonden bij de klant.
      <hr />
      <div className="d-flex justify-content-end">
        <AddBezorgadres
          label="Voeg nu een nieuw bezorgadres toe!"
          klant={null}
          onAdd={bezorgadres => onAddBezorgadres(bezorgadres)}
          variant="info"
        />
      </div>
    </Alert>
  )
};

export default BezorgadresFormPart;