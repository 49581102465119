const tableColumns = [
  {
    Header: 'Palletnummer',
    accessor: 'id',
    sortType: 'basic',
    id: 'id'
  },
  {
    Header: 'Locatie',
    accessor: (row, i) => {
      if (!row.locatie) {
        return (
          <>
            Geen locatie
          </>
        )
      }
      return (
        <>{row.locatie}</>
      )
    },
    sortType: 'basic',
    id: 'locatie'
  },
  {
    Header: 'Bezorgdatum',
    accessor: (row, i) => {
      const formatter = new Intl.DateTimeFormat('nl-nl', {
        day: '2-digit',
        month: "2-digit",
        year: "numeric"
      });
      const bezorgdatum = formatter.format(new Date(row.verkooporder.bezorgdatum));
      return (
        <>
          {bezorgdatum}
        </>
      )
    },
    sortType: 'basic',
    id: 'bezorgdatum'
  },
  // {
  //   Header: '# Artikelen',
  //   accessor: 'artikelen_aantal',
  //   sortType: 'basic',
  //   id: 'artikelen_aantal'
  // },
  // {
  //   Header: '# Pakketten',
  //   accessor: 'pakket_aantal',
  //   sortType: 'basic',
  //   id: 'pakket_aantal'
  // },
  // {
  //   Header: 'Totaal prijs excl.',
  //   accessor: (row, i) => {
  //     return (
  //       <>&euro; {formatCurrency(parseFloat(row.verkoopprijs_totaal))}</>
  //     )
  //   },
  //   sortType: 'basic',
  //   id: 'verkoopprijs_totaal'
  // },
];

export default tableColumns;