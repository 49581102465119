import { faExternalLink, faRandom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Image, OverlayTrigger, Popover } from 'react-bootstrap';
import quotes from '../../data/quotes';
import useTimeoutState from '../../Helpers/Hooks/useTimeoutState';

const QuoteWidget = () => {
  const [quote, setQuote] = useState(quotes[Math.floor((Math.random() * quotes.length))]);
  const [loading, setLoading] = useTimeoutState(false, { timeout: 200 });

  const setNewQuote = () => {
    setLoading(true, { timeout: 0 });
    setQuote(quotes[Math.floor((Math.random() * quotes.length))]);
    setLoading(false, { timeout: 200 });
  };

  if (!quote || loading) {
    return (
      <Card className="p-3">
        <Card.Body>
          <blockquote className="blockquote mb-0 card-body2">
              <p>Druk met een leuke quote uitzoeken...</p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  <span>
                    PP
                  </span>
                </small>
              </footer>
            </blockquote>
        </Card.Body>
      </Card>
    );
  }

  const popover = (
    (!quote.source && !quote.profession && !quote.url)
      ? (<Popover id="popover-basic">
          <Popover.Title as="h3">Helaas!</Popover.Title>
          <Popover.Content>
            Er is geen info bekend over deze quote.
          </Popover.Content>
        </Popover>)
      : (<Popover id="popover-basic">
        <Popover.Title as="h3">Over {quote.by} </Popover.Title>
        <Popover.Content>
          {quote.photo_url ? (
            <Image
              thumbnail
              src={quote.photo_url}
              />
          ) : null}
          {quote.profession ? (
            <p>
              <strong>Beroep:</strong>
              <br />
              {quote.profession}
            </p>
          ) : null}
          {quote.known_for ? (
            <p>
              <strong>{quote.known_for_label ? quote.known_for_label : 'Bekend van/door/om'}:</strong>
              <br /> {quote.known_for}
            </p>
          ) : null}

          {quote.url ? (
            <>
              <hr />
              <p>
                <a href={quote.url} rel="noopener noreferrer" target="_blank">
                  Meer informatie <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </p>
            </>
          ) : null}
        </Popover.Content>
      </Popover>)
  );

  return (
    <Card className="p-322">
      <Card.Body>
        <OverlayTrigger trigger={'click'} placement="bottom" overlay={popover}>
          {({ ref, ...triggerHandler }) => (
            <blockquote {...triggerHandler} className="blockquote mb-0 card-body" style={{cursor: 'pointer'}}>
              <p>{quote.quote}</p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  <span ref={ref}>
                    {quote.by}
                  </span>
                  {quote.source ? (
                    <p>
                      Bron: <cite title="Source Title">“{quote.source}“</cite>
                    </p>
                  ) : null}
                </small>
              </footer>
            </blockquote>
          )}
        </OverlayTrigger>
        <hr />
        <Button onClick={() => setNewQuote()}>
          <FontAwesomeIcon icon={faRandom} />
        </Button>
      </Card.Body>
    </Card>
  );
}

export default QuoteWidget;