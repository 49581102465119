import React from 'react';

import {
  ButtonGroup,
  DropdownButton
} from 'react-bootstrap';
import ApiService from '../../Services/InkoopService';

import OrderDropdownItems from './Actions/OrderDropdownItem';

const DropdownActionsPurchaseOrder = ({purchaseOrder}) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      title={'Print & download'}
      id={'acties'}
    >
      <OrderDropdownItems
        id={purchaseOrder.ordernummer}
        api={ApiService}
        label={'Inkooporder'}
        pageType={'both'}
      />
      <OrderDropdownItems
        id={purchaseOrder.ordernummer}
        api={ApiService}
        label={'Inkooporder-magazijn'}
        pageType={'internal'}
      />
      <OrderDropdownItems
        id={purchaseOrder.ordernummer}
        api={ApiService}
        label={'Inkooporder-leverancier'}
        pageType={'external'}
        hideDivider
      />
    </DropdownButton>
  )
};

export default DropdownActionsPurchaseOrder;