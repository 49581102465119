import React, { useState } from 'react';
import { Form, Col, Alert, Toast } from 'react-bootstrap';
import ApiService from '../../Services/AuthService';
import AuthManager from '../../Helpers/Auth/AuthManager';
import './LoginPage.css';
import LoaderButton from './LoaderButton';
import useLocalStorage from '../../Helpers/Hooks/useLocalStorage';
import LogoutToast from './LogoutToast';

const LoginPage = () => {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(null);
  const [, setToken] = useLocalStorage('token', null);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleAuthenticationError = (e) => {
    setAuthError({
      title: 'Authentication Failed',
      message: <><p>Unable to login!</p><p>Did you enter the correct credentials?</p></>,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    ApiService.login(email, password)
      .then(response => {
        setToken(response.data.token);
        AuthManager.setUser(response.data.user);
        setIsLoading(false);
        setAuthError(null);
      })
      .catch(e => {
        handleAuthenticationError(e);
        setValidated(false);
        setIsLoading(false);
        AuthManager.logout();
      });
  };

  return (
    <>
      <div className="Login">
        <div
          style={{
            position: 'relative',
            minHeight: '100px',
          }}
        >
          <LogoutToast />
        </div>
        <Form
          onSubmit={handleSubmit}
          className="shadow"
          noValidate
          validated={validated}
        >
          {authError ? (
            <Form.Row>
              <Col>
                <Alert variant="danger">
                  <Alert.Heading>{authError.title}</Alert.Heading>
                  {authError.message}
                </Alert>
              </Col>
            </Form.Row>
          ) : null}
          <Form.Row>
            <Col>
              <h3>Inloggen</h3>
            </Col>
          </Form.Row>

          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              required
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="valid">
              Het ingevulde email adres is geldig.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Dit is geen geldig email adres!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Wachtwoord</Form.Label>
            <Form.Control
              required
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="valid">
              Het wachtwoord is geldig.
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Dit is geen geldig wachtwoord!
            </Form.Control.Feedback>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="success"
            isLoading={isLoading}
            disabled={!validateForm()}
          >Inloggen</LoaderButton>
        </Form>
      </div>
      <div className="credits shadow">
          Product van <a href="https://pakketperfect.nl">PakketPerfect</a>
      </div>
    </>
  )
};

export default LoginPage;