import axios from "axios";

import AuthManager from "./Auth/AuthManager";

const baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

const ajax = axios.create({
  baseURL,
  timeout: 30000,
});

ajax.interceptors.request.use(request => {
  if (AuthManager.hasToken()) {
    request.headers.Authorization = `Bearer ${AuthManager.getToken()}`;
  }

  return request;
});

ajax.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    AuthManager.logout();
  }
  return error;
});

export const api = '/api';

export default ajax;