import { FaEdit } from "react-icons/fa";
import { formatCurrency } from "../../../Helpers/Helpers";
import InspectButton from "../../../Helpers/InspectButton";

const tableColumns = [
  {
    Header: 'Offertenummer',
    accessor: (row, i) => {
      return (
        <InspectButton
          variant="link"
          to={`/offertes/view/${row.offertenummer}`}
        >#{row.offertenummer}</InspectButton>
      )
    },
    sortType: 'basic',
    id: 'offertenummer'
  },
  {
    Header: 'Pakket naam',
    accessor: 'pakket_naam',
    sortType: 'basic',
    id: 'pakket_naam'
  },
  {
    Header: 'Klantnummer',
    accessor: (row, i) => {
      return (
        <InspectButton
          variant="link"
          to={`/klanten/edit/${row.klant.nummer}`}
        >#{row.klant.nummer}</InspectButton>
      )
    },
    sortType: 'basic',
    id: 'klantnummer'
  },
  {
    Header: 'Klant',
    accessor: (row, i) => {
      return (
        <InspectButton
          variant="link"
          to={`/klanten/edit/${row.klant.nummer}`}
        >{row.klant.bedrijfsnaam}</InspectButton>
      )
    },
    sortType: 'basic',
    id: 'klant_bedrijfsnaam'
  },
  {
    Header: '# Artikelen',
    accessor: 'artikelen_aantal',
    sortType: 'basic',
    id: 'artikelen_aantal'
  },
  {
    Header: '# Pakketten',
    accessor: 'pakket_aantal',
    sortType: 'basic',
    id: 'pakket_aantal'
  },
  {
    Header: 'Totaal prijs excl.',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(parseFloat(row.verkoopprijs_totaal))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal'
  },
];

export default tableColumns;