import React, { useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faExclamationTriangle, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import WidgetApiService from '../../Services/WidgetService';
import { laravelToJsDate, isToday } from '../../Helpers/Helpers';
import useShowing from '../../Helpers/Hooks/useShowing';

const NextDeliveryRow = ({delivery}) => {
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveries, setDeliveries] = useState(0);

  useEffect(() => {
    setDeliveryDate(laravelToJsDate(delivery.delivery_date.date));
    setDeliveries(delivery.total_deliveries);
  }, [delivery]);

  if (deliveryDate !== null) {
    return (
      <ListGroup.Item variant={isToday(deliveryDate) ? 'primary' : 'light'}>
        <strong>
          {isToday(deliveryDate)
            ? 'Vandaag'
            : deliveryDate.toLocaleDateString('nl-NL')
          }: {deliveries} order{(deliveries > 1 || deliveries < 1) ? 's' : null}
        </strong>
      </ListGroup.Item>
    );
  } else {
    return null;
  }
};

const NextDeliveriesWidget = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const { isShowing, toggle } = useShowing();

  useEffect(() => {
    WidgetApiService.getNextDeliveries()
      .then(response => {
        setDeliveries(response.data);
        setLoading(false);
        setFailed(false);
      })
      .catch(error => {
        setFailed(true);
        setLoading(false);
      });
  }, []);

  if (failed && !loading) {
    return (
      <Card
        bg={'danger'}
        text={'light'}
        className={'text-center'}
      >
        <Card.Body>
          Bezorgingen verzamelen is mislukt.
        </Card.Body>
      </Card>
    );
  }

  if (loading && !failed) {
    return (
      <Card
        bg={'secondary'}
        text={'light'}
        className={'text-center'}
      >
        <Card.Body>
          Bezorgingen berekenen...
        </Card.Body>
      </Card>
    );
  }
  const listGroupClass = "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center";

  return (
    <Card
      bg={deliveries ? 'primary' : 'secondary'}
      text={'light'}
      className={'text-center'}
    >
      <Card.Header className={listGroupClass}>
        <span><FontAwesomeIcon icon={faTruckFast}/> Bezorgingen</span>
        <span>
          <Button
            size={'sm'}
            variant={'light'}
            onClick={toggle}
          >{isShowing ? 'Minder' : 'Meer'} <FontAwesomeIcon icon={isShowing ? faCaretUp : faCaretDown} /></Button>
        </span>
      </Card.Header>

      {deliveries ? (
        <ListGroup variant={'flush'}>
          {deliveries.slice(0, isShowing ? -1 : 5).map(delivery => {
            return (
              <NextDeliveryRow
                delivery={delivery}
                key={`${delivery.delivery_date.date}-${delivery.total_deliveries}`}
              />
            )}
          )}
        </ListGroup>
      ) : null}

      {!deliveries ? (
        <Card.Body variant={'primary'}>
          <FontAwesomeIcon icon={faExclamationTriangle}/> 0
        </Card.Body>
      ) : null }
    </Card>
  )
};

export default NextDeliveriesWidget;