import React from 'react';
import {
  Table,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FaFileDownload, FaPrint, FaHandshake } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import ButtonTooltip from '../../Helpers/ButtonTooltip';
import InspectButton from '../../Helpers/InspectButton';
import ApiService from '../../Services/OfferteService';

const OfferteTableRow = ({offerte}) => {
  let history = useHistory();
  return (
    <tr>
      <td className="align-middle">
        <InspectButton
          to={`/offertes/edit/${offerte.offertenummer}`}
        >#{offerte.offertenummer}</InspectButton>
      </td>
      <td className="align-middle">
        {offerte.pakket_naam}
      </td>
      <td className="align-middle">
        <InspectButton
          to={`/klanten/edit/${offerte.klant.nummer}`}
        >{offerte.klant.bedrijfsnaam}</InspectButton>
      </td>
      <td className="align-middle">&euro; {offerte.verkoopprijs_totaal}</td>
      <td className="align-middle">
        <ButtonGroup>
          <ButtonTooltip
            variant="success"
            onClick={() => history.push(`/verkooporders/add/${offerte.offertenummer}`)}
            text={'Geef akkoord en zet om naar verkooporder'}
          ><FaHandshake /></ButtonTooltip>
          <Button onClick={() => ApiService.downloadPDF(offerte.id, `offerte-${offerte.id}.pdf`)}>
            <FaFileDownload />
          </Button>
          <Button onClick={() => ApiService.printPDF(offerte.id)}>
            <FaPrint />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

const OfferteTable = ({ offertes }) => {
  return (
    <Table bordered striped>
      <thead className="thead-dark">
        <tr>
          <th>Offertenummer</th>
          <th>Pakketnaam</th>
          <th>Klant</th>
          <th>Prijs</th>
          <th>Acties</th>
        </tr>
      </thead>
      <tbody>
        {offertes && offertes.map((offerte, index) => {
          return (
            <OfferteTableRow key={index} offerte={offerte} />
          )
        })}
      </tbody>
    </Table>
  );
};

export default OfferteTable;