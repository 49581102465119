import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, ButtonGroup, Container } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight, FaEdit, FaFileDownload, FaPrint } from 'react-icons/fa';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/OfferteService';
import OfferteConfirmButton from './OfferteConfirmButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const ViewOfferte = () => {
  const [offertePDFBase64, setOffertePDFBase64] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  let { offertenummer } = useParams();
  let history = useHistory();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    if (offertenummer) {
      ApiService.getBase64PDF(offertenummer)
        .then(response => {
          setOffertePDFBase64(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = () => {
    const prevPage = pageNumber - 1;
    if (prevPage === 0) {
      setPageNumber(numPages);
    } else {
      setPageNumber(prevPage);
    }
  }

  const goToNextPage = () => {
    const nextPage = pageNumber + 1;
    if (nextPage === numPages + 1) {
      setPageNumber(1);
    } else {
      setPageNumber(nextPage);
    }
  }

  return (
    <Container fluid>
      <BodyTitleBar title={`Offerte #${offertenummer} bekijken`}>
        <ButtonGroup>
          <OfferteConfirmButton offertenummer={offertenummer} label="Bevestigen" />
          <Button
            variant="danger"
            onClick={() => {
              history.push(`/offertes/edit/${offertenummer}`)
            }}
          ><FaEdit /> Bewerken</Button>
        </ButtonGroup>
      </BodyTitleBar>
      <p>Pagina {pageNumber} van {numPages}</p>
      <ButtonGroup>
        <Button
          disabled={numPages === 1}
          onClick={() => goToPreviousPage()}
        ><FaAngleLeft /></Button>
        <Button
          disabled={numPages === 1}
          onClick={() => goToNextPage()}
        ><FaAngleRight /></Button>
      </ButtonGroup>{" "}
      <ButtonGroup>
        <Button onClick={() => ApiService.downloadPDF(offertenummer, `offerte-${offertenummer}.pdf`)}>
          <FaFileDownload />
        </Button>
        <Button onClick={() => ApiService.printPDF(offertenummer)}>
          <FaPrint />
        </Button>
      </ButtonGroup>
      {offertePDFBase64 != '' && (
        <Document
          file={`data:application/pdf;base64,${offertePDFBase64}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      )}
    </Container>
  );
};

export default ViewOfferte;