const searchKeys = [
  {
    value: 'artikelnr',
    label: 'Artikelnummer'
  },
  {
    value: 'leverancier_artikel_nummer',
    label: 'Artikelnummer (leverancier)'
  },
  {
    value: 'omschrijving',
    label: 'Omschrijving'
  },
  {
    value: 'inkoop_prijs',
    label: 'Prijs (inkoop)'
  },
  // {
  //   value: 'verkoop_prijs',
  //   label: 'Prijs (verkoop)'
  // },
  {
    value: 'btw',
    label: 'BTW (0, 1, 2)'
  },
  {
    value: 'besteleenheid',
    label: 'Besteleenheid (ST, DS, KR, TR)'
  },
  {
    value: 'inhoud',
    label: 'Inhoud'
  },
  {
    value: 'leveranciers_nummer',
    label: 'Leverancier (nummer)'
  },
  {
    value: 'leverancier.bedrijfsnaam',
    label: 'Leverancier (bedrijfsnaam)'
  }
]

const searchKeysOutput = {
  default: 'artikelnr',
  searchKeys: searchKeys
}

export default searchKeysOutput