import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaAt } from 'react-icons/fa';

const TelButton = ({
  variant = 'link',
  to = '',
  icon = <FaAt />,
  size = 'sm',
  iconPosition = 'left',
  children,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);

  return (
    <Button
      size={size}
      variant={hasError ? 'danger' : variant}
      onClick={() => {
        if (to === '') {
          setHasError(true);
        } else {
          setHasError(false);
          window.location = `mailto:${to}`;
        }
      }}
      {...props}
    >
      {iconPosition === 'left' && <>{icon}{' '}</>}
      {children}
      {iconPosition === 'right' && <>{' '}{icon}</>}
    </Button>
  );
};

export default TelButton;