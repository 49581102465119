import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  InputGroup,
  Alert,
  Badge,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import { FaCaretDown, FaCaretUp, FaCog, FaSort } from 'react-icons/fa';
import {
  useHistory,
} from 'react-router-dom'

import { getPageFromUrl } from '../Helpers';
import SearchComponent from './SearchComponent';
import PaginationElement from './PaginationElement';
import useAxios from '../Hooks/useAxios';
import useShowing from '../Hooks/useShowing';
import useQuery from '../Hooks/useQuery'
import TableCheckbox from './TableCheckbox';
import LoadingText from '../LoadingText';

import GlobalModal from './GlobalModal';
import useLocalStorageWithLazyCallback from '../Hooks/useLocalStorageWithLazyCallback';

const defaultPropGetter = () => ({});
const defaultRowSelect = () => {};

const PaginatedTable = ({
  api,
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys,
  apiOperators = [{
    operator: 'Bevat',
    key: 'LIKE'
  }],
  onRowSelect = defaultRowSelect,
  showSearchInfo,
  setSearchInfo,
  autoReset = {
    hiddenColumns: false,
    sortBy: false,
    resetPage: false,
    resetFilters: false,
  },
  getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  size = '',
  responsive = true,
  forceReset = false,
  setForceReset = () => {},
  pageSizes = [5, 10, 15, 20, 25, 30, 50],
  defaultPageSize = 20,
  initialSortDir = 'asc',
  localStorageId = '_',
}) => {
  const initialPagination = {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: null,
    links: [],
    next_page_url: null,
    path: 0,
    prev_page_url: null,
    to: 0,
    total: 0
  };
  const defaults = {
    perPage: defaultPageSize
  };
  const initialSearchState = {
    key: searchKeys.default,
    query: ''
  };

  useEffect(() => {
    setSearch(prevState => {
      return {
        ...prevState,
        key: searchKeys.default
      };
    });
  }, [searchKeys]);

  const [pagination, setPagination] = useState(initialPagination);
  const [search, setSearch] = useState(initialSearchState);
  const [searchOperator, setSearchOperator] = useState('LIKE');

  const [sorted, setSorted] = useState(false);
  const [reset, setReset] = useState(false);

  const [url, setUrl] = useState('');
  const [data, setData] = useState([]);

  const [persistedPerPage, setPersistedPerPage] = useLocalStorageWithLazyCallback(
    `table_${localStorageId}`,
    defaults.perPage
  );

  const { status, data: apiResultData, error } = useAxios(url, true);
  const { isShowing: showSettings, toggle: toggleShowSettings } = useShowing();

  const [noDataFound, setNoDataFound] = useState(false);

  const columns = tableColumns;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    setHiddenColumns,
    state: { sortBy }
  } = useTable(
    {
      manualSortBy: true,
      autoResetHiddenColumns: autoReset.hiddenColumns,
      autoResetSortBy: autoReset.sortBy,
      autoResetPage: autoReset.resetPage,
      autoResetFilters: autoReset.filters,
      columns,
      data,
      initialState: {
        hiddenColumns
      }
    },
    useSortBy
    );

  useEffect(() => {
    if (apiSortKeys.includes(sortBy[0]?.id)) {
      retrieveFromApi(pagination.current_page);
      setSorted(true);
      return;
    }
    if (sorted) {
      setSorted(false);
      retrieveFromApi(pagination.current_page);
    }
  }, [sortBy]);

  useEffect(() => {
    retrieveFromApi();
  }, []);

  useEffect(() => {
    if (reset) {
      retrieveFromApi(1);
      setReset(false);
      if (setForceReset) {
        setForceReset(false);
      }
    }
  }, [reset]);

  useEffect(() => {
    setReset(true);
  }, [forceReset]);

  useEffect(() => {
    if (apiResultData.data) {
      setData(apiResultData.data);
      setPagination(apiResultData);
      setSearchInfo && setSearchInfo(prevState => {
        return {
          ...prevState,
          itemsFound: apiResultData.total,
          pages: apiResultData.last_page,
          currentPage: apiResultData.current_page,
          from: apiResultData.from,
          to: apiResultData.to,
          perPage: apiResultData.per_page,
          showSearchInfo: prevState.showSearchInfo
        }
      });
    }
  }, [apiResultData]);

  useEffect(() => {
    setNoDataFound(status === 'fetched' && data.length === 0);
  }, [data]);

  const retrieveFromApi = (page = 1, pp = 0) => {
    const amountOfItems = pp > 0 ? pp : persistedPerPage;

    const sort = {
      key: sortBy[0] ? sortBy[0].id : initialSearchState.key,
      dir: sortBy[0]
        ? sortBy[0].desc
          ? 'desc'
          : 'asc'
        : initialSortDir
    };

    const url = api.getAllUrl(page, amountOfItems, search, sort, searchOperator);
    setUrl(url);
  };

  const goTo = (url) => {
    retrieveFromApi(getPageFromUrl(url));
  };

  const resetSearch = () => {
    setSearch(initialSearchState);
    setReset(true);
  };

  const resetSettings = () => {
    setHiddenColumns(hiddenColumns);
    setPersistedPerPage(defaults.perPage, value => retrieveFromApi(1, value));
    setSearchInfo && setSearchInfo(prevState => {
      return {
        ...prevState,
        showSearchInfo: true
      }
    });
  };

  const onChangePerPage = e => {
    setPersistedPerPage(e.target.value, value => retrieveFromApi(1, value));
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const onChangeOperator = (e) => {
    setSearchOperator(e.target.value);
  }

  return (
    <>
      <GlobalModal
        isShowing={showSettings}
        hide={toggleShowSettings}
        closeButton
        headerClass={'bg-secondary text-light'}
        header={<span><FaCog /> <strong>Tabel instellingen</strong></span>}
        body={(
          <>
            <Row className="pb-2 border-bottom">
              <Col>
                Aantal rijen per pagina
              </Col>
              <Col>
                <FormControl
                  custom
                  as={'select'}
                  onChange={onChangePerPage}
                  value={persistedPerPage}
                >
                  {pageSizes.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>Toon {pageSize}</option>
                  ))}
                </FormControl>
              </Col>
            </Row>
            {setSearchInfo && (
              <Row className="mt-3 pb-2 border-bottom">
                <Col>
                  Zoek informatie
                </Col>
                <Col>
                  <TableCheckbox
                    label={'Toon'}
                    checked={showSearchInfo}
                    onChange={(e) => {
                      setSearchInfo && setSearchInfo(prevState => {
                        return {
                          ...prevState,
                          showSearchInfo: e.target.checked
                        }
                      });
                    }}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-3 pb-2 border-bottom">
              <Col>
                Toon alle kolommen
            </Col>
              <Col>
                <TableCheckbox label={'Alles'} {...getToggleHideAllColumnsProps()} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                Kolommen
            </Col>
              <Col>
                {allColumns.map(column => {
                  return (
                    <div key={column.id}>
                      <label>
                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                        {column.Header}
                        {apiSortKeys.includes(column.id) && (
                          <>
                            {' '}
                            <sup>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 250 }}
                                overlay={<Tooltip id="button-tooltip">Online sorteerbaar</Tooltip>}
                              >
                                {({ ref, ...triggerHandler }) => (
                                  <Badge
                                    ref={ref}
                                    {...triggerHandler}
                                    variant="info"
                                  ><FaSort /></Badge>
                                )}
                              </OverlayTrigger>
                            </sup>
                          </>
                        )}
                      </label>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </>
        )}
        footer={(
          <>
            <Button
              variant={'secondary'}
              onClick={resetSettings}>Herstel</Button>
            <Button
              variant="info"
              onClick={() => toggleShowSettings(!showSettings)}
            >Sluit</Button>
          </>
        )}
      />
      <Row className="mb-1">
        <Col sm={12} md={1}>
          <Button
            variant="secondary"
            onClick={toggleShowSettings}
          ><FaCog /></Button>
        </Col>
        <Col sm={12} md={11}>
          <SearchComponent
            operators={apiOperators}
            searchKeys={searchKeys.searchKeys}
            search={search}
            searchOperator={searchOperator}
            onChangeOperator={onChangeOperator}
            onChangeSearch={onChangeSearch}
            onSubmit={() => retrieveFromApi(1)}
            onReset={resetSearch}
          />
        </Col>

      </Row>
      <Row className="mb-1 mt-1">
        <Col sm={12} md={4}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Ga naar pagina</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              defaultValue={pagination.current_page}
              onChange={(e) => {
                let page = e.target.value;
                if (page === '') {
                  return;
                }
                if (page < 1) {
                  page = 1;
                }
                if (page > pagination.last_page) {
                  page = pagination.last_page;
                }
                retrieveFromApi(page)
              }}
            />
          </InputGroup>
        </Col>
        <Col sm={12} md={8}>
          <PaginationElement
            goTo={goTo}
            pagination={pagination}
          />
        </Col>
      </Row>
      <Row>
      </Row>
      <Row>
        <Col>
          {noDataFound && (
            <Alert variant="warning">Er is geen data gevonden om te tonen.</Alert>
          )}
          {data.length === 0 && status === 'error' && (
            <Alert variant="danger">{error}</Alert>
          )}

          <Table {...getTableProps()} size={size} striped bordered responsive={responsive}>
            <thead className="table-dark">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps([
                        column.getSortByToggleProps()
                      ])}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? (<>{' '} <FaCaretDown /></>)
                            : (<>{' '} <FaCaretUp /></>)
                          : ''
                        }
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {status === 'fetched' && (
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps(getRowProps(row))}
                      onClick={() => onRowSelect(row.original, row.original.id)}
                    >
                      {row.cells.map((cell, j) => {
                        return (
                          <td
                            rowSpan={row.rowSpan}
                            {...cell.getCellProps([
                              {
                                className: `${cell.column.className} align-middle`,
                                style: {
                                  ...cell.column.style,
                                }
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),
                            ])}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            )}
          </Table>
          {status === 'fetching' && (
            <Alert variant="info"><LoadingText /></Alert>
          )}
        </Col>
      </Row>
      <Row className="mb-1 mt-1">
        <Col>
          <PaginationElement
            goTo={goTo}
            pagination={pagination}
          />
        </Col>
      </Row>
    </>
  );
};
export default PaginatedTable;