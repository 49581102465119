import React, {useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, InputGroup, Card} from 'react-bootstrap';
import api from '../../Services/PakketService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import PakketInformatie from './PakketInformatie';
import { useHistory } from 'react-router-dom';
import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';


import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/tableData';

const PakkettenList = () => {
  const [currentPakket, setCurrentPakket] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const setActivePakket = (pakket, index) => {
    setCurrentPakket(pakket);
    setCurrentIndex(index);
  };

  return (
    <Container fluid>
      <BodyTitleBar title="Pakketten" btnTo="/pakketten/add" btnTitle="Nieuw pakket samenstellen" />
      <Row>
        <Col lg={7} md={7} sm={12}>
          <PaginatedTable
            api={api}
            tableColumns={tableColumns}
            hiddenColumns={hiddenColumns}
            searchKeys={searchKeys}
            apiSortKeys={apiSortKeys}
            onRowSelect={(dataObj, index) => setActivePakket(dataObj, index)}
            getRowProps={row => {
              let variant = row.original.id === currentIndex ? 'table-active' : '';
              return ({
                className: variant,
                style: {
                  cursor: 'context-menu'
                }
              });
            }}
            localStorageId={'gift_boxes_list'}
          />
        </Col>
        <Col lg={5} md={5} sm={12}>
          {
            currentPakket ? (
              <PakketInformatie pakket={currentPakket} />
            ) : (
              <Card bg="info" text="white" className="sticky-top">
                <Card.Header>
                  <strong>Informatie</strong>
                </Card.Header>
                <Card.Body>
                  Voor meer informatie selecteer een pakket.
                </Card.Body>
              </Card>
            )
          }
        </Col>
      </Row>
    </Container>
  );
}

export default PakkettenList;