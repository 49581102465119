import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import VerkooporderApiService from '../../Services/VerkooporderService';

const parseIsoDateToDutchDate = (
  date,
  options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}) => {
  if (date === null) {
    return '';
  }
  const parsedDate = new Date(Date.parse(date));

  return parsedDate.toLocaleDateString('nl-NL', options);
}

const ArchivedOrdersList = ({ orders }) => {
  return (
    <>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Aanmaakdatum</th>
                <th>Laatste wijziging</th>
                <th>Aantal wijzigingen</th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.map((order, index) => (
                <tr key={index}>
                  <td>
                    <Button
                      variant='outline-primary'
                      size='sm'
                      onClick={() => VerkooporderApiService.getArchivedVerkooporderPDF(order.ordernummer)}
                    ><FontAwesomeIcon icon={faFileDownload} /> #{order.ordernummer}</Button>
                  </td>
                  <td>{parseIsoDateToDutchDate(order.created_at)}</td>
                  <td>{parseIsoDateToDutchDate(order.updated_at)}</td>
                  <td>{order.version}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
};

export default ArchivedOrdersList;