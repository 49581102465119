import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';

import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';
import api from '../../Services/OfferteService';
import OfferteConfirmButton from './OfferteConfirmButton';
import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/tableData';

import { FaPrint, FaFileDownload, FaEdit, FaSearch } from 'react-icons/fa';
import ButtonTooltip from '../../Helpers/ButtonTooltip';

const OfferteList = () => {
  let history = useHistory();
  const customColumns = [
    ...tableColumns,
    {
      Header: 'Acties',
      accessor: (row, i) => {
        return (
          <>
            <ButtonGroup>
              <OfferteConfirmButton offertenummer={row.offertenummer} />
              <ButtonTooltip
                text="Offerte bekijken"
                variant="outline-secondary"
                onClick={() => history.push(`offertes/view/${row.offertenummer}`)}
              ><FaSearch /></ButtonTooltip>
              <Button
                variant="danger"
                onClick={() => {
                  history.push(`/offertes/edit/${row.offertenummer}`)
                }}
              ><FaEdit /></Button>
              <Button
                onClick={() => api.downloadPDF(row.offertenummer, `offerte-${row.offertenummer}.pdf`)}
              ><FaFileDownload /></Button>
              <Button
                onClick={() => api.printPDF(row.offertenummer)}
              ><FaPrint /></Button>
            </ButtonGroup>
          </>
        )
      },
      disableSortBy: true,
      id: 'actions'
    }
  ];

  return (
    <>
    <BodyTitleBar title="Offertes" />
    <Container fluid>
      <Row>
        <Col>
          <PaginatedTable
            api={api}
            tableColumns={customColumns}
            hiddenColumns={hiddenColumns}
            searchKeys={searchKeys}
            apiSortKeys={apiSortKeys}
            localStorageId={'invoices_list'}
          // onRowSelect={(dataObj, index) => setActiveArtikel(dataObj, index)}
          // showSearchInfo={searchInfo.showSearchInfo}
          // setSearchInfo={info => setSearchInfo(info)}
          // getRowProps={row => {
          //   let variant = row.original.id === currentIndex ? 'table-active' : '';
          //   return ({
          //     className: variant
          //   });
          // }}
          />

        </Col>
      </Row>
    </Container>
    </>
  )
};

export default OfferteList;