import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaPrint, FaFileDownload } from 'react-icons/fa';

const OrderDropdownItems = ({ api, id, label, disabled, hideDivider, ...props }) => {
  if (!id) {
    return (<></>);
  }
  return (
    <>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => api.downloadPDF(id, `${label}.pdf`)}
        {...props}
      ><FaFileDownload /> {label}</Dropdown.Item>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => api.printPDF(id)}
        {...props}
      ><FaPrint /> {label}</Dropdown.Item>
      <Dropdown.Divider hidden={hideDivider} />
    </>
  )
};

export default OrderDropdownItems;