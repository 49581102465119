import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaUser, FaUserFriends, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadingText from '../../Helpers/LoadingText';

import ApiService from '../../Services/KlantService';

const AantalKlantenWidget = () => {
  const [klanten, setKlanten] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiService.getAll()
      .then(response => {
        setKlanten(response.data.length);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  let history = useHistory();

  const redirect = () => {
    history.push(`/klanten/add`);
  }

  const redirectTo = () => {
    history.push(`/klanten`);
  }

  if (loading) {
    return (
      <Card
        bg="secondary"
        text="light"
        className="text-center"
      >
        <Card.Body>
          <LoadingText text="Klanten tellen" />
        </Card.Body>
      </Card>
    );
  }

  if (!loading && klanten === 0) {
    return (
      <Card text="black" className="text-center">
        <Card.Header>
          <FaUserFriends /> Klanten
        </Card.Header>
        <Card.Body>
          <Button
            onClick={redirect}
          ><FaUser /> Voeg de eerste klant toe</Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card text="black" className="text-center">
      <Card.Header className="d-flex justify-content-between">
        <span><FaUserFriends /> Klanten</span>
        <Button
          variant="primary"
          onClick={redirect}
        ><FaPlus /></Button>
      </Card.Header>
      <Card.Body
        style={{ cursor: 'pointer' }}
        onClick={redirectTo}
      >
        <h1><FaUserFriends /> {klanten}</h1>
      </Card.Body>
    </Card>
  );
};

export default AantalKlantenWidget;