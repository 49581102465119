import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { hellip } from './Helpers';

const DefaultTooltip = ({ children = '', text = '' }) => {
  return (
    <OverlayTrigger>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{text ?? hellip()}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <span
            ref={ref}
            {...triggerHandler}
          >{children}</span>
        )}
      </OverlayTrigger>
    </OverlayTrigger>
  );
};

export default DefaultTooltip;