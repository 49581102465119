const apiSortKeys = [
  'offertenummer',
  'klantnummer',
  'pakket_aantal',
  'artikelen_aantal',
  'pakket_id',
  'pakket_naam',
  'offerte_datum',
  'verkoopprijs_totaal',
  'verkoopprijs_totaal_btw_hoog',
  'verkoopprijs_totaal_btw_laag',
  'verkoopprijs_totaal_btw_nul',
  'verkoopprijs_totaal_incl'
];

export default apiSortKeys;