import ajax, { api } from '../Helpers/http-common';

const getAll = (page = 1) => {
  return ajax.get(`/voorraad?page=${page}`);
};

const findBy = (key, value) => {
  return ajax.get(`/artikelen/find/${key}/${value}`);
};

const mutateStock = (id, data) => {
  return ajax.post(`${api}/stock/${data.mutator}/${id}`, {
    quantity: data.quantity,
    destination_location: data.destination_location,
    from_location: data.from_location,
  });
}

const DefaultExport = {
  getAll,
  findBy,
  mutateStock,
};

export default DefaultExport;