const quotes = [
  {
    by: 'Helen Steiner Rice',
    url: 'https://en.wikipedia.org/wiki/Helen_Steiner_Rice',
    photo_url: null,
    profession: 'Zij was een Amerikaanse dichteres',
    known_for: null,
    source: null,
    quote: 'Peace on earth will come to stay, when we live Christmas every day.',
  },
  {
    by: 'J.K. Rowling',
    url: 'https://nl.wikipedia.org/wiki/J.K._Rowling',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/J._K._Rowling_2010.jpg/532px-J._K._Rowling_2010.jpg',
    profession: 'Zij is een Britse schrijfster',
    known_for: 'Harry Potter',
    source: 'Harry Potter And The Philosopher\'s Stone',
    quote: '’One can never have enough socks,’ said Dumbledore. ’Another Christmas has come and gone and I didn\'t get a single pair.’',
  },
  {
    by: 'J.R.R Tolkien',
    url: 'https://nl.wikipedia.org/wiki/J.R.R._Tolkien',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/J._R._R._Tolkien%2C_ca._1925.jpg/532px-J._R._R._Tolkien%2C_ca._1925.jpg',
    profession: 'Hij was een Britse schrijver',
    known_for: 'The Hobbit en The Lord of the Rings',
    source: 'The Hobbit',
    quote: 'If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.',
  },
  {
    by: 'Dr. Seuss',
    url: 'https://nl.wikipedia.org/wiki/Dr._Seuss',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Ted_Geisel_NYWTS_2_crop.jpg/532px-Ted_Geisel_NYWTS_2_crop.jpg',
    profession: 'Hij was een Amerikaanse schrijver, cartoonist',
    known_for: 'How the Grinch Stole Christmas en The Lorax',
    source: 'How the Grinch Stole Christmas',
    quote: '’Maybe Christmas,’ he thought, ‘doesn’t come from a store. Maybe Christmas… perhaps… means a little bit more!’',
  },
  {
    by: 'Matt Groening',
    url: 'https://nl.wikipedia.org/wiki/Matt_Groening',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Matt_Groening_by_Gage_Skidmore_2.jpg/400px-Matt_Groening_by_Gage_Skidmore_2.jpg',
    profession: 'Hij is een Amerikaanse cartoonist',
    known_for: 'The Simpsons en Futurama',
    source: null,
    quote: 'Aren’t we forgetting the true meaning of Christmas? You know, the birth of Santa?',
  },
  {
    by: 'Laura Ingalls Wilder',
    url: 'https://nl.wikipedia.org/wiki/Laura_Ingalls_Wilder',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/1/10/Laura_Ingalls_Wilder.jpg',
    profession: 'Zij was een Amerikaanse schrijfster',
    known_for: 'Little House on the Prairie',
    source: null,
    quote: 'We are better throughout the year for having, in spirit, become a child again at Christmastime.',
  },
  {
    by: 'Bernard Manning',
    url: 'https://nl.wikipedia.org/wiki/Bernard_Manning',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Bernard_manning.jpg/520px-Bernard_manning.jpg',
    profession: 'Hij was een Britse komiek',
    known_for: null,
    source: null,
    quote: 'I once bought my kids a set of batteries for Christmas with a note on it saying, toys not included.',
  },
  {
    by: 'Unknown',
    url: null,
    photo_url: null,
    profession: null,
    known_for: null,
    source: null,
    quote: 'May you never be too grown up to search the skies on Christmas Eve.',
  },
  {
    by: 'Unknown',
    url: null,
    photo_url: null,
    profession: null,
    known_for: null,
    source: null,
    quote: 'I’m dreaming of a white Christmas. But if the white runs out, I’ll drink the red.',
  },
  {
    by: 'Jos de Boer',
    url: null,
    photo_url: null,
    profession: 'Hij is eigenaar van De Boer & Schuur',
    known_for: null,
    source: null,
    quote: 'One smile a day keeps the grinch away.',
  },
  {
    by: 'Winnie the Pooh',
    url: 'https://nl.wikipedia.org/wiki/Winnie_de_Poeh',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Winnie-the-Pooh_80-2.png/340px-Winnie-the-Pooh_80-2.png',
    profession: null,
    known_for: null,
    source: null,
    quote: 'Christmas is a together-y sort of holiday',
  },
  {
    by: 'Stephen Jones',
    url: 'https://nl.wikipedia.org/wiki/Stephen_Jones_(hoedenmaker)',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Stephen_Jones_%281%29.jpg/260px-Stephen_Jones_%281%29.jpg',
    profession: 'Hij is een Engelse hoedenmaker',
    known_for_label: 'Zijn hoeden zijn gedragen door',
    known_for: 'Spice Girls, Robby Williams, U2 en George Michael',
    source: null,
    quote: 'Some people are born for Halloween, and some are just counting the days until Christmas.',
  },
  {
    by: 'Charles M. Schulz',
    photo_url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Charles_Schulz_NYWTS.jpg/266px-Charles_Schulz_NYWTS.jpg',
    url: 'https://nl.wikipedia.org/wiki/Charles_M._Schulz',
    profession: 'Hij was een Amerikaanse striptekenaar',
    known_for: 'Peanuts',
    source: null,
    quote: 'Christmas is doing a little something extra for someone.',
  },
];

export default quotes;