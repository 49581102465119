import React from 'react';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import CookieConsent from "react-cookie-consent";

import Routes from './Routes';
import LoginPage from './Components/Auth/LoginPage';
import Layout from './Helpers/Layouts/Layout';
import useReadLocalStorage from './Helpers/Hooks/useReadLocalStorage';

registerLocale('nl', nl);
setDefaultLocale('nl');

const App = () => {
  const isLoggedIn = useReadLocalStorage('token');

  return (
    <>
      {(!!isLoggedIn) ? (
        <Layout><Routes /></Layout>
      ) : <LoginPage />}

      <CookieConsent buttonText="Oké!">
        Ter verbetering van onze dienstverlening wordt op onze site gebruik gemaakt van cookies. Door gebruik te maken van deze website gaat u akkoord met het gebruik van cookies op deboerschuur.pakketperfect.nl. Wij gebruiken cookies voor het kunnen meten van bezoeken aan deze website. Deze data worden geanalyseerd en waar mogelijk wordt het gebruikt om deze website te verbeteren qua structuur en/of geboden content.
      </CookieConsent>
    </>
  );
};

export default App;