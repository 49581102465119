import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Button, Card, CardColumns, Container } from 'react-bootstrap';
import useLocalStorage from '../../Helpers/Hooks/useLocalStorage';
import background_patterns from '../../Helpers/Layouts/background_patterns';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';

const SidebarBackgroundSelector = () => {
  const [pattern, setPattern] = useLocalStorage('sidebar_pattern',
    {
      pattern: background_patterns.GeometryPattern,
      name: background_patterns.GeometryPattern,
    }
  );
  useEffect(() => {
  }, [pattern]);

  return (
    <Container fluid>
      <BodyTitleBar title={<><FontAwesomeIcon icon={faImage} /> Kies de achtergrond voor het zij menu</>} />
        <CardColumns>
        {Object.values(background_patterns).map((bgPattern) => {
          return (
            <Card
              key={bgPattern.name}
            >
              <Card.Img variant="top" src={bgPattern.pattern} style={{minWidth: 200, minHeight: 200, width: '100%', height: '100%', }} />
              <Card.ImgOverlay>
                <Card.Title>{bgPattern.display_name}</Card.Title>
                <Button
                  size="lg"
                  variant={pattern.name === bgPattern.name ? 'success' : 'primary'}
                  onClick={() => setPattern(bgPattern)}
                >{pattern.name === bgPattern.name ? 'Huidige' : 'Kies'}</Button>
              </Card.ImgOverlay>
            </Card>
          )
        })}
        </CardColumns>
    </Container>
  )
};

export default SidebarBackgroundSelector;