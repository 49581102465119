import { ListGroup, Table, Button, ButtonGroup } from 'react-bootstrap'
import { FaFileDownload, FaPrint } from 'react-icons/fa'

import InspectButton from "../../../Helpers/InspectButton"
import TruncateTooltip from "../../../Helpers/TruncateTooltip"
import apiService from "../../../Services/SamenstellingService"
import DefaultTooltip from '../../../Helpers/DefaultTooltip'

const SamenstellingBesteleenheidAantalListCard = ({ orderId, artikelen, pakketAantal, truncate = true }) => {
  const listGroupClasses = 'd-flex justify-content-between flex-wrap flex-md-nowrap align-items-center'
  return (
    <ListGroup variant="flush">
      {(
        <ListGroup.Item className={listGroupClasses}>
          <span>&nbsp;</span>
          <ButtonGroup>
            <Button
              size="sm"
              onClick={() => apiService.downloadPDF(orderId, `Samenstelling-Verkooporder-${orderId}.pdf`)}
            ><FaFileDownload /></Button>
            <Button
              size="sm"
              onClick={() => apiService.printPDF(orderId)}
            ><FaPrint /></Button>
          </ButtonGroup>
        </ListGroup.Item>
      )}
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <Table responsive style={{ display: "table" }} >
          <thead>
            <tr>
              <th>Artikelnr</th>
              <th>Omschrijving</th>
              <th>Aantal</th>
              <th>Besteleenheid</th>
              <th>Totaal</th>
            </tr>
          </thead>
          <tbody>
            {artikelen && artikelen.map((artikel, index) => {
              const dividend = pakketAantal * artikel.pivot.aantal;
              const divisor = artikel.inhoud;

              const quotient = Math.floor(dividend / divisor);
              const remainder = dividend % divisor;

              let compositionTooltipDescription = `${pakketAantal} pakketten * ${artikel.pivot.aantal} st = ${dividend} st. ` +
                `${dividend} / ${artikel.inhoud} st per ${artikel.besteleenheid} = ${quotient} ${artikel.besteleenheid}`;

              if (remainder > 0) {
                compositionTooltipDescription += `+ ${remainder} st`;
              }

              return (
                <tr
                  key={index}
                  className={artikel.pivot.aantal > 0 ? '' : 'table-secondary strikeout text-muted'}
                >
                  <td className="align-middle">
                    <InspectButton
                      to={`/artikelen/show/${artikel.artikelnr}`}
                      variant={'outline-primary'}
                    >#{artikel.artikelnr}</InspectButton>
                  </td>
                  <td className="align-middle">
                    <TruncateTooltip
                      text={artikel.omschrijving}
                      threshold={truncate ? 25 : -1}
                    />
                  </td>
                  <td className="align-middle">{artikel.pivot.aantal}</td>
                  <td className="align-middle">{artikel.besteleenheid} à {artikel.inhoud}</td>
                  <td className="align-middle">
                    <DefaultTooltip text={compositionTooltipDescription}>
                      {quotient} {artikel.besteleenheid}
                      {
                        remainder > 0 ? (
                          <>
                            <br />
                            {` + ${remainder} stuk(s)`}
                          </>
                        ) : ''
                      }
                    </DefaultTooltip>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ListGroup.Item>
    </ListGroup>
  )
}

export default SamenstellingBesteleenheidAantalListCard