import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Accordion,
  Card,
  ToggleButton,
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import format from 'date-fns/format';
import {
  FaExclamationTriangle,
  FaTimes,
  FaRegSquare,
  FaCheck,
  FaSave
} from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/VerkooporderService';
import KlantApiService from '../../Services/KlantService';
import DropdownActionsVerkooporder from './DropdownActionsVerkooporder';
import BezorgadresFormPart from './BezorgadresFormPart';
import ToegewezenPakketten from './ToegewezenPakketten';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import {
  VerkooporderDetailsCard,
  SamenstellingCard,
  PrijsBerekeningCard,
  SamenstellingBesteleenheidAantalListCard
} from './Cards/cards';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import { formatCurrency } from '../../Helpers/Helpers';

const EditVerkooporder = () => {
  const initialVerkooporder = {
    id: null,
    ordernummer: '',
    bezorgdatum: null,
    mededeling: '',
    verkoopprijs_totaal: '',
    verkoopprijs_totaal_incl: '',
    verkoopprijs_subtotaal_btw_hoog: '',
    verkoopprijs_subtotaal_btw_laag: '',
    verkoopprijs_subtotaal_btw_nul: '',
    fixed_verkoopprijs: '0,00',
    klantnummer: '',
    pakketnaam: '',
    artikelen_aantal: '',
    pakket_aantal: '',
    offerte_id: -1,
    pakket_id: -1,
    offerte: null,
    klant: {
      id: null,
      bezorgadressen: []
    },
    artikelen: [],
    status: '',
    opVoorraad: false,
    vrachtbrieven: [],
    inpakopdrachten: [],
    verdeling: [],
    bestaald: false,
  };

  const initialOriginalVerkoopprijs = {
    fixedVerkoopprijs: '0,00',
    totaalExcl: '0,00',
    totaalIncl: '0,00',
    totaalBtwHoog: '0,00',
    totaalBtwLaag: '0,00',
    totaalBtwNul: '0,00',
  };

  const [originalVerkoopprijs, setOriginalVerkoopprijs] = useState(initialOriginalVerkoopprijs);

  const [bezorgdatum, setBezorgdatum] = useState(null);
  const [verkooporder, setVerkooporder] = useState(initialVerkooporder);
  const [statussen, setStatussen] = useState(null);
  const [vrachtbrieven, setVrachtbrieven] = useState([]);
  const [inpakopdrachten, setInpakopdrachten] = useState([]);
  const [fixedVerkoopprijs, setFixedVerkoopprijs] = useState('0,00');

  const { register, handleSubmit, control } = useForm();

  const {
    notifications,
    addErrorNotification,
    addGenericErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  let { ordernummer } = useParams();
  let history = useHistory();

  useEffect(() => {
    retrieveFromApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveFromApi = () => {
    if (ordernummer) {
      ApiService.get(ordernummer)
        .then(response => {
          setVerkooporder(response.data);
          setVrachtbrieven(response.data.vrachtbrieven);
          setInpakopdrachten(response.data.inpakopdrachten);
          handleFixedVerkoopprijs(response.data.fixed_verkoopprijs);
          if (response.data.bezorgdatum) {
            setBezorgdatum(new Date(response.data.bezorgdatum));
          }
          ApiService.getStatussenFromOrder(ordernummer)
            .then(response => {
              setStatussen(response.data);
            })
            .catch(e => {
              addErrorNotification(`${e.response.statusText}`, e.response.status);
            });
          getOriginalVerkoopprijs(response.data.id);
        })
        .catch(e => {
          addErrorNotification(`${e.response.statusText}`, e.response.status);
        });
    }
  };

  const getOriginalVerkoopprijs = (verkooporderId) => {
    ApiService.getOriginalVerkoopprijs(verkooporderId)
      .then(response => {
        setOriginalVerkoopprijs(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const handleCheckedChange = event => {
    const { name, checked } = event.target;
    setVerkooporder({ ...verkooporder, [name]: checked});
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setVerkooporder({ ...verkooporder, [name]: value });
  };

  const refreshList = () => {
    retrieveFromApi();
  };

  const handleFixedVerkoopprijs = verkoopprijs => {
    if (verkoopprijs) {
      const formattedFixedVerkoopprijs = formatCurrency(parseFloat(verkoopprijs), false);
      setFixedVerkoopprijs(formattedFixedVerkoopprijs);
    } else {
      setFixedVerkoopprijs('0,00');
    }
  };

  const onSubmit = fieldData => {
    const pallets = [];
    if (fieldData.pallets) {
      Object.entries(fieldData.pallets).forEach(entry => {
        const [id, aantal] = entry;
        if (aantal > 0) {
          pallets.push({
            'bezorgadres_id': id,
            'aantal': parseInt(aantal),
          });
        }
      });
    }

    const pakketVerdeling = [];
    if (fieldData.bezorgadressen) {
      Object.entries(fieldData.bezorgadressen).forEach(entry => {
        const [id, aantal] = entry;
        const pallet = pallets.find(plt => plt.bezorgadres_id === id);

        pakketVerdeling.push({
          'bezorgadres_id': id,
          'aantal': parseInt(aantal),
          'pallet_aantal': pallet ? pallet.aantal : 0
        });
      });
    }

    const data = {
      ...verkooporder,
      bezorgdatum: bezorgdatum ? format(bezorgdatum, 'yyyy-MM-dd') : null,
      pakket_verdeling: pakketVerdeling,
      fixed_verkoopprijs: fixedVerkoopprijs.replace(',', '.')
    };

    ApiService.update(verkooporder.id, data)
      .then(response => {
        setVerkooporder(response.data);
        setVrachtbrieven(response.data.vrachtbrieven);
        setInpakopdrachten(response.data.inpakopdrachten);
        handleFixedVerkoopprijs(response.data.fixed_verkoopprijs);
        if (response.data.bezorgdatum) {
          setBezorgdatum(new Date(response.data.bezorgdatum));
        }
        addSuccessNotification("De verkooporder is succesvol aangepast.");
        ApiService.getStatussenFromOrder(ordernummer)
          .then(response => {
            setStatussen(response.data);
          })
          .catch(e => {
            addErrorNotification(`${e.response.statusText}`, e.response.status);
          });
        getOriginalVerkoopprijs(verkooporder.id);
      })
      .catch(e => {
        addErrorNotification(`${e.response.statusText}`, e.response.status);
      });

  };

  const handleAddBezorgadres = (data) => {
    if (verkooporder && verkooporder.klant && verkooporder.klant.id) {
      // if all fields are empty dont create.
      KlantApiService.addBezorgadresWithCb(verkooporder.klant.id, data, (response) => {
        refreshList();
        addSuccessNotification("Bezorgadres is met succes toegevoegd.");
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BodyTitleBar title={`Verkooporder #${ordernummer} aanpassen`}>
          <div>
          <ButtonGroup>
            {verkooporder.status > 0 && verkooporder.status <= 18 && (
              <Button
                type="submit"
                variant="success"
              ><FaSave /> Opslaan</Button>
            )}
            <DropdownActionsVerkooporder
              inpakopdrachten={inpakopdrachten}
              verkooporder={verkooporder}
              vrachtbrieven={vrachtbrieven}
              refresh={() => refreshList()}
            />
          </ButtonGroup>
          {" "}
            <Button
              disabled={verkooporder.status >= 10}
              onClick={() => { history.push(`/verkooporders/mutate/${verkooporder.id}`)}}
              variant="danger"
            >Muteer samenstelling <FaExclamationTriangle /></Button>
          </div>
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          removeNotifications={removeNotifications}
        />
        <Container fluid>
          <Row>
            <Col sm={12} xs={12} lg={7}>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={4} md={3} lg={2}>Bezorgdatum</Form.Label>
                <Col sm={'auto'}>
                  <InputGroup>
                    <DatePicker
                      className='form-control'
                      required={false}
                      disabled={verkooporder.status >= 16}
                      showWeekNumbers={true}
                      selected={bezorgdatum}
                      onChange={date => setBezorgdatum(date)}
                      dateFormat={`dd-MM-yyyy`}
                      placeholderText="Onbekend"
                    />
                    <InputGroup.Append>
                      <Button
                        variant='danger'
                        hidden={verkooporder.status >= 16 }
                        onClick={() => setBezorgdatum(null)}
                      ><FaTimes /></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={4} md={3} lg={2}>Betaald</Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <ButtonGroup toggle className="mb-2">
                    <ToggleButton
                      type="checkbox"
                      variant={verkooporder.betaald ? 'success' : 'danger'}
                      checked={verkooporder.betaald}
                      value="1"
                      name="betaald"
                      id="betaald"
                      onChange={(e) => handleCheckedChange(e)}
                    >{verkooporder.betaald ? <FaCheck /> : <FaRegSquare />}</ToggleButton>
                  </ButtonGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={4} md={3} lg={2}>Mededeling</Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control
                    type="text"
                    defaultValue={verkooporder.mededeling}
                    onChange={handleInputChange}
                    name="mededeling"
                    id="mededeling"
                    readOnly={verkooporder.status >= 6}
                    ref={register()}
                  />
                </Col>
              </Form.Group>
              {statussen && (
              <Form.Group as={Form.Row}>
                <Form.Label column sm={4} md={3} lg={2}>Status</Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control as="select"
                    custom
                    disabled={verkooporder.status === 2 || verkooporder.status === 18}
                    className="custom-select"
                    id="status"
                    onChange={handleInputChange}
                    value={verkooporder.status}
                    name="status"
                    ref={register()}
                  >
                    {statussen.map((status) => {
                      return (
                        <option
                          key={status.id}
                          value={status.id}>{status.code}: {status.omschrijving}
                        </option>
                      )
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              )}
              <Form.Group as={Form.Row}>
                <Form.Label column sm={2}>Verkoopprijs</Form.Label>
                <Col sm={10}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>&euro;</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      id="fixed_verkoopprijs"
                      value={fixedVerkoopprijs}
                      placeholder={'0,00'}
                      onChange={e => setFixedVerkoopprijs(e.target.value)}
                      name="fixed_verkoopprijs"
                      ref={register()}
                      autoComplete="off"
                      readOnly={verkooporder.status >= 11}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={4} md={3} lg={2}>
                  Aantal pakketten
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control
                    type="text"
                    value={verkooporder.pakket_aantal}
                    onChange={handleInputChange}
                    name="pakket_aantal"
                    id="pakket_aantal"
                    required
                    ref={register()}
                    readOnly={verkooporder.status >= 6}
                    plaintext={verkooporder.status >= 6}
                    isInvalid={verkooporder.pakket_aantal <= 0}
                  />
                  <Form.Control.Feedback type="invalid">
                    Minimaal 1 pakket moet worden ingevuld.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row>
                <Col>
                  <ToegewezenPakketten
                    className="mt-2"
                    control={control}
                    total={verkooporder.pakket_aantal}
                    totalAdressen={
                      verkooporder
                        && verkooporder.klant.bezorgadressen
                        ? verkooporder.klant.bezorgadressen.length
                        : 0
                    }
                  />
                </Col>
              </Row>
              <hr />
              {verkooporder && verkooporder.klant.bezorgadressen &&  (
                <Row>
                  <Col>
                    <BezorgadresFormPart
                      status={verkooporder.status}
                      control={control}
                      bezorgadressen={verkooporder.verdeling}
                      register={register}
                      onAddBezorgadres={handleAddBezorgadres}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col>
              {verkooporder && (
                <Accordion defaultActiveKey="1">
                  <Card className="border-dark">
                    <Card.Header
                      className="bg-dark text-light d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
                    >
                      <span><FaExclamationTriangle /> Informatie</span>
                    </Card.Header>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="1">
                      <span>Verkooporder details</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <VerkooporderDetailsCard
                        verkooporder={verkooporder}
                        originalVerkoopprijs={originalVerkoopprijs}
                        addErrorNotification={addErrorNotification}
                        addSuccessNotification={addSuccessNotification}
                        refresh={refreshList}
                      />
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="2">
                      <span>Prijs berekening</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <PrijsBerekeningCard
                        verkooporder={verkooporder}
                        originalVerkoopprijs={originalVerkoopprijs}
                      />
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="3">
                      <span>
                        Pakket samenstelling <span className="text-muted">(Artikelen + prijs)</span>
                      </span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <SamenstellingCard
                        artikelen={verkooporder.artikelen}
                        verkooporder={verkooporder}
                        originalVerkoopprijs={originalVerkoopprijs}
                      />
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="4">
                      <span>
                        Pakket samenstelling <span className="text-muted">(Artikelen + besteleenheid & inhoud)</span>
                      </span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <SamenstellingBesteleenheidAantalListCard
                        artikelen={verkooporder.artikelen}
                        pakketAantal={verkooporder.pakket_aantal}
                        orderId={verkooporder.id}
                      />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )}
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
};

export default EditVerkooporder;