import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import MutateSamenstelling from '../MutateSamenstelling/MutateSamenstelling';

const MutateVerkooporder = () => {
  let { id } = useParams();

  return (
    <MutateSamenstelling
      id={id}
      type={'verkooporder'}
    />
  );
};

export default MutateVerkooporder;