import React from 'react';
import { Row, Col, ListGroup, Badge } from 'react-bootstrap';
import DefaultTooltip from '../../Helpers/DefaultTooltip';

const getVariant = aantal => {
  if (aantal === 0) {
    return 'primary';
  }

  if (aantal > 0) {
    return 'success';
  }

  if (aantal < 0) {
    return 'danger';
  }
};

const ArtikelMutations = ({ inkoopMutations, nodigMutations, voorraadMutations, breukMutations }) => {
  const mutations = [
    {
      label: 'Nodig',
      positive: nodigMutations.filter(x => x.aantal > 0).reduce((sum, x) => sum + x.aantal, 0),
      negative: nodigMutations.filter(x => x.aantal < 0).reduce((sum, x) => sum + x.aantal, 0),
      mutations: nodigMutations
    },
    {
      label: 'Besteld',
      positive: inkoopMutations.filter(x => x.aantal > 0).reduce((sum, x) => sum + x.aantal, 0),
      negative: inkoopMutations.filter(x => x.aantal < 0).reduce((sum, x) => sum + x.aantal, 0),
      mutations: inkoopMutations
    },
    {
      label: 'Voorraad',
      positive: voorraadMutations.filter(x => x.aantal > 0).reduce((sum, x) => sum + x.aantal, 0),
      negative: voorraadMutations.filter(x => x.aantal < 0).reduce((sum, x) => sum + x.aantal, 0),
      mutations: voorraadMutations
    },
    {
      label: 'Breuk',
      positive: breukMutations.filter(x => x.aantal > 0).reduce((sum, x) => sum + x.aantal, 0),
      negative: breukMutations.filter(x => x.aantal < 0).reduce((sum, x) => sum + x.aantal, 0),
      mutations: breukMutations
    }
  ];

  return (
    <Row>
      {mutations.map((mutation, i) => {
        return (
          <Col key={i}>
            <Row>
              <Col><h5>{mutation.label}</h5></Col>
            </Row>
            <ListGroup>
              <ListGroup.Item
                key={`${mutation.label}-${i}`}
              >
                Totaal:{' '}
                <strong><Badge variant="success">+{mutation.positive}</Badge></strong>
                {' / '}
                <strong><Badge variant="danger">{mutation.negative}</Badge></strong>
              </ListGroup.Item>
              {mutation.mutations && mutation.mutations.filter(x => x.aantal !== 0).reverse().map(mut => {
                const date = new Date(mut.created_at)
                return (
                  <DefaultTooltip
                    key={`${mutation.label}-${i}-${mut.id}`}
                    text={
                      (<div>
                        {mut.beschrijving ? `Beschrijving: ${mut.beschrijving}` : 'Geen beschrijving'}
                        <hr />
                        {`Datum: ${date.toLocaleDateString()}`}
                        <br />
                        {`Tijd: ${date.toLocaleTimeString()}`}
                       </div>)
                    }
                  >
                    <ListGroup.Item
                      variant={getVariant(mut.aantal)}
                    >
                      {mut.aantal}
                      {mut.voorraadbaar_id && mut.locatie && ` @ ${mut.locatie}`}
                    </ListGroup.Item>
                  </DefaultTooltip>
                )
              })}
            </ListGroup>
          </Col>
        )
      })}
    </Row>
  );
};

export default ArtikelMutations;