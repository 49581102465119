import React from 'react';
import { Button, FormControl, InputGroup, Form} from 'react-bootstrap';
import { FaTimes, FaSearch, FaSyncAlt } from 'react-icons/fa';

const SearchComponent = ({
  searchKeys,
  search,
  onChangeSearch,
  searchOperator,
  onChangeOperator,
  operators = [],
  onSubmit, onReset
}) => {
  return (
    <>
    <Form onSubmit={(e) => {
      e.preventDefault();
      onSubmit();
    }}>
      <InputGroup className="flex-wrap">
        <FormControl
          type="text"
          placeholder="Zoek..."
          autoComplete="off"
          value={search.query}
          id="query"
          name="query"
          onChange={onChangeSearch}
        />
        <FormControl as="select"
          custom
          className="custom-select"
          onChange={onChangeSearch}
          name="key"
          id="key"
          value={search.key}
        >
          {searchKeys && searchKeys.map((searchKey, i) => {
            return (
              <option key={i} value={searchKey.value}>{searchKey.label}</option>
            )
          })}
        </FormControl>
        {operators && operators.length > 0 && (
          <FormControl as="select"
            custom
            className="custom-select col-md-2"
            onChange={onChangeOperator}
            name="operator"
            id="operator"
            value={searchOperator}
          >
            {operators && operators.map((operator, i) => {
              return (
                <option key={i} value={operator.key}>{operator.operator}</option>
              )
            })}
          </FormControl>
        )}

        <InputGroup.Append>
          <Button
            variant={'success'}
            onClick={() => {
              onSubmit();
            }}
          >{search.query === '' ? <FaSyncAlt /> : <FaSearch /> }</Button>
        </InputGroup.Append>
        <InputGroup.Append>
          <Button
            disabled={search.query === ''}
            variant={'danger'}
            onClick={onReset}
          ><FaTimes /></Button>
        </InputGroup.Append>
      </InputGroup>
      </Form>
    </>
  );
};

export default SearchComponent;