import React, { useState, useEffect } from 'react';
import {
  FaPhone,
  FaAt,
  FaTruckLoading,
  FaAddressCard,
  FaFileInvoice,
  FaExclamationTriangle,
  FaMobileAlt,
  FaSave
} from 'react-icons/fa';
import {
  Button,
  Accordion,
  Card,
  Alert,
  Container,
  Badge,
  InputGroup,
  Form,
  Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArchive } from '@fortawesome/free-solid-svg-icons';

import ApiService from '../../Services/KlantService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import BezorgadressenList from './BezorgadressenList';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import ArchivedOrdersList from './ArchivedOrdersList';


const ErrorMessage = () => {
  return (
    <>
      <Container>
        <Alert variant="danger">
          Oeps, er lijkt iets fout te zijn gegaan.
        </Alert>
      </Container>
    </>
  )
};

const EditKlant = (props) => {
  const initialKlantState = {
    id: null,
    nummer: '',
    bedrijfsnaam: '',
    factuur_adres: '',
    factuur_postcode: '',
    factuur_plaatsnaam: '',
    factuur_land: 'NL',
    contactpersoon: '',
    telefoonnummer: '',
    emailadres: '',
    factuur_emailadres: '',
    mobielnummer: '',
    bezorgadressen: [],
    archived_orders: [],
  };

  const [currentKlant, setCurrentKlant] = useState(initialKlantState);
  const [bezorgadressen, setBezorgadressen] = useState([]);
  const { notifications, removeNotifications, addErrorNotification, addSuccessNotification } = useAlertNotifications();

  const getKlant = id => {
    ApiService.get(id)
    .then(response => {
      const klant = response.data;
      setCurrentKlant({
        id: klant.id,
        nummer: klant.nummer,
        bedrijfsnaam: klant.bedrijfsnaam ?? '',
        factuur_adres: klant.factuur_adres ?? '',
        factuur_postcode: klant.factuur_postcode ?? '',
        factuur_plaatsnaam: klant.factuur_plaatsnaam ?? '',
        factuur_land: klant.factuur_land ?? 'NL',
        contactpersoon: klant.contactpersoon ?? '',
        telefoonnummer: klant.telefoonnummer ?? '',
        emailadres: klant.emailadres ?? '',
        factuur_emailadres: klant.factuur_emailadres ?? '',
        mobielnummer: klant.mobielnummer ?? '',
        bezorgadressen: klant.bezorgadressen ?? [],
        archived_orders: klant.archivedorders ?? [],
      });
      setBezorgadressen(klant.bezorgadressen);
      if (klant.bezorgadressen.length === 0) {
        addErrorNotification('Deze klant heeft nog geen bezorgadres(sen)!');
      }
    })
    .catch(e => {
      addErrorNotification(`Er ging wat mis bij het ophalen van de klant gegevens.`, e.response.status);
    });
  };

  useEffect(() => {
    getKlant(props.match.params.nummer);
  }, [props.match.params.nummer]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentKlant({ ...currentKlant, [name]: value});
  };

  const updateKlant = () => {
    ApiService.update(currentKlant.id, currentKlant)
      .then(response => {
        setCurrentKlant(response.data);
        setBezorgadressen(response.data.bezorgadressen);
        addSuccessNotification('De klant is aangepast');
      })
      .catch(e => {
        addErrorNotification('Er is iets fout gegaan bij het aanpassen van de klant.', e.response.status);
      });
  };

  const handleAddBezorgadres = data => {
    ApiService.addBezorgadresWithCb(currentKlant.id, data, response => {
      setCurrentKlant(response.data);
      setBezorgadressen(response.data.bezorgadressen);
    });
  };

  const handleRemoveBezorgadres = data => {
    ApiService.removeBezorgadres(data.id)
      .then(response => {
        addSuccessNotification('Bezorgadres verwijderd');
        setBezorgadressen(prevState => {
          return [...prevState.filter(ba => ba.id !== data.id)];
        });
      })
      .catch(e => {
        addErrorNotification(e.response.statusText, e.response.status);
      });
  }

  const handleEditBezorgadres = (bezorgadresId, data) => {
    const errorMessage = 'Er is iets fout gegaan bij het opslaan van een bezorgadres.';
    ApiService.editBezorgadres(
      bezorgadresId,
      data,
      response => {
        setBezorgadressen(prevState => {
          const index = prevState.findIndex(x => x.id === response.data.id);
          if (index === -1) {
            addErrorNotification(errorMessage);
            return [...prevState];
          }

          addSuccessNotification('Bezorgadres is aangepast.');
          return [
            ...prevState.slice(0, index),
            response.data,
            ...prevState.slice(index+1)
          ]
        });
      }, error => {
        addErrorNotification(errorMessage);
      }
    );
  }

  return (
    <>
      {currentKlant ? (<>
        <Container fluid className="edit-form">
          <BodyTitleBar title={`Klant aanpassen`}>
            {currentKlant.id && (
              <Button
                onClick={updateKlant}
              ><FaSave /> Opslaan</Button>
            )}
          </BodyTitleBar>
          <AlertNotifications
            notifications={notifications}
            removeNotifications={removeNotifications}
          />
          {currentKlant.id && (
          <div>
            <div className="form-group form-row">
              <div className="col">
                <label htmlFor="nummer">Klantnummer</label>
                <input
                  type="text"
                  className="form-control"
                  id="nummer"
                  required
                  defaultValue={currentKlant.nummer}
                  onChange={handleInputChange}
                  name="nummer"
                />
              </div>
              <div className="col-md-10">
                <label htmlFor="bedrijfsnaam">Bedrijfsnaam</label>
                <input
                  type="text"
                  className="form-control"
                  id="bedrijfsnaam"
                  required
                  defaultValue={currentKlant.bedrijfsnaam}
                  onChange={handleInputChange}
                  name="bedrijfsnaam"
                />
              </div>
            </div>

            <Accordion defaultActiveKey="0">
              <Card>
                <ContextAwareToggle eventKey="0">
                  <span>
                    <FaFileInvoice /> {" "}
                    Factuurgegevens
                  </span>
                </ContextAwareToggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                <div className="form-group form-row">
                  <div className="col">
                    <label htmlFor="factuur_adres">Adres + nummer</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="factuur_adres"
                        required
                        defaultValue={currentKlant.factuur_adres}
                        onChange={handleInputChange}
                        name="factuur_adres"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group form-row">
                  <div className="col-md-2">
                    <label htmlFor="factuur_postcode">Postcode</label>
                    <input
                      type="text"
                      className="form-control"
                      id="factuur_postcode"
                      required
                      defaultValue={currentKlant.factuur_postcode}
                      onChange={handleInputChange}
                      name="factuur_postcode"
                    />
                  </div>
                  <div className="col-md-8">
                    <label htmlFor="factuur_plaatsnaam">Plaatsnaam</label>
                    <input
                      type="text"
                      className="form-control"
                      id="factuur_plaatsnaam"
                      required
                      defaultValue={currentKlant.factuur_plaatsnaam}
                      onChange={handleInputChange}
                      name="factuur_plaatsnaam"
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="factuur_land">Land</label>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="factuur_land"
                        onChange={handleInputChange}
                        defaultValue={currentKlant.factuur_land}
                        name="factuur_land"
                      >
                        <option value="NL">Nederland</option>
                        <option value="BE">België</option>
                        <option value="DE">Duitsland</option>
                      </select>
                    </div>
                  </div>
                </div>
                      <Form.Group as={Form.Row}>
                        <Col sm={12} md={12}>
                          <Form.Label>
                            E-mailadres {" "}
                            <span className="text-muted small">(factuur)</span>
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text><FaAt /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              id="factuur_emailadres"
                              name="factuur_emailadres"
                              placeholder="factuur@bedrijfsnaam.nl"
                              value={currentKlant.factuur_emailadres}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                </Card.Body>
              </Accordion.Collapse>
              </Card>
              <Card>
                <ContextAwareToggle eventKey="1"><span><FaAddressCard /> Contactgegevens</span></ContextAwareToggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <Form.Group as={Form.Row}>
                        <Col sm={12} md={12}>
                          <Form.Label>Contactpersoon</Form.Label>
                          <Form.Control
                            type="text"
                            id="contactpersoon"
                            name="contactpersoon"
                            placeholder='John Smit'
                            value={currentKlant.contactpersoon}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Form.Row}>
                        <Col sm={12} md={12}>
                          <Form.Label>
                            Telefoonnummer {" "}
                            <span className="text-muted small">(vast)</span>
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text><FaPhone /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              id="telefoonnummer"
                              name="telefoonnummer"
                              placeholder='0123-456789'
                              value={currentKlant.telefoonnummer}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Form.Row}>
                        <Col sm={12} md={12}>
                          <Form.Label>
                            Telefoonnummer {" "}
                            <span className="text-muted small">(mobiel)</span>
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text><FaMobileAlt /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              id="mobielnummer"
                              name="mobielnummer"
                              placeholder='06-12345678'
                              value={currentKlant.mobielnummer}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Form.Row}>
                        <Col sm={12} md={12}>
                          <Form.Label>
                            E-mailadres {" "}
                            <span className="text-muted small">(contactpersoon)</span>
                          </Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text><FaAt /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              id="emailadres"
                              name="emailadres"
                              placeholder="contactpersoon@bedrijfsnaam.nl"
                              value={currentKlant.emailadres}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <ContextAwareToggle eventKey="2">
                  <span>
                    <FaTruckLoading /> Bezorgadressen
                    {bezorgadressen && bezorgadressen.length <= 0 ? (
                      <>{" "}
                        <Badge variant="danger">
                          <FaExclamationTriangle />
                        </Badge>
                      </>
                    ) : (<></>)}
                  </span>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <BezorgadressenList
                      bezorgadressen={bezorgadressen}
                      onAdd={bezorgadres => handleAddBezorgadres(bezorgadres)}
                      onRemove={bezorgadres => handleRemoveBezorgadres(bezorgadres)}
                      onEdit={handleEditBezorgadres}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <ContextAwareToggle eventKey="3">
                  <span>
                    <FontAwesomeIcon icon={faArchive}/> Archief
                    {currentKlant.archived_orders && currentKlant.archived_orders.length <= 0 ? (
                      <>{" "}
                        <Badge variant="danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Badge>
                      </>
                    ) : (<></>)}
                  </span>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <ArchivedOrdersList orders={currentKlant.archived_orders} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          )}
        </Container>
      </>) : (
        <>
          <AlertNotifications notifications={notifications.notifications} />
          <ErrorMessage />
        </>
      )}
    </>
  );
};

export default EditKlant;