const date = new Date();


const searchKeys = [
  {
    value: 'ordernummer',
    label: 'Ordernummer'
  },
  {
    value: 'bezorgdatum',
    label: `Bezorgdatum (bijv. ${date.getFullYear()}-01-31)`
  },
  {
    value: 'current_status',
    label: 'Status (2 t/m 9 & A t/m I)'
  },
  {
    value: 'mededeling',
    label: 'Mededeling'
  },
  {
    value: 'pakket_naam',
    label: 'Pakket naam'
  },
  {
    value: 'verkoopprijs_totaal',
    label: 'Totaal prijs excl.'
  },
  {
    value: 'verkoopprijs_totaal_incl',
    label: 'Totaal prijs incl.'
  },
  {
    value: 'verkoopprijs_totaal_btw_hoog',
    label: 'Prijs BTW Hoog'
  },
  {
    value: 'verkoopprijs_totaal_btw_laag',
    label: 'Prijs BTW Laag'
  },
  {
    value: 'verkoopprijs_totaal_btw_nul',
    label: 'Prijs BTW nul'
  },
  {
    value: 'betaald',
    label: 'Betaald (0 = nee, 1 = ja)'
  },
  {
    value: 'pakket_aantal',
    label: 'Aantal Pakketten'
  },
  {
    value: 'artikelen_aantal',
    label: 'Aantal artikelen'
  },
  {
    value: 'klantnummer',
    label: 'Klantnummer'
  },
  {
    value: 'klant.bedrijfsnaam',
    label: 'Klantnaam'
  },
  {
    value: 'artikel.nr',
    label: 'Artikel(nummer)'
  },
  {
    value: 'artikel.omschrijving',
    label: 'Artikel(omschrijving)'
  },
  {
    value: 'artikel.leverancier.artikel_nummer',
    label: 'Artikel(leverancier artikelnr)'
  }
];

const searchKeysOutput = {
  default: 'ordernummer',
  searchKeys
};

export default searchKeysOutput;