import React from 'react';
import api from '../../Services/LeverancierService';
import { useHistory } from 'react-router-dom';
import {
  FaEdit,
  FaPlus
} from 'react-icons/fa';
import {
  Button,
  Container
} from 'react-bootstrap';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';
import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/tableData';
const LeverancierList = () => {
  let history = useHistory();

  const customColumns = [
    ...tableColumns,
    {
      Header: 'Aantal artikelen',
      accessor: 'aantal_artikelen',
      disableSortBy: true,
      id: 'aantal_artikelen'
    },
    {
      Header: 'Acties',
      accessor: (row, i) => {
        return (
          <>
            <Button
              variant="danger"
              onClick={() => history.push(`/leveranciers/edit/${row.nummer}`)}
            ><FaEdit /></Button>
          </>
        )
      },
      disableSortBy: true,
      id: 'actions'
    }
  ];

  return (
    <>
      <Container fluid>
        <BodyTitleBar title="Leveranciers" btnTo="/leveranciers/add" btnTitle={<><FaPlus /> Leverancier toevoegen</>}/>
        <PaginatedTable
          api={api}
          tableColumns={customColumns}
          hiddenColumns={hiddenColumns}
          searchKeys={searchKeys}
          apiSortKeys={apiSortKeys}
          localStorageId={'suppliers_list'}
        />
      </Container>
    </>
  );
};

export default LeverancierList;