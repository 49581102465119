import React from 'react';
import {
  Card,
  ListGroup,
  Accordion
} from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { truncate, hellip } from '../../Helpers/Helpers';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import InspectButton from '../../Helpers/InspectButton';
import TruncateTooltip from '../../Helpers/TruncateTooltip';

const OrderNoVoorraadInfo = ({ verkooporder, deselect }) => {
  if (!verkooporder) {
    return (
      <Card bg="info" text="white">
        <Card.Header>
          <strong>Verkooporder Informatie</strong>
        </Card.Header>
        <Card.Body>
          Voor meer informatie selecteer een verkooporder.
        </Card.Body>
      </Card>
    );
  }
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="d-flex justify-content-between">
            <strong>Informatie</strong>
            <span onClick={() => deselect()}><FaTimes/></span>
        </Card.Header>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <span>{verkooporder.pakket_naam}</span>
          </Card.Title>
          <div>
            <label>Klant:</label> {" "}
            <em>
              <InspectButton
                variant="link"
                to={`/klanten/edit/${verkooporder.klantnummer}`}
              >{verkooporder.klant.bedrijfsnaam}</InspectButton>
            </em>
          </div>
          <div>
            <label>Ordernummer:</label> {" "}
            <em>
              <InspectButton
                variant="link"
                to={`/verkooporders/edit/${verkooporder.ordernummer}`}
              >{verkooporder.ordernummer}</InspectButton>
            </em>
          </div>
          <div>
            <label>Status:</label> <strong>{verkooporder.status}</strong> (<em>{verkooporder.status_info.omschrijving}</em>)
          </div>
        </Card.Body>
      </Card>
      <Card>
        <ContextAwareToggle eventKey="0">
          <span>
            Artikelen
          </span>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="0">
          <ArtikelenTableCardList artikelen={verkooporder.order_artikelen} />
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const ArtikelenTableCardList = ({ artikelen }) => {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        // className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
        style={{ padding: 0 }}
      >
        <table className="table table-condensed table-responsive" style={{ display: "table"}} >
          <thead>
            <tr>
              <th>#</th>
              <th>Omschrijving</th>
              <th>Aantal</th>
              <th>Voorraad</th>
            </tr>
          </thead>
          <tbody>
            {artikelen && artikelen.map((artikel, index) => {
              const aantal = artikel.voorraad_info.aantal
              const nodig = artikel.voorraad_info.nodig
              const voorraad = artikel.voorraad_info.voorraad
              const opVoorraad = artikel.voorraad_info.op_voorraad
              const hasPartialVoorraad = voorraad > 0 && !opVoorraad
              return (
                <tr
                  key={index}
                  className={
                    opVoorraad
                      ? 'table-success'
                      : hasPartialVoorraad
                        ? 'table-warning'
                        : 'table-danger'
                  }
                >
                  <td>
                    <InspectButton
                      variant="link"
                      to={`/artikelen/show/${artikel.artikelnr}`}
                    >{artikel.artikelnr}</InspectButton>
                  </td>
                  <td>
                    <TruncateTooltip
                      text={artikel.omschrijving}
                      threshold={25}
                    />
                  </td>
                  <td align="center">{aantal}</td>
                  <td align="right">{voorraad} / {nodig}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default OrderNoVoorraadInfo;