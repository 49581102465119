import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/GeneralInfoService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


const GeneralInfoView = () => {
  const [generalInfo, setGeneralInfo] = useState({
    inkooporder_algemene_notitie: '',
    btw: {
      zero: '1.00',
      low: '1.00',
      high: '1.00',
    },
    kost_prijs: '1.00',
    winst_marge: '1.00',
  });
  const [errors, setErrors] = useState({});

  const {
    notifications,
    addGenericErrorNotification,
    removeNotifications
  } = useAlertNotifications();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.get()
      .then(response => {
        setGeneralInfo(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  }

  return (
    <Container fluid>
      <BodyTitleBar title='Algemene Informatie'>
        <Button variant='danger'><FontAwesomeIcon icon={faEdit}/> Opslaan</Button>
      </BodyTitleBar>

      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      <Alert variant="warning">
        <Alert.Heading><FontAwesomeIcon icon={faExclamationTriangle}/> PAS OP!</Alert.Heading>
        <p>
          Wijzigingen aan deze waarden hebben grote gevolgen voor het gehele systeem.
        </p>
      </Alert>
      <Form>
        <Form.Group as={Form.Row}>
          <Form.Label column sm={2}>Winst marge</Form.Label>
          <Col sm={3} md={3}>
            <InputGroup>
              <Form.Control
                type="text"
                className="form-control"
                required
                id="winst_marge"
                name="winst_marge"
                value={generalInfo.winst_marge}
                onChange={(e) => console.log(e)}
                autoComplete="off"
              />
              <InputGroup.Append>
                <InputGroup.Text>&#37;</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>


            <Form.Control.Feedback type="invalid">
              {errors.winst_marge}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Form>

    </Container>
  )
}

export default GeneralInfoView;