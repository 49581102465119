import ajax, { api } from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';
import { getPaginatedUrl } from '../Helpers/Helpers';

const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl('/verkooporders', page, perPage, search, sort, operator);
};

const getAll = (page = 1, perPage = 10, searchKey = '', query = '', sortKey = null, sortDir = 'asc') => {
  // const url = getPaginatedUrl('/verkooporders', page, perPage, { key: searchKeys, query }, { key: apiSortKeys, dir: sortDir });
  // return ajax.get(url);
  return ajax.get('/verkooporders');
};

const getStatistics = () => {
  return ajax.get(`${api}/statistics/verkooporders`);
};

const getAllFromPakket = (pakketId) => {
  return ajax.get(`/verkooporders/pakket/${pakketId}`);
};

const getAllFromOfferte = (offerteId) => {
  return ajax.get(`/verkooporders/offerte/${offerteId}`);
};

const getArchivedVerkooporderPDF = async (ordernummer) => {
  const response = await ajax.get(`${api}/verkooporders/archived/${ordernummer}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, ordernummer + '.pdf');
};

const get = ordernummer => {
  return ajax.get(`/verkooporders/${ordernummer}`);
};

const getById = id => {
  return ajax.get(`${api}/verkooporders/${id}`);
};

const create = data => {
  return ajax.post('/verkooporders', data);
};

const update = (id, data) => {
  return ajax.put(`/verkooporders/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/verkooporders/${id}`);
};

const removeAll = () => {
  return ajax.delete('/verkooporders');
};

const findBy = (key, value) => {
  return ajax.get(`/verkooporders/find/${key}/${value}`);
};

const getBase64PDF = (id) => {
  return ajax.get(`/verkooporders/pdf/base64/${id}`);
};

const downloadPDF = async (id, filename) => {
  const response = await ajax.get(`/verkooporders/pdf/download/${id}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id) => {
  try {
    const response = await getBase64PDF(id);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
};

const attachBezorgadres = (verkooporderId, data) => {
  return ajax.post(`/verkooporders/${verkooporderId}/bezorgadres/attach`, data);
};

const detachBezorgadres = (verkooporderId, bezorgadresId) => {
  return ajax.delete(`/verkooporders/${verkooporderId}/bezorgadres/detach/${bezorgadresId}`);
};

const getStatussen = () => {
  return ajax.get('/verkooporders/statussen');
};

const getStatussenFromOrder = (ordernummer) => {
  return ajax.get(`/verkooporders/statussen/${ordernummer}`);
};

const mutateArtikel = (id, data) => {
  return ajax.post(`${api}/verkooporders/${id}/mutate/artikel`, data);
};

const mutateArtikelRemove = (id, artikelId) => {
  return ajax.put(`${api}/verkooporders/${id}/mutate/artikel/${artikelId}`);
};

const getOriginalVerkoopprijs = (id) => {
  return ajax.get(`${api}/verkooporders/${id}/original-verkoopprijs`);
}

export default {
  getAllUrl,
  getAll,
  get,
  getById,
  getStatussen,
  getStatussenFromOrder,
  create,
  update,
  remove,
  removeAll,
  findBy,
  getBase64PDF,
  downloadPDF,
  printPDF,
  attachBezorgadres,
  detachBezorgadres,
  getStatistics,
  mutateArtikel,
  mutateArtikelRemove,
  getOriginalVerkoopprijs,
  getArchivedVerkooporderPDF
};