import React from 'react';
import {
  Button,
  Table,
  ButtonGroup
} from 'react-bootstrap';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';
import PaginatedTable from '../../../Helpers/PaginatedTable/PaginatedTable';

import api from '../../../Services/ArtikelService';
import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/tableData';

const PaginatedArtikelenTab = ({ onIncrement, onDecrement, onDetach, pakket }) => {

  const findArtikelInPakket = (pakket, artikelId) => {
    return pakket.artikelen.find(artikel => artikel.id === artikelId);
  };

  const customColumns = [
    ...tableColumns,
    {
      Header: 'Aantal',
      accessor: (row, i) => {
        const artikel = findArtikelInPakket(pakket, row.id);
        return artikel ? artikel.pivot.aantal : 0;
      },
      disableSortBy: true,
      id: 'aantal',
    },
    {
      Header: 'Handelingen',
      accessor: (row, i) => {
        const artikel = findArtikelInPakket(pakket, row.id);
        const huidigAantal = artikel ? artikel.pivot.aantal : 0;
        return (
          <>
            <ButtonGroup>
              <Button
                onClick={() => onIncrement(row.id, pakket.id)}
              ><FaPlus /></Button>
              <Button
                disabled={!artikel || huidigAantal === 0}
                onClick={() => onDecrement(row.id, pakket.id)}
              ><FaMinus /></Button>
            </ButtonGroup>
            {" "}
            <Button
              variant="danger"
              disabled={!artikel}
              onClick={() => onDetach(pakket.id, row.id)}
            ><FaTrash /></Button>
          </>
        )
      },
      disableSortBy: true,
      id: 'actions'
    }
  ];

  return (
    <PaginatedTable
      size="sm"
      api={api}
      tableColumns={customColumns}
      hiddenColumns={hiddenColumns}
      searchKeys={searchKeys}
      apiSortKeys={apiSortKeys}
      getRowProps={row => {
        const artikel = findArtikelInPakket(pakket, row.original.id);
        const huidigAantal = artikel ? artikel.pivot.aantal : 0;
        let variant = artikel
          ? huidigAantal > 0 ? 'table-primary' : 'table-secondary'
          : '';
        return ({
          className: variant
        });
      }}
    />
  );
};

export default PaginatedArtikelenTab;