import React, { useState } from 'react';
import {
  Dropdown,
  Button,
  Modal
} from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';

const ConfirmDeleteAllArtikelenFromPakketModal = ({ isDropdownItem = true, onConfirm, disabled = false }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  }

  return (
    <>
      {isDropdownItem
        ? (
          <Dropdown.Item
            disabled={disabled}
            onClick={handleShow}
          ><FaRegTrashAlt /> Leeg pakket</Dropdown.Item>
         )
        : (
          <Button
            disabled={disabled}
            variant="danger"
            onClick={handleShow}
          ><FaRegTrashAlt /> Leeg pakket</Button>
         )
      }
      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Opgelet!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>U staat op het punt alle artikelen uit een pakket te verwijderen.</p>
          <p>Weet u zeker dat u dit wilt doen?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Nee, annuleer
          </Button>
          <Button variant="danger" onClick={handleConfirm}>Ja, verwijder alles</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmDeleteAllArtikelenFromPakketModal;