import { useCallback, useState } from 'react';

const useTimeoutState = (defaultState, opts) => {
  const [state, _setState] = useState(defaultState);
  const [currentTimeoutId, setCurrentTimeoutId] = useState(null);

  const setState = useCallback(
    (newState, setStateOpts) => {
      clearTimeout(currentTimeoutId);

      newState !== state && _setState(newState);

      if (newState === defaultState) {
        return;
      }

      const id = setTimeout(
        () => _setState(defaultState),
        setStateOpts?.timeout || opts?.timeout
      );

      setCurrentTimeoutId(id);
    },
    [currentTimeoutId, state, opts, defaultState]
  );

  return [state, setState];
}

export default useTimeoutState;