import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  ListGroup,
  Accordion,
  InputGroup,
  ButtonGroup,
  Table
} from 'react-bootstrap';
import { useParams, useHistory , Link} from 'react-router-dom';
import DatePicker from "react-datepicker";
import format from 'date-fns/format';
import {
  FaPrint,
  FaTimes,
  FaHandshake,
  FaFileDownload,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaEdit,
  FaSearch
} from 'react-icons/fa';
import { useForm } from 'react-hook-form';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import ApiService from '../../Services/VerkooporderService';
import OfferteApiService from '../../Services/OfferteService';
import KlantApiService from '../../Services/KlantService';

import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import { truncate, hellip, formatCurrency } from '../../Helpers/Helpers';
import BezorgadresFormPart from './BezorgadresFormPart';
import ToegewezenPakketten from './ToegewezenPakketten';
import InspectButton from '../../Helpers/InspectButton';

const AddVerkooporder = () => {
  const [pakketAantal, setPakketAantal] = useState(0);
  const [mededeling, setMededeling] = useState('');
  const [fixedVerkoopprijs, setFixedVerkoopprijs] = useState('0,00');
  const [offerte, setOfferte] = useState(null);
  const [bezorgdatum, setBezorgdatum] = useState(new Date());

  const { register, handleSubmit, control } = useForm();
  const { notifications, addErrorNotification, addSuccessNotification, removeNotifications } = useAlertNotifications();

  let { offertenummer } = useParams();
  let history = useHistory();

  useEffect(() => {
    retrieveFromApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveFromApi = () => {
    if (offertenummer) {
      OfferteApiService.getByOffertenummer(offertenummer)
        .then(response => {
          setPakketAantal(response.data.pakket_aantal);
          setOfferte(response.data);
          handleFixedVerkoopprijs(response.data.fixed_verkoopprijs);
        })
        .catch(e => {
          addErrorNotification(e.response.statusText, e.response.status);
        });
    }
  };

  const handleFixedVerkoopprijs = verkoopprijs => {
    if (verkoopprijs) {
      const formattedFixedVerkoopprijs = formatCurrency(parseFloat(verkoopprijs), false);
      setFixedVerkoopprijs(formattedFixedVerkoopprijs);
    } else {
      setFixedVerkoopprijs('0,00');
    }
  };

  const onSubmit = fieldData => {
    if (offerte && offerte.artikelen_aantal <= 0) {
      addErrorNotification('Deze offerte bevat 0 artikelen. Bevestigen kan dus niet.');
      return;
    }
    const pallets = [];
    if (fieldData.pallets) {
      Object.entries(fieldData.pallets).forEach(entry => {
        const [id, aantal] = entry;
        if (aantal > 0) {
          pallets.push({
            'bezorgadres_id': id,
            'aantal': parseInt(aantal)
          });
        }
      });
    }

    const pakketVerdeling = [];
    if (fieldData.bezorgadressen) {
      Object.entries(fieldData.bezorgadressen).forEach(entry => {
        const [id, aantal] = entry;
        if (aantal > 0) {
          const pallet = pallets.find(plt => plt.bezorgadres_id === id);
          pakketVerdeling.push({
            'bezorgadres_id': id,
            'aantal': parseInt(aantal),
            'pallet_aantal': pallet ? pallet.aantal : 0
          });
        }
      });
    }
    const data = {
      offerteId: offerte.id,
      bezorgdatum: bezorgdatum ? format(bezorgdatum, 'yyyy-MM-dd') : null,
      mededeling: fieldData.mededeling ? fieldData.mededeling : null,
      pakket_aantal: fieldData.pakketAantal,
      pakket_verdeling: pakketVerdeling,
      fixed_verkoopprijs: fixedVerkoopprijs.replace(',', '.')
    };

    ApiService.create(data)
      .then(response => {
        const ordernummer = response.data.ordernummer;
        if (ordernummer) {
          history.push(`/verkooporders/edit/${ordernummer}`);
        } else {
          addErrorNotification('Er is iets mis gegaan bij het bevestigen van deze offerte.', 'Fout!');
        }
      })
      .catch(e => {
        addErrorNotification(e.response.statusText, e.response.status);
      });
  };

  const handleAddBezorgadres = (data) => {
    if (offerte && offerte.klant && offerte.klant.id) {
      // if all fields are empty dont create.
      KlantApiService.addBezorgadresWithCb(offerte.klant.id, data, (response) => {
        retrieveFromApi();
        addSuccessNotification("Bezorgadres is met succes toegevoegd.");
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BodyTitleBar title={`Bevestig offerte #${offertenummer}`}>
          <ButtonGroup>
            <Button
              type="submit"
              variant='success'
            ><FaHandshake /> Bevestig</Button>
            <Button
              variant="danger"
              onClick={() => {
                history.push(`/offertes/edit/${offertenummer}`);
              }}
              ><FaEdit /> Bewerken</Button>
            <Button
              variant="secondary"
              onClick={() => history.push(`/offertes/view/${offerte.offertenummer}`)}
            ><FaSearch /> Bekijken</Button>
          </ButtonGroup>
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          removeNotifications={removeNotifications}
        />
        <Container fluid>
          <Row>
            <Col sm="12" xs="12" lg="7">
              <Row>
                <Col>
                  <Form.Group as={Form.Row}>
                    <Form.Label column sm={2}>Bezorgdatum</Form.Label>
                    <Col sm="auto">
                      <InputGroup>
                        <DatePicker
                          className='form-control'
                          required={false}
                          showWeekNumbers={true}
                          selected={bezorgdatum}
                          onChange={date => setBezorgdatum(date)}
                          dateFormat={`dd-MM-yyyy`}
                          placeholderText="Onbekend"
                        />
                        <InputGroup.Append>
                          <Button
                            variant='danger'
                            onClick={() => setBezorgdatum(null)}
                          ><FaTimes /></Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Form.Row}>
                    <Form.Label column sm={2}>Mededeling</Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        defaultValue={mededeling}
                        name="mededeling"
                        id="mededeling"
                        ref={register()}
                        autoComplete="off"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Form.Row}>
                    <Form.Label column sm={2}>Verkoopprijs</Form.Label>
                    <Col sm={10}>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>&euro;</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          id="fixed_verkoopprijs"
                          value={fixedVerkoopprijs}
                          placeholder={'0,00'}
                          onChange={e => setFixedVerkoopprijs(e.target.value)}
                          name="fixed_verkoopprijs"
                          ref={register()}
                          autoComplete="off"
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Form.Row}>
                    <Form.Label column sm={2}>Aantal pakketten</Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        value={pakketAantal}
                        onChange={e => setPakketAantal(e.target.value)}
                        name="pakketAantal"
                        id="pakketAantal"
                        required
                        ref={register()}
                        isInvalid={pakketAantal <= 0}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        Minimaal 1 pakket moet worden ingevuld.
                      </Form.Control.Feedback>
                      <ToegewezenPakketten
                        className="mt-2"
                        control={control}
                        total={pakketAantal}
                        totalAdressen={
                          offerte
                          && offerte.klant.bezorgadressen
                          ? offerte.klant.bezorgadressen.length
                          : 0
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {offerte && offerte.klant.bezorgadressen && (
                    <BezorgadresFormPart
                      control={control}
                      bezorgadressen={offerte.verdeling}
                      register={register}
                      onAddBezorgadres={handleAddBezorgadres}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              {offerte && (
                <Accordion defaultActiveKey="1">
                  <Card className="border-dark">
                    <Card.Header
                      className={`bg-dark text-light d-flex
                      justify-content-between flex-wrap
                      flex-md-nowrap align-items-center`}
                    >
                      <span><FaExclamationTriangle /> Informatie</span>
                      <ButtonGroup>
                        <Button
                          variant='primary'
                          onClick={
                            () => OfferteApiService.downloadPDF(offerte.id,
                            `Offerte-${offerte.offertenummer}.pdf`
                            )
                          }
                        ><FaFileDownload /></Button>
                        <Button
                          variant='primary'
                          onClick={() => OfferteApiService.printPDF(offerte.id)}
                        ><FaPrint /></Button>
                      </ButtonGroup>

                    </Card.Header>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="1">
                      <span>Offerte details</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <OfferteDetails offerte={offerte} />
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="2">
                      <span>Prijs berekening</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <OffertePrijsBerekening offerte={offerte} />
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <ContextAwareToggle eventKey="3">
                      <span>
                        Pakket samenstelling <span className="text-muted">(Artikelen)</span>
                      </span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <ArtikelenTableCardList
                        artikelen={offerte.artikelen}
                        offerte={offerte}
                      />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )}
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
};

export const ArtikelenTableCardList = ({ artikelen, offerte, hideNotUsed = true }) => {
  const [filteredArtikelen, setFilteredArtikelen] = useState([]);
  useEffect(() => {
    if (hideNotUsed) {
      setFilteredArtikelen(artikelen.filter(a => a.pivot.aantal > 0));
    } else {
      setFilteredArtikelen(artikelen);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelen]);

  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <Table
          responsive
          style={{ display: "table" }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Omschrijving</th>
              <th>Aantal</th>
              <th>Prijs</th>
            </tr>
          </thead>
          <tbody>
            {filteredArtikelen && filteredArtikelen.map((artikel, index) => {
              return (
                <tr
                  key={index}
                  className={artikel.pivot.aantal > 0 ? '' : 'table-secondary strikeout text-muted'}
                >
                  <td>
                    <InspectButton
                      to={`/artikelen/show/${artikel.artikelnr}`}
                      variant="outline-primary"
                    >#{artikel.artikelnr}</InspectButton>
                  </td>
                  <td>
                    {truncate(artikel.omschrijving, 20)}
                    {artikel.omschrijving.length > 20 ? (
                      <span dangerouslySetInnerHTML={hellip()} />
                    ) : ''}
                  </td>
                  <td>{artikel.pivot.aantal}</td>
                  <td>&euro; {formatCurrency(parseFloat(artikel.verkoop_prijs))}</td>
                </tr>
              )
            })}
            {offerte.verkoopprijs_totaal_btw_hoog !== '0.00' && (
              <tr className="text-muted">
                <td colSpan="2"></td>
                <td>BTW-Hoog</td>
                <td>&euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_hoog))}</td>
              </tr>
            )}
            {offerte.verkoopprijs_totaal_btw_laag !== '0.00' && (
              <tr className="text-muted">
                <td colSpan="2"></td>
                <td>BTW-Laag</td>
                <td>&euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_laag))}</td>
              </tr>
            )}
            {offerte.verkoopprijs_totaal_btw_nul !== '0.00' && (
              <tr className="text-muted">
                <td colSpan="2"></td>
                <td>BTW-Nul</td>
                <td>&euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_nul))}</td>
              </tr>
            )}
            <tr className="table-secondary">
              <th colSpan="2"></th>
              <th>Totaal</th>
              <th>
                &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal))}
                <span className="text-muted">{" "}excl.</span>
                <hr />
                &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_incl))}
                <span className="text-muted">{" "}incl.</span>
              </th>
            </tr>
          </tbody>
        </Table>
      </ListGroup.Item>
    </ListGroup>
  );
};

export const OffertePrijsBerekening = ({ offerte }) => {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <table className="table table-condensed table-responsive" style={{ display: "table" }} >
          <tbody>
            {offerte.verkoopprijs_totaal_btw_hoog !== '0.00' && (
              <tr className="text-right">
                <td>BTW-Hoog</td>
                <td className="text-muted">
                  <em>
                    &euro;{' '}{formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_hoog))}
                  </em>
                </td>
              </tr>
            )}
            {offerte.verkoopprijs_totaal_btw_laag !== '0.00' && (
              <tr className="text-right">
                <td>BTW-Laag</td>
                <td className="text-muted">
                  <em>
                    &euro;{' '}{formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_laag))}
                  </em>
                </td>
              </tr>
            )}
            {offerte.verkoopprijs_totaal_btw_nul !== '0.00' && (
              <tr className="text-right">
                <td>BTW-Nul</td>
                <td className="text-muted">
                  <em>
                    &euro;{' '}{formatCurrency(parseFloat(offerte.verkoopprijs_totaal_btw_nul))}
                  </em>
                </td>
              </tr>
            )}
            <tr className="text-right">
              <td>Pakket prijs</td>
              <td>
                &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal))}
                <span className="text-muted">{" "}excl.</span>
                <hr />
                &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_incl))}
                <span className="text-muted">{" "}incl.</span>
              </td>
            </tr>
            <tr className="text-right">
              <td>Aantal pakketten</td>
              <td className="text-right">{offerte.pakket_aantal}</td>
            </tr>
            <tr className="text-right table-secondary">
              <th>
                Totaal
              </th>
              <th className="text-right">
                &euro; {formatCurrency(offerte.verkoopprijs_totaal * offerte.pakket_aantal)}
                <span className="text-muted">{" "}excl.</span>
                <hr />
                &euro; {formatCurrency(offerte.verkoopprijs_totaal_incl * offerte.pakket_aantal)}
                <span className="text-muted">{" "}incl.</span>
              </th>
            </tr>
          </tbody>
        </table>
      </ListGroup.Item>
    </ListGroup>
  );
};

export const OfferteDetails = ({ offerte }) => {
  if (!offerte) { return (<></>)}
  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Offertenummer</span>
        <span>
          <Link
            to={`/offertes/view/${offerte.offertenummer}`}
          ><strong><em>#</em></strong>{offerte.offertenummer}</Link>
        </span>
      </ListGroup.Item>

      {offerte.klant && (
        <ListGroup.Item
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
        >
          <span>Klant</span>
          <span>
            <Link
              to={`/klanten/edit/${offerte.klantnummer}`}
            >{offerte.klant.bedrijfsnaam}</Link>
          </span>
        </ListGroup.Item>
      )}
      <ListGroup.Item
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Pakket</span>
        <span>
          <Link
            to={`/pakketten/samenstellen/${offerte.pakket.id}`}
          >{offerte.pakket_naam}</Link>
        </span>
      </ListGroup.Item>
      <ListGroup.Item
        className={`d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ${offerte.pakket_aantal <= 0 && 'bg-warning text-dark'}`}
      >
        <span>Aantal pakketten</span>
        <span>{offerte.pakket_aantal}</span>
      </ListGroup.Item>
      <ListGroup.Item
        className={`d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ${offerte.artikelen_aantal <= 0 && 'bg-danger text-white'}`}
      >
        <span>Aantal artikelen</span>
        <span>{offerte.artikelen_aantal}</span>
      </ListGroup.Item>
      <ListGroup.Item
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Pakket prijs</span>
        <span>
          &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal))}
          <span className="text-muted">{" "}excl.</span>
          <hr />
          &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_incl))}
          <span className="text-muted">{" "}incl.</span>
        </span>
      </ListGroup.Item>
      <ListGroup.Item
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Totaal prijs</span>
        <span>
          &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal * offerte.pakket_aantal))}
          <span className="text-muted">{" "}excl.</span>
          <hr />
          &euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal_incl * offerte.pakket_aantal))}
          <span className="text-muted">{" "}incl.</span>
        </span>
      </ListGroup.Item>
      <ListGroup.Item
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Mededeling</span>
        {offerte.mededeling ? (
          <span>{offerte.mededeling}</span>
        ) : (
          <span className="text-muted">
            <FaExclamationCircle /> {" "}
            <strong>GEEN</strong>
          </span>
        )}
      </ListGroup.Item>
    </ListGroup>
  );
};

export default AddVerkooporder;