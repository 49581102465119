import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaFileDownload, FaPrint } from 'react-icons/fa';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';
import api from '../../Services/VerkooporderService';

import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys,
  apiOperators
} from './data/tableData';
import DefaultTooltip from '../../Helpers/DefaultTooltip';

const VerkooporderList = () => {
  const [statussen, setStatussen] = useState([]);
  let history = useHistory();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    api.getStatussen()
      .then(response => {
        setStatussen(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const customColumns = [
    {
      Header: 'Status',
      accessor: (row, i) => {
        const status = statussen && statussen.find(s => s.id === row.current_status);
        return (
          <>
            <DefaultTooltip
              text={status ? status.omschrijving : 'Er is geen status informatie beschikbaar.'}
            >{status ? status.code : row.current_status}</DefaultTooltip>
          </>
        );
      },
      sortType: 'basic',
      id: 'current_status'
    },
    ...tableColumns,
    {
      Header: 'Acties',
      accessor: (row, i) => {
        return (
          <>
            <ButtonGroup>
              <Button
                variant="danger"
                onClick={() => {
                  history.push(`/verkooporders/edit/${row.ordernummer}`)
                }}
              ><FaEdit /></Button>
              <Button
                onClick={() => api.downloadPDF(row.id, `Orderbevestiging-${row.ordernummer}.pdf`)}
              ><FaFileDownload /></Button>
              <Button
                onClick={() => api.printPDF(row.id)}
              ><FaPrint /></Button>
            </ButtonGroup>
          </>
        )
      }
    }
  ];

  return (
    <Container fluid>
      <BodyTitleBar title="Verkooporders" />
      <Row>
        <Col>
          <PaginatedTable
            api={api}
            tableColumns={customColumns}
            hiddenColumns={hiddenColumns}
            searchKeys={searchKeys}
            apiSortKeys={apiSortKeys}
            apiOperators={apiOperators}
            initialSortDir='desc'
            getCellProps={cell => {
              if (cell.column.id === 'artikelen_aantal') {
                return ({ className: cell.value <= 0 ? 'table-danger' : '' });
              }

              if(cell.column.id === 'pakket_aantal') {
                return ({ className: cell.value <= 0 ? 'table-warning' : '' });
              }
              return ({});
            }}
            localStorageId={'saleorders_list'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default VerkooporderList;