import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import { capitalize } from '../../Helpers/Helpers';
import ArtikelenTableCardList from './ArtikelenTableCardList';
import OfferteApiService from '../../Services/OfferteService';
import VerkooporderApiService from '../../Services/VerkooporderService';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import PaginatedArtikelenTable from './PaginatedArtikelenTable';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router';

const MutateSamenstelling = ({ id, type }) => {
  const { notifications, addErrorNotification, addSuccessNotification } = useAlertNotifications();
  const [entity, setEntity] = useState(null);
  const [artikelen, setArtikelen] = useState([]);
  const [entityInfo, setEntityInfo] = useState({
    nummer: '',
    entity: null
  });

  let history = useHistory();

  const supportedTypes = {
    verkooporder: {
      type: 'verkooporder',
      plural: 'verkooporders',
      name: {
        single: 'saleorder',
        plural: 'saleorders',
      },
      nummerKey: 'ordernummer',
      backToEntity: nummer => { history.push(`/verkooporders/edit/${nummer}`); },
      apiCalls: {
        getAll: id => VerkooporderApiService.getById(id),
        mutate: (id, artikelId, aantal) => {
          VerkooporderApiService.mutateArtikel(id, {artikelId, aantal})
            .then(response => {
              addSuccessNotification(`Artikel #${response.data.data.artikelnr} is aangepast.`);
              setArtikelen(response.data.data.artikelen);
              setEntityInfo(prevState => {
                return {
                  ...prevState,
                  entity: response.data.data
                };
              });
            })
            .catch(e => {
              addErrorNotification(e.response.statusText, e.response.status);
            });
        },
        remove: (id, artikelId) => {
          VerkooporderApiService.mutateArtikelRemove(id, artikelId)
            .then(response => {
              setArtikelen(response.data.data.artikelen);
              setEntityInfo(prevState => {
                return {
                  ...prevState,
                  entity: response.data.data
                };
              });
            })
            .catch(e => {
              addErrorNotification(e.response.statusText, e.response.status);
            });
        },
      }
    },
    offerte: {
      type: 'offerte',
      plural: 'offertes',
      name: {
        single: 'invoice',
        plural: 'invoices',
      },
      nummerKey: 'offertenummer',
      backToEntity: nummer => { history.push(`/offertes/edit/${nummer}`); },
      apiCalls: {
        getAll: id => OfferteApiService.get(id),
        mutate: (id, artikelId, aantal) => {
          OfferteApiService.mutateArtikel(id, {artikelId, aantal})
            .then(response => {
              addSuccessNotification(`Artikel #${response.data.data.artikelnr} is aangepast.`);
              setArtikelen(response.data.data.artikelen);
              setEntityInfo(prevState => {
                return {
                  ...prevState,
                  entity: response.data.data
                };
              });
            })
            .catch(e => {
              addErrorNotification(e.response.statusText, e.response.status);
            });
        },
        remove: (id, artikelId) => {
          OfferteApiService.mutateArtikelRemove(id, artikelId)
            .then(response => {
              setArtikelen(response.data.data.artikelen);
              setEntityInfo(prevState => {
                return {
                  ...prevState,
                  entity: response.data.data
                };
              });
            })
            .catch(e => {
              addErrorNotification(e.response.statusText, e.response.status);
            });
        },
      }
    }
  };

  useEffect(() => {
    if (supportedTypes.hasOwnProperty(type)) {
      setEntity(supportedTypes[type]);
    }
  }, [type]);

  useEffect(() => {
    retrieveFromApi();
  }, [entity]);

  const retrieveFromApi = () => {
    if (entity) {
      entity.apiCalls.getAll(id)
        .then(response => {

          setEntityInfo({
            'nummer': response.data[entity.nummerKey]
          });
          setArtikelen(response.data.artikelen);
        })
        .catch(e => {
          addErrorNotification(e.response.statusText, e.response.status);
        });
    }
  };

  return (
    <Container fluid>
      <BodyTitleBar title={`${capitalize(type)}${entityInfo.nummer ? ` #${entityInfo.nummer}` : ''} muteren`}>
        {entityInfo.nummer && entity.backToEntity && (
          <Button
            onClick={() => {
              entity.backToEntity(entityInfo.nummer);
            }}
          ><FaArrowLeft /> Terug naar {type}</Button>
        )}
      </BodyTitleBar>
      <AlertNotifications notifications={notifications} />
      <Row>
        <Col>
          {entity && (
            <PaginatedArtikelenTable
              onIncrement={artikelId => {
                entity.apiCalls.mutate(id, artikelId, 1);
              }}
              onDecrement={artikelId => {
                entity.apiCalls.mutate(id, artikelId, -1)
              }}
              onDetach={(artikelId) => {
                entity.apiCalls.remove(id, artikelId)
              }}
              artikelen={artikelen}
              localStorageId={`mutate_products_for_${entity.name.plural}_list`}
            />
          )}
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Header>Samenstelling</Card.Header>
            <Card.Body>-</Card.Body>
          </Card>
          {entity && (
            <ArtikelenTableCardList
              artikelen={artikelen}
              entity={entity}
              mutate={(artikelId, aantal) => {
                entity.apiCalls.mutate(id, artikelId, aantal);
              }}
              remove={(artikelId) => {
                entity.apiCalls.remove(id, artikelId)
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MutateSamenstelling;