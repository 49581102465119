import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FaTruckLoading } from 'react-icons/fa';
import useEventListener from '../../Helpers/Hooks/useEventListener';

const AddBezorgadres = ({ label, klant, onAdd, ...props}) => {
  const initialBezorgadres = {
    id: null,
    per_adres: '',
    adres: '',
    postcode: '',
    plaatsnaam: '',
    land: 'NL'
  };

  const [bezorgadres, setBezorgadres] = useState(initialBezorgadres);
  const [show, setShow] = useState(false);
  const [pressedKeys, setPressedKeys] = useState([]);

  useEffect(() => {
    if (show && pressedKeys.includes('Enter')) {
      handleConfirmAndClose();
    }
  }, [pressedKeys]);

  const keyDownHandler = ({ key }) => {
    setPressedKeys(prevState => {
      return [
        ...prevState,
        String(key)
      ];
    });
  }

  const keyUpHandler = ({ key }) => {
    setPressedKeys(pressedKeys.filter(pk => pk !== String(key)));
  }

  useEventListener('keydown', keyDownHandler);
  useEventListener('keyup', keyUpHandler);
  const handleShow = () => {
    setBezorgadres(initialBezorgadres);
    setShow(true);
  }
  const handleClose = () => setShow(false);

  const handleConfirmAndClose = () => {
    onAdd(bezorgadres);
    handleClose();
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setBezorgadres({ ...bezorgadres, [name]: value });
  }

  return (
    <>
      <Button
        onClick={handleShow}
        {...props}
      >{label}</Button>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title><FaTruckLoading /> Nieuw bezorgadres</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Per adres</Form.Label>
              <Form.Control
                type="text"
                id="per_adres"
                name="per_adres"
                value={bezorgadres.per_adres}
                placeholder="..."
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Adresregel<sup className="text-danger">*</sup></Form.Label>
              <Form.Control
                type="text"
                id="adres"
                name="adres"
                required
                value={bezorgadres.adres}
                placeholder="Straatnaam 10"
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Postcode<sup className="text-danger">*</sup></Form.Label>
              <Form.Control
                type="text"
                id="postcode"
                name="postcode"
                required
                value={bezorgadres.postcode}
                placeholder="1234 AB"
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Plaats<sup className="text-danger">*</sup></Form.Label>
              <Form.Control
                type="text"
                id="plaatsnaam"
                name="plaatsnaam"
                required
                value={bezorgadres.plaatsnaam}
                placeholder="Amsterdam"
                onChange={handleInputChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Land</Form.Label>
              <select
                className="custom-select"
                id="land"
                onChange={handleInputChange}
                defaultValue={bezorgadres.land}
                name="land"
              >
                <option value="NL">Nederland</option>
                <option value="BE">België</option>
                <option value="DE">Duitsland</option>
              </select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >Sluit</Button>

          <Button
            variant="primary"
            onClick={handleConfirmAndClose}
          >Toevoegen</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddBezorgadres;