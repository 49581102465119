import React, { useState, useEffect } from 'react';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import LeverancierService from '../../Services/LeverancierService';
import { Button, Container, FormCheck, Row, Col } from 'react-bootstrap';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import { FaSave } from 'react-icons/fa';

const ErrorMessage = () => {
  return (
    <>
      <div className="container">
        <div className="alert alert-danger" role="alert">
          Oeps, er lijkt iets fout te zijn gegaan.
        </div>
      </div>
    </>
  )
};

const ShowMessage = props => {
  if (!props.message)
    return <></>;
  return (
    <>
      <div className="alert alert-success" role="alert">
        {props.message}
    </div>
    </>
  )
};

const EditLeverancier = (props) => {
  const initialLeverancierState = {
    id: null,
    nummer: '',
    naam: '',
    adres: '',
    postcode: '',
    plaatsnaam: '',
    land: 'NL',
    contactpersoon: '',
    telefoonnummer: '',
    mobielnummer: '',
    emailadres: '',
    emailadres_order: '',
    website: '',
    webshop: false,
  };

  const [leverancier, setLeverancier] = useState(initialLeverancierState);
  const [message, setMessage] = useState("");
  const {
    notifications,
    removeNotifications,
    addErrorNotification,
    addGenericErrorNotification,
    addSuccessNotification
  } = useAlertNotifications();

  const getLeverancier = id => {
    LeverancierService.get(id)
      .then(response => {
        setLeverancier(response.data);
      })
      .catch(e => { addGenericErrorNotification(e)});
  };

  useEffect(() => {
    getLeverancier(props.match.params.nummer);
  }, [props.match.params.nummer]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setLeverancier({ ...leverancier, [name]: value});
  };

  const updateLeverancier = () => {
    LeverancierService.update(leverancier.id, leverancier)
      .then(response => {
        addSuccessNotification('Leverancier is aangepast.');
      })
      .catch(e => { addGenericErrorNotification(e) });
  };

  return (
    <>
    <Container fluid>
      <BodyTitleBar title="Leverancier aanpassen">
        <Button
          variant="success"
          onClick={updateLeverancier}
        ><FaSave /> Opslaan</Button>
      </BodyTitleBar>
      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      {leverancier.id && (
          <Row>
            <Col>
              <div>
                <div className="form-group form-row">
                  <div className="col">
                    <label htmlFor="nummer">Leveranciernummer</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nummer"
                      required
                      value={leverancier.nummer}
                      onChange={handleInputChange}
                      name="nummer"
                    />
                  </div>
                  <div className="col-md-10">
                    <label htmlFor="naam">Naam</label>
                    <input
                      type="text"
                      className="form-control"
                      id="naam"
                      required
                      value={leverancier.naam}
                      onChange={handleInputChange}
                      name="naam"
                    />
                  </div>
                </div>
                <div className="form-group form-row">
                  <div className="col">
                    <label htmlFor="adres">Adres + nummer</label>
                    <div className="input-group mb-3">
                      {/* <div className="input-group-prepend">
                    <span className="input-group-text">&euro;</span>
                  </div> */}
                      <input
                        type="text"
                        className="form-control"
                        id="adres"
                        required
                        value={leverancier.adres}
                        onChange={handleInputChange}
                        name="adres"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group form-row">
                  <div className="col-md-2">
                    <label htmlFor="postcode">Postcode</label>
                    <input
                      type="text"
                      className="form-control"
                      id="postcode"
                      required
                      value={leverancier.postcode}
                      onChange={handleInputChange}
                      name="postcode"
                    />
                  </div>
                  <div className="col-md-8">
                    <label htmlFor="plaatsnaam">Plaatsnaam</label>
                    <input
                      type="text"
                      className="form-control"
                      id="plaatsnaam"
                      required
                      value={leverancier.plaatsnaam}
                      onChange={handleInputChange}
                      name="plaatsnaam"
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="land">Land</label>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="land"
                        onChange={handleInputChange}
                        defaultValue={leverancier.land}
                        name="land"
                      >
                        <option value="NL">Nederland</option>
                        <option value="BE">België</option>
                        <option value="DE">Duitsland</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="leveranciers_nummer">Contactpersoon</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactpersoon"
                    required
                    value={leverancier.contactpersoon}
                    onChange={handleInputChange}
                    name="contactpersoon"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="telefoonnummer">Telefoonnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    id="telefoonnummer"
                    required
                    value={leverancier.telefoonnummer}
                    onChange={handleInputChange}
                    name="telefoonnummer"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobielnummer">Mobielnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobielnummer"
                    required
                    value={leverancier.mobielnummer}
                    onChange={handleInputChange}
                    name="mobielnummer"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="emailadres">Contact e-mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailadres"
                    required
                    value={leverancier.emailadres}
                    onChange={handleInputChange}
                    name="emailadres"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="emailadres_order">Order e-mail</label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailadres_order"
                    required
                    value={leverancier.emailadres_order}
                    onChange={handleInputChange}
                    name="emailadres_order"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <input
                    type="text"
                    className="form-control"
                    id="website"
                    required
                    value={leverancier.website}
                    onChange={handleInputChange}
                    name="website"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="webshop">Heeft een webshop?</label>
                  <FormCheck
                    id="webshop"
                    required
                    type="switch"
                    label={leverancier.webshop ? 'Ja' : 'Nee'}
                    checked={leverancier.webshop}
                    value={leverancier.webshop}
                    onChange={() => setLeverancier(prevState => {
                      return {
                        ...prevState,
                        webshop: !prevState.webshop
                      }
                    })}
                    name="webshop"
                  />
                </div>
              </div>
            </Col>
          </Row>
      )}

    </Container>
    </>
  );
};

export default EditLeverancier;