import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaPrint, FaFileDownload } from 'react-icons/fa';

const OrderDropdownItems = ({ api, id, label, disabled, pageType, hideDivider, ...props }) => {
  if (!id) {
    return (<></>);
  }

  const downloadPdf = () => {
    let filename = `inkooporder-${id}-leverancier-magazijn.pdf`;
    if (pageType === 'external') {
      filename = `inkooporder-${id}.pdf`;
    } else if (pageType === 'internal') {
      filename = `inkooporder-${id}-magazijn.pdf`;
    }

    return api.downloadPDF(id, filename, pageType);
  }

  return (
    <>
      <Dropdown.Item
        disabled={disabled}
        onClick={downloadPdf}
        {...props}
      ><FaFileDownload /> {label}</Dropdown.Item>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => api.printPDF(id, pageType)}
        {...props}
      ><FaPrint /> {label}</Dropdown.Item>
      <Dropdown.Divider hidden={hideDivider} />
    </>
  )
};

export default OrderDropdownItems;