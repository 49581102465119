import React from 'react';
import {
  Card,
  ListGroup,
  Badge,
  Accordion,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {
  FaEdit,
  FaFileDownload,
  FaHandshake,
  FaPrint
} from 'react-icons/fa';

import CreateOfferteButton from '../Offertes/CreateOfferteButton';
import OfferteApiService from '../../Services/OfferteService';
import VerkooporderApiService from '../../Services/VerkooporderService';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';
import PakketArtikelenCardList from './Cards/PakketArtikelenCardList';
import { formatCurrency } from '../../Helpers/Helpers';

const PakketInformatie = ({ pakket }) => {
  const verkoopprijs = pakket.verkoopprijs_totaal
    ? formatCurrency(parseFloat(pakket.verkoopprijs_totaal))
    : '0,00';

  return (
    <div className="sticky-top">
      <Card className="sticky-top2">
        <Card.Header className="d-flex justify-content-between">
          <Link
            to={`/pakketten/samenstellen/${pakket.id}`}
            className="btn btn-warning"
          >Samenstellen</Link>
          <CreateOfferteButton pakketId={pakket.id} pakket={pakket} checkDisabled={false} />
        </Card.Header>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <span>{pakket.naam}</span>
            <Badge pill variant="secondary">&euro; {verkoopprijs}</Badge>
          </Card.Title>
        </Card.Body>
      </Card>
      <Accordion defaultActiveKey="0">
      <Card>
        <ContextAwareToggle eventKey="0">Verkooporders</ContextAwareToggle>
        <Accordion.Collapse eventKey="0">
          <PakketVerkoopordersCardList pakket={pakket} />
        </Accordion.Collapse>
      </Card>
      <Card>
        <ContextAwareToggle eventKey="1">Offertes</ContextAwareToggle>
        <Accordion.Collapse eventKey="1">
          <PakketOffertesCardList pakket={pakket}/>
        </Accordion.Collapse>
      </Card>
      <Card>
        <ContextAwareToggle eventKey="2">
          <span>
            Samenstelling <span className="text-muted">(Artikelen)</span>
          </span>
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="2">
          <PakketArtikelenCardList pakket={pakket} />
        </Accordion.Collapse>
      </Card>
    </Accordion>
    </div>
  );
};

const PakketVerkoopordersCardList = ({ pakket }) => {
  const verkooporders = pakket.verkooporders.slice().reverse();
  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>#</span>
        <span>Bedrijfsnaam</span>
        <span>Aantal pakketten</span>
        <span>Prijs</span>
        <span>Bezorgdatum</span>
        <span>Handelingen</span>
      </ListGroup.Item>
      {verkooporders && verkooporders.map((verkooporder, index) => {
        return (
          <ListGroup.Item
            key={index}
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
          >
            <span>{verkooporder.ordernummer}</span>
            <span>{verkooporder.klant.bedrijfsnaam}</span>
            <span>{verkooporder.pakket_aantal}</span>
            <span>&euro; {formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal))}</span>
            <span>{verkooporder.bezorgdatum ? verkooporder.bezorgdatum : 'Onbekend'}</span>
            <ButtonGroup>
              <Link
                to={`/verkooporders/edit/${verkooporder.ordernummer}`}
                className="btn btn-danger"
              ><FaEdit/></Link>
              <Button
                onClick={() => VerkooporderApiService.downloadPDF(verkooporder.ordernummer, `Orderbevestiging-${verkooporder.ordernummer}.pdf`)}>
                <FaFileDownload />
              </Button>
              <Button onClick={() => VerkooporderApiService.printPDF(verkooporder.ordernummer)}>
                <FaPrint />
              </Button>
            </ButtonGroup>
          </ListGroup.Item>
        )
      })}
    </ListGroup>
  );
};

const PakketOffertesCardList = ({ pakket }) => {
  const offertes = pakket.offertes.slice().reverse();
  let history = useHistory();

  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>#</span>
        <span>Bedrijfsnaam</span>
        <span>Aantal pakketten</span>
        <span>Prijs</span>
        <span>Handelingen</span>
      </ListGroup.Item>
      {offertes && offertes.reverse().map((offerte, index) => {
        return (
          <ListGroup.Item
            key={index}
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
          >
            <span>{offerte.offertenummer}</span>
            <span>{offerte.klant.bedrijfsnaam}</span>
            <span>{offerte.pakket_aantal}</span>
            <span>&euro; {formatCurrency(parseFloat(offerte.verkoopprijs_totaal))}</span>
            <ButtonGroup>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Geef akkoord en maak verkooporder</Tooltip>}
              >
                {({ref, ...triggerHandler}) => (
                  <Button
                    ref={ref}
                    variant="success"
                    {...triggerHandler}
                    onClick={() => history.push(`/verkooporders/add/${offerte.offertenummer}`)}
                  ><FaHandshake /></Button>
                )}
              </OverlayTrigger>
              <Button onClick={() => OfferteApiService.downloadPDF(offerte.id, `offerte-${offerte.offertenummer}.pdf`)}>
                <FaFileDownload />
              </Button>
              <Button onClick={() => OfferteApiService.printPDF(offerte.id)}>
                <FaPrint />
              </Button>
            </ButtonGroup>
          </ListGroup.Item>
        )
      })}
    </ListGroup>
  );
};

export default PakketInformatie;