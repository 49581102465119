import React, { useEffect, useState } from 'react';
import { FaPhone, FaAt, FaPlus, FaFileInvoice, FaAddressCard, FaMobileAlt } from 'react-icons/fa';
import { Button, Container, Form, Col, Accordion, Card, InputGroup, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/KlantService';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';

const AddKlant = () => {
  const initialKlantState = {
    id: null,
    nummer: '',
    bedrijfsnaam: '',
    contactpersoon: '',
    factuur_adres: '',
    factuur_postcode: '',
    factuur_plaatsnaam: '',
    factuur_land: 'NL',
    factuur_emailadres: '',
    emailadres: '',
    telefoonnummer: '',
    mobielnummer: ''
  };
  const emptyErrorAlert = {
    message: '',
    show: false
  };

  const [klant, setKlant] = useState(initialKlantState);
  const [errorAlert, setErrorAlert] = useState(emptyErrorAlert);
  let history = useHistory();

  const clearErrorAlert = () => {
    setErrorAlert(emptyErrorAlert);
  };

  const handleInputChange = event => {
    const {name, value} = event.target;
    setKlant({...klant, [name]: value});
  };

  useEffect(() => {
    console.log(errorAlert);
  }, [errorAlert]);

  const saveKlant = () => {
    if (klant.nummer === '') {
      console.log('FAIL:NO_KLANT_NUMMER');
      return;
    }

    ApiService.exists(klant.nummer)
      .then(response => {
        if (!response.data) {
          ApiService.create(klant)
            .then(response => {
              setKlant(response.data);
              // redirect to edit page
              const klantnummer = response.data.nummer;
              history.push(`/klanten/edit/${klantnummer}`);
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          setErrorAlert({
            show: true,
            message: 'Klantnummer is al in gebruik'
          });
          console.log('klantnummer already exists');
        }
      })
  };

  return (
    <Container fluid>
      <BodyTitleBar title="Nieuwe klant toevoegen">
        <Button
          variant={'success'}
          disabled={klant.nummer === ''}
          onClick={saveKlant}
        ><FaPlus /> Toevoegen</Button>
      </BodyTitleBar>
      {errorAlert && errorAlert.show && (
        <Alert
          variant="danger"
          dismissible
          onClose={clearErrorAlert}
        >
          {errorAlert.message}
        </Alert>
      )}
      <Form>
        <Form.Group as={Form.Row}>
          <Col sm={12} md={2}>
            <Form.Label>
              Klantnummer {" "}
              <span style={{ color: 'red'}}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="nummer"
              name="nummer"
              required
              placeholder='0000'
              value={klant.nummer}
              onChange={handleInputChange}
            />
          </Col>
          <Col sm={12} md={10}>
            <Form.Label>Bedrijfsnaam</Form.Label>
            <Form.Control
              type="text"
              id="bedrijfsnaam"
              name="bedrijfsnaam"
              placeholder='...'
              value={klant.bedrijfsnaam}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Accordion>
          <Card>
            <ContextAwareToggle eventKey="0">
              <span>
                <FaFileInvoice /> {" "}
                Factuurgegevens
              </span>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>Adres</Form.Label>
                    <Form.Control
                      type="text"
                      id="factuur_adres"
                      name="factuur_adres"
                      placeholder='Straatnaam 10'
                      value={klant.factuur_adres}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={2}>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      type="text"
                      id="factuur_postcode"
                      name="factuur_postcode"
                      placeholder='1234 AA'
                      value={klant.factuur_postcode}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col sm={12} md={8}>
                    <Form.Label>Plaatsnaam</Form.Label>
                    <Form.Control
                      type="text"
                      id="factuur_plaatsnaam"
                      name="factuur_plaatsnaam"
                      placeholder='...'
                      value={klant.factuur_plaatsnaam}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col sm={12} md={2}>
                    <Form.Label>Land</Form.Label>
                    <Form.Control as="select"
                      custom
                      className="custom-select"
                      id="factuur_land"
                      name="factuur_land"
                      onChange={handleInputChange}
                      value={klant.factuur_land}
                    >
                      <option value="NL">Nederland</option>
                      <option value="BE">België</option>
                      <option value="DE">Duitsland</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>
                      E-mailadres {" "}
                      <span className="text-muted small">(factuur)</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><FaAt /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="factuur_emailadres"
                        name="factuur_emailadres"
                        placeholder="factuur@bedrijfsnaam.nl"
                        value={klant.factuur_emailadres}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <ContextAwareToggle eventKey="1">
              <span>
                <FaAddressCard /> {" "}
                Contactgegevens
              </span>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>Contactpersoon</Form.Label>
                    <Form.Control
                      type="text"
                      id="contactpersoon"
                      name="contactpersoon"
                      placeholder='John Smit'
                      value={klant.contactpersoon}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>
                      Telefoonnummer {" "}
                      <span className="text-muted small">(vast)</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><FaPhone /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="telefoonnummer"
                        name="telefoonnummer"
                        placeholder='0123-456789'
                        value={klant.telefoonnummer}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>
                      Telefoonnummer {" "}
                      <span className="text-muted small">(mobiel)</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><FaMobileAlt /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="mobielnummer"
                        name="mobielnummer"
                        placeholder='06-12345678'
                        value={klant.mobielnummer}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
                <Form.Group as={Form.Row}>
                  <Col sm={12} md={12}>
                    <Form.Label>
                      E-mailadres {" "}
                      <span className="text-muted small">(contactpersoon)</span>
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text><FaAt /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="emailadres"
                        name="emailadres"
                        placeholder="contactpersoon@bedrijfsnaam.nl"
                        value={klant.emailadres}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

        </Accordion>
      </Form>
    </Container>
  );
};

export default AddKlant;