import React, { useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { FaAt, FaTruck } from 'react-icons/fa';
import InspectButton from '../../Helpers/InspectButton';
import WidgetApiService from '../../Services/WidgetService';
import useShowing from '../../Helpers/Hooks/useShowing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const SupplierSoldStatisticsRow = ({ supplier }) => {
  if (!supplier) {
    return null;
  }

  const getVariant = (level) => {
    if (!level) {
      return null;
    }

    // eslint-disable-next-line eqeqeq
    if (level == 1) {
      return 'outline-success';
    }
    // eslint-disable-next-line eqeqeq
    if (level == 2) {
      return 'outline-primary';
    }
    // eslint-disable-next-line eqeqeq
    if (level == 3) {
      return 'outline-secondary';
    }
    // eslint-disable-next-line eqeqeq
    if (level == 4) {
      return 'outline-danger';
    }

    return null;
  }

  return (
    <ListGroup.Item>
      <InspectButton
        variant={getVariant(supplier.sold_level)}
        icon={<FaTruck />}
        to={`/leveranciers/edit/${supplier.supplier_code}`}
      >{supplier.supplier_name} <FaAt /> {supplier.sold_total}</InspectButton>
    </ListGroup.Item>
  );
};

const SupplierSoldStatisticsWidget = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const { isShowing, toggle } = useShowing();

  useEffect(() => {
    WidgetApiService.getSupplierSoldStatistics()
      .then(response => {
        setSupplierData(response.data.sort((a, b) => a.aantal_artikelen + b.aantal_artikelen));
        setLoading(false);
        setFailed(false);
      })
      .catch(error => {
        setFailed(true);
        setLoading(false);
      });
  }, []);

  if (failed && !loading) {
    return (
      <Card
        bg={'danger'}
        text={'light'}
        className={'text-center'}
      >
        <Card.Body>
          Het is niet gelukt om uit te rekenen wie de top leveranciers zijn helaas. =(
        </Card.Body>
      </Card>
    );
  }

  if (loading && !failed) {
    return (
      <Card
        bg={'secondary'}
        text={'light'}
        className={'text-center'}
      >
        <Card.Body>
          Uitrekenen welke leverancier de topper is.
        </Card.Body>
      </Card>
    );
  }
  const listGroupClass = "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center";

  return (
    <Card>
      <Card.Header className={listGroupClass}>
        <span>Top leveranciers</span>
        <span>
          <Button
            size={'sm'}
            variant={'outline-secondary'}
            onClick={toggle}
          >{isShowing ? 'Minder' : 'Meer'} <FontAwesomeIcon icon={isShowing ? faCaretUp : faCaretDown} />
          </Button>
        </span>
      </Card.Header>
      {supplierData ? (
        <ListGroup variant={'flush'}>
          {supplierData.slice(0, isShowing ? -1 : 5).map(supplier => {
            return <SupplierSoldStatisticsRow key={supplier.supplier_code} supplier={supplier} />
          })}
        </ListGroup>
      ) : null}
    </Card>
  );
};

export default SupplierSoldStatisticsWidget;