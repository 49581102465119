import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/PlanningService';
import OrderNoVoorraadInfo from './OrderNoVoorraadInfo';
import InspectButton from '../../Helpers/InspectButton';

const OrdersNietOpVoorraadList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.getAllNotOnVoorraad()
      .then(response => {
        setOrders(response.data.reverse());
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSelection = (obj, index) => {
    setSelectedOrder(obj);
    setSelectedIndex(index);
  };

  return (
    <>
      <Container fluid>
        <BodyTitleBar title="Orders niet op voorraad" />
        <Row>
          <Col sm={8}>
            <Table striped hover bordered>
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>Details</th>
                  <th>Bezorgdatum</th>
                  <th>Aantal pakketten</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map((order, index) => {
                  const formatter = new Intl.DateTimeFormat('nl-nl', {
                    day: '2-digit',
                    month: "2-digit",
                    year: "numeric"
                  });
                  const bezorgdatum = formatter.format(new Date(order.bezorgdatum));
                  return (
                    <Fragment key={index}>
                      <tr
                        className={`${(index === selectedIndex ? "table-active" : "")}`}
                        onClick={() => handleSelection(order, index)}
                      >
                        <td>
                          <InspectButton
                            variant="link"
                            to={`/verkooporders/edit/${order.ordernummer}`}
                          >#{order.ordernummer}</InspectButton>
                        </td>
                        <td>
                          Pakket: {order.pakket_naam} <br />
                          Voor: <InspectButton
                            variant="link"
                            to={`/klanten/edit/${order.klantnummer}`}
                          >{order.klant.bedrijfsnaam}</InspectButton>
                        </td>
                        <td>{bezorgdatum}</td>
                        <td>Totaal: <strong>{order.pakket_aantal}</strong></td>
                      </tr>
                      <tr>
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </Col>
          <Col sm={4}>
            <OrderNoVoorraadInfo
              verkooporder={selectedOrder}
              deselect={() => handleSelection(null, -1)}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default OrdersNietOpVoorraadList;