import React from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import AddBezorgadres from './AddBezorgadres';
import EditBezorgadres from './EditBezorgadres';
import ButtonTooltip from '../../Helpers/ButtonTooltip';

const BezorgadressenList = ({ bezorgadressen, onAdd, onEdit, onRemove }) => {
  return (
    <>
      <Row>
        <Col>
          <AddBezorgadres
            label={'Bezorgadres toevoegen'}
            klant={null}
            onAdd={onAdd}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Adres</th>
                <th>Postcode</th>
                <th>Plaats</th>
                <th>Per adres</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {bezorgadressen && bezorgadressen.map((ba, index) => {
                const canBeRemoved = (ba.pallets?.length === 0
                  && ba.vrachtbrieven?.length === 0
                  && ba.verkooporders?.length === 0);
                return (
                  <tr key={index}>
                    <td>{ba.id}</td>
                    <td>{ba.adres}</td>
                    <td>{ba.postcode}</td>
                    <td>{ba.plaatsnaam}</td>
                    <td>{ba.per_adres ? ba.per_adres : '-'}</td>
                    <td>
                      <EditBezorgadres
                        bezorgadresId={ba.id}
                        klant={null}
                        variant="danger"
                        label={<FaEdit />}
                        onSubmit={onEdit}
                      />
                      {' '}
                      <ButtonTooltip
                        variant={canBeRemoved ? 'outline-danger' : 'outline-secondary'}
                        text={canBeRemoved ? 'Veilig te verwijderen' : 'Bezorgadres is in gebruik. Kan niet worden verwijderd.'}
                        onClick={canBeRemoved ? () => onRemove(ba) : () => {}}
                      ><FaTrashAlt /></ButtonTooltip>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default BezorgadressenList;