const artikelApiSortKeys = [
  'artikelnr',
  'omschrijving',
  'inkoop_prijs',
  'btw',
  'besteleenheid',
  'inhoud',
  'leveranciersnummer',
  'leverancier_artikelnr'
];

export default artikelApiSortKeys;