import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { truncate , hellip} from '../../../Helpers/Helpers';
import InspectButton from '../../../Helpers/InspectButton';
import sortByProductNumber from '../../../Helpers/Sorts/sortByProductNumber';

const PakketArtikelenCardList = ({ pakket }) => {
  const [numArtikelen, setNumArtikelen] = useState(0);

  useEffect(() => {
    setNumArtikelen(pakket.artikelen.length);
  }, [pakket])

  return (
    <ListGroup
      variant="flush"
      style={{
        minHeight: `${Math.round(100 / 49 * numArtikelen)}vh`,
        maxHeight: `${Math.round(100 / 49 * numArtikelen) + 50}vh`,
        overflowY: 'auto'
      }}
    >
      <ListGroup.Item
        key={'headers'}
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
      >
        <span>Artikelnr</span>
        <span>Omschrijving</span>
        <span>Prijs</span>
        <span>Aantal</span>
      </ListGroup.Item>
      {pakket.artikelen
        && pakket.artikelen.filter(art => art.pivot.aantal > 0).sort(sortByProductNumber).map((artikel, index) => {
          if (artikel.pivot.aantal === 0) {
            return <>{artikel.omschrijving}</>;
          }
        return (

          <ListGroup.Item
            key={index}
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center"
          >
            <span>
              <InspectButton
                variant='outline-primary'
                to={`/artikelen/show/${artikel.artikelnr}`}
              >#{artikel.artikelnr}</InspectButton>
            </span>
            <span className="text-justify-left">
             {truncate(artikel.omschrijving, 20)}{artikel.omschrijving.length > 20 ? (<span dangerouslySetInnerHTML={hellip()} />) : ''}
            </span>
            <span>&euro; {artikel.verkoop_prijs}</span>
            <span>{artikel.pivot.aantal}</span>
          </ListGroup.Item>
        )
      })}
    </ListGroup>
  );
};

export default PakketArtikelenCardList;