import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Dropdown, InputGroup } from 'react-bootstrap';
import { FaArchive } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { EURPriceFormatToServerFormat } from '../../Helpers/Helpers';
import OfferteApiService from '../../Services/OfferteService';

const CreateOfferteButton = ({
  variant = 'primary',
  isDropDownItem,
  pakket,
  ...props
}) => {

  useEffect(() => {
    setOfferte(prevState => {
      return {
        ...prevState,
        fixed_verkoopprijs: pakket.fixed_verkoopprijs ?? '0,00',
      };
    });
  }, [pakket]);

  const initialOfferte = {
    pakketId: props.pakketId,
    pakketAantal: 1,
    mededeling: '',
    fixed_verkoopprijs: '0,00'
  };

  const [offerte, setOfferte] = useState(initialOfferte);
  const [show, setShow] = useState(false);
  let history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirmAndShow = () => {
    createOfferte((data) => {
      history.push(`/offertes/view/${data.offertenummer}`);
    });
  }

  const handleConfirmAndClose = () => {
    createOfferte((data) => {
      setOfferte(data);
      if (props.refreshParent) {
        props.refreshParent();
      }
    });
    handleClose();
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setOfferte({ ...offerte, [name]: value });
  };

  const createOfferte = (onSucces) => {
    offerte.pakketId = props.pakketId;
    offerte.fixed_verkoopprijs = offerte.fixed_verkoopprijs ? EURPriceFormatToServerFormat(offerte.fixed_verkoopprijs) : null;
    OfferteApiService.create(offerte)
      .then(response => {
        onSucces(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      {isDropDownItem
        ? (
            <Dropdown.Item
              disabled={props.checkDisabled}
              onClick={handleShow}
            ><FaArchive /> Offerte maken</Dropdown.Item>
          )
        : (
            <Button
              disabled={props.checkDisabled}
              variant={variant}
              onClick={handleShow}
            ><FaArchive /> Offerte maken</Button>
          )
      }

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title><FaArchive /> Offerte aanmaken</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Aantal pakketten</Form.Label>
              <Form.Control
                type="text"
                id="pakketAantal"
                required
                value={offerte.pakketAantal}
                onChange={handleInputChange}
                name="pakketAantal"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mededeling</Form.Label>
              <Form.Control
                type="text"
                id="mededeling"
                value={offerte.mededeling}
                onChange={handleInputChange}
                name="mededeling"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Vaste verkoopprijs</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>&euro;</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  id="fixed_verkoopprijs"
                  placeholder={'0,00'}
                  value={offerte.fixed_verkoopprijs}
                  onChange={handleInputChange}
                  name="fixed_verkoopprijs"
                  autoComplete="off"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sluit
          </Button>
          <Button variant="primary" onClick={handleConfirmAndClose}>
            Maak en sluit
          </Button>
          <Button variant="primary" onClick={handleConfirmAndShow}>
            Maak en bekijk
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOfferteButton;