import { Button, ButtonGroup } from "react-bootstrap";
import React from "react";

const StockLocationDetails = ({location, quantity}) => {
    return (
        <>
            <ButtonGroup>
                <Button
                    size={'sm'}
                    variant={'secondary'}
                    disabled
                >{quantity}x</Button>
                <Button
                    size={'sm'}
                    variant={'outline-secondary'}
                    disabled
                > @ {location}</Button>
            </ButtonGroup>
            {' '}
        </>
    )
};

export default StockLocationDetails;