import { useEffect, useState } from "react";
import { ListGroup, Table } from "react-bootstrap";
import { formatCurrency } from "../../../Helpers/Helpers";

const PrijsBerekeningCard = ({ verkooporder, originalVerkoopprijs }) => {
  const [verkoopprijs, setVerkoopprijs] = useState({
    totaalExcl: '0,00',
    totaalIncl: '0,00',
    totaalBtwHoog: '0,00',
    totaalBtwLaag: '0,00',
    totaalBtwNul: '0,00',
    fixedVerkoopprijs: '0,00'
  });

  const [hasFixedPrijs, setHasFixedPrijs] = useState(false);
  const [btwRows, setBtwRows] = useState([
    {
      label: 'BTW-Hoog',
      calculated: '0,00',
      final: '0,00'
    },
    {
      label: 'BTW-Laag',
      calculated: '0,00',
      final: '0,00'
    },
    {
      label: 'BTW-Nul',
      calculated: '0,00',
      final: '0,00'
    }
  ]);

  useEffect(() => {
    setVerkoopprijs({
      totaalExcl: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal ?? '0.00')),
      totaalIncl: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_incl ?? '0.00')),
      totaalBtwHoog: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_hoog ?? '0.00')),
      totaalBtwLaag: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_laag ?? '0.00')),
      totaalBtwNul: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_nul ?? '0.00')),
      fixedVerkoopprijs: formatCurrency(parseFloat(verkooporder.fixed_verkoopprijs ?? '0.00'))
    });
    setHasFixedPrijs(originalVerkoopprijs?.fixedVerkoopprijs !== '0.00');
    setBtwRows([
      {
        label: 'BTW-Hoog',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwHoog ?? '0.00')),
        final: verkoopprijs.totaalBtwHoog
      },
      {
        label: 'BTW-Laag',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwLaag ?? '0.00')),
        final: verkoopprijs.totaalBtwLaag
      },
      {
        label: 'BTW-Nul',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwNul ?? '0.00')),
        final: verkoopprijs.totaalBtwNul
      }
    ]);
  }, [verkooporder, originalVerkoopprijs]);

  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <Table responsive style={{ display: "table" }} bordered>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th className="text-right">Berekend</th>
              {hasFixedPrijs && (<th className="text-right">Vastgezet</th>)}
            </tr>
          </thead>
          <tbody>
            {btwRows && btwRows.map((btwRow, i) => {
              return (
                <tr className="text-right" key={`prijs-berekening-btw-row-${i}`}>
                  <td>{btwRow.label}</td>
                  {hasFixedPrijs && (
                    <td className="text-muted"><em>&euro; {btwRow.calculated}</em></td>
                  )}
                  <td className="text-muted2"><em>&euro; {btwRow.final}</em></td>
                </tr>
              )
            })}
            <tr className="text-right">
              <td>Pakket prijs</td>
              {hasFixedPrijs && (
                <td className="text-muted">
                  &euro; {originalVerkoopprijs.totaalExcl} {' '}excl.
                  <hr />
                  &euro; {originalVerkoopprijs.totaalIncl} {' '}incl.
                </td>
              )}
              <td>
                &euro; {verkoopprijs.totaalExcl}
                <span className="text-muted">{" "}excl.</span>
                <hr />
                &euro; {verkoopprijs.totaalIncl}
                <span className="text-muted">{" "}incl.</span>
              </td>
            </tr>
            <tr className="text-right">
              <td>Aantal pakketten</td>
              {hasFixedPrijs && (
                <td className="text-right text-muted">{verkooporder.pakket_aantal}</td>
              )}
              <td className="text-right">{verkooporder.pakket_aantal}</td>
            </tr>
            <tr className="text-right table-secondary">
              <th>
                Totaal
              </th>
              {hasFixedPrijs && (
                <th className="text-right text-muted">
                  &euro; {formatCurrency(originalVerkoopprijs.totaalExcl * verkooporder.pakket_aantal)}
                  <span className="text-muted">{" "}excl.</span>
                  <hr />
                  &euro; {formatCurrency(originalVerkoopprijs.totaalIncl * verkooporder.pakket_aantal)}
                  <span className="text-muted">{" "}incl.</span>
                </th>
              )}
              <th className="text-right">
                &euro; {formatCurrency(verkooporder.verkoopprijs_totaal * verkooporder.pakket_aantal)}
                <span className="text-muted">{" "}excl.</span>
                <hr />
                &euro; {formatCurrency(verkooporder.verkoopprijs_totaal_incl * verkooporder.pakket_aantal)}
                <span className="text-muted">{" "}incl.</span>
              </th>
            </tr>
          </tbody>
        </Table>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default PrijsBerekeningCard;