import { useState } from 'react';

const useShowing = (initialValue = false) => {
  const [isShowing, setIsShowing] = useState(initialValue);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle
  };
};

export default useShowing;