import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Dropdown } from 'react-bootstrap';
import { FaBarcode, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import ApiService from '../../Services/ArtikelService';

const AantalArtikelenWidget = () => {
  const [aantal, setAantal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiService.getStats()
      .then(response => {
        setAantal(response.data.aantal);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  let history = useHistory();

  const redirect = () => {
    history.push(`/artikelen/add`);
  }

  const redirectTo = () => {
    history.push('/artikelen');
  }

  if (loading) {
    return (
      <Card
        bg="secondary"
        text="light"
        className="text-center"
      >
        <Card.Body>
          <>Artikelen opruimen, sorteren en tellen...</>
        </Card.Body>
      </Card>
    );
  }

  if (!loading && aantal === 0) {
    return (
      <Card className="text-center">
        <Card.Header>
          <span><FaBarcode /> Artikelen</span>
        </Card.Header>
        <Card.Body>
          <Button
            onClick={redirect}
          ><FaBarcode /> Voeg het eerste artikel toe</Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <span><FaBarcode /> Artikelen</span>
        <Dropdown as={ButtonGroup}>
          <Button
            variant="primary"
            onClick={redirect}
          ><FaPlus /></Button>
          {/* <Dropdown.Toggle split variant="primary" />
          <Dropdown.Menu>
            <Dropdown.Item><FaPlus /> Nieuw artikel</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item><FaPlus /> Bulk invoer (.csv)</Dropdown.Item>
          </Dropdown.Menu> */}
        </Dropdown>
      </Card.Header>
      <Card.Body
        className="text-center cursor"
        onClick={redirectTo}
        style={{ cursor: 'pointer' }}
      >
        <h1><FaBarcode /> {aantal}</h1>
      </Card.Body>
    </Card>
  );
};

export default AantalArtikelenWidget;