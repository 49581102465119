import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from 'react-bootstrap';
import { FaSearch, FaPlus } from 'react-icons/fa';

import KlantApiService from '../../Services/KlantService';
import ApiService from '../../Services/PakketService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import sortByCustomerName from '../../Helpers/Sorts/sortByCustomerName';

const AddPakket = () => {
  const initialKlant = {
    bedrijfsnaam: ''
  };
  const initialPakketState = {
    id: null,
    naam: '',
    klantnummer: '',
    klant: initialKlant,
    verkoopprijs_totaal: '0,00',
    verkoopprijs_totaal_incl: '0,00',
    verkoopprijs_totaal_btw_hoog: '0,00',
    verkoopprijs_totaal_btw_laag: '0,00',
    verkoopprijs_totaal_btw_nul: '0,00',
    fixed_verkoopprijs: '0,00',
    artikelen: []
  };

  const [klanten, setKlanten] = useState([]);
  const [pakket, setPakket] = useState(initialPakketState);
  const [placeholderPakketnaam, setPlaceholderPakketnaam] = useState('...');

  const {
    notifications,
    addNotification,
    addGenericErrorNotification,
    addErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  let history = useHistory();

  const redirect = (pakketId) => {
    history.push(`/pakketten/samenstellen/${pakketId}`);
  };

  useEffect(() => {
    retrieveFromApi();
  }, []);

  useEffect(() => {
    const klant = klanten.find(k => k.nummer === pakket.klantnummer);

    setPlaceholderPakketnaam(klant ? klant.bedrijfsnaam : '...');
    setPakket({ ...pakket, 'naam': klant ? klant.bedrijfsnaam : ''});
    if (klant) {
      setPakket(prevState => {
        return {
          ...prevState,
          klant: klant
        }
      });
    }
  }, [pakket.klantnummer]);

  const retrieveFromApi = () => {
    KlantApiService.getAll()
      .then(response => {
        setKlanten(response.data.sort(sortByCustomerName));
      })
      .catch(e => {
        console.warn(e);
        addGenericErrorNotification(e);
      });
  };

  const createPakket = () => {
    const data = {...pakket};

    if (data.klant) {
      if (data.klant.nummer !== data.klantnummer) {
        addErrorNotification('De twee velden voor klant en klantnummer komen niet overeen.');
        return;
      }
    }

    if (!data.klantnummer || data.klantnummer === '') {
      addErrorNotification('Er kan geen pakket worden aangemaakt, er is geen klantnummer ingevuld of klant geselecteerd.');
    } else {
      ApiService.create(data)
        .then(response => {
          setPakket(initialPakketState);
          addSuccessNotification((
            <>
              <p>
                Het pakket is met succes aangemaakt voor <strong><em>{data.klant.bedrijfsnaam}</em></strong>.
              </p>
              {response.data.id && (
                <>
                  <p>
                    Bekijk en bewerk het pakket <Link to={`/pakketten/samenstellen/${response.data.id}`}>hier</Link>. Of ga hieronder verder om nog een pakket te maken.
                  </p>
                  <hr />
                  <span className="d-flex justify-content-end">
                    <Button
                      variant="success"
                      onClick={() => history.push(`/pakketten/samenstellen/${response.data.id}`)}
                    ><FaSearch /> Naar pakket</Button>
                  </span>
                </>
              )}
            </>
          ), 'Pakket aangemaakt');
          if (!data.naam) {
            addNotification('info', (
              <p>
                Er is <span style={{ textDecoration: 'underline' }} >geen</span> pakketnaam ingevuld. Pakketnaam is: '<strong><em>{data.klant.bedrijfsnaam}</em></strong>'.
              </p>
            ), 'Let op!');
          }
        })
        .catch(e => {
          addGenericErrorNotification(e);
        });
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setPakket({ ...pakket, [name]: value });
  };

  return (
    <>
      <BodyTitleBar title={`Nieuw pakket aanmaken`}>
        <Button
          variant="success"
          onClick={() => createPakket()}
        ><FaPlus /> Aanmaken</Button>
      </BodyTitleBar>
      <AlertNotifications
        notifications={notifications}
        showAmount={3}
        removeNotifications={removeNotifications}
      />
    <Container fluid>
      <Row>
        <Col>
          <Form>
            <Form.Group as={Form.Row}>
              <Form.Label column sm={2}>Naam</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder={placeholderPakketnaam}
                  id="naam"
                  required
                  value={pakket.naam}
                  onChange={handleInputChange}
                  name="naam"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
              <Form.Label column md={2}>Klantnummer</Form.Label>
              <Col md={1}>
                <Form.Control
                  type="text"
                  placeholder="..."
                  required
                  value={pakket.klantnummer}
                  onChange={handleInputChange}
                  name="klantnummer"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
              <Form.Label column md={2}>Klant</Form.Label>
              <Col md={5}>
                <select
                  className="custom-select"
                  onChange={handleInputChange}
                  value={pakket.klantnummer}
                  name="klantnummer"
                >
                  <option value="" disabled>Kies een klant</option>
                  {klanten.map((klant) => {
                    return (
                      <option
                        key={klant.nummer}
                        value={klant.nummer}>{klant.bedrijfsnaam}
                      </option>
                    )
                  })}
                </select>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default AddPakket;