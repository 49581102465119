import React from 'react';
import Sidebar from './Sidebar';
import './Layout.css';
import Navbar from './Nav/Navbar';
import { Container, Row, Col } from 'react-bootstrap';
import useReadLocalStorage from '../Hooks/useReadLocalStorage';

const Layout = ({ children }) => {

  const pattern = useReadLocalStorage('sidebar_pattern');

  return (
    <>
      <Navbar />
      <Container fluid>
        <Row>
          <Col className="d-none d-lg-block d-xl-block">
            <Sidebar bgImage={pattern?.pattern} />
          </Col>
          <Col role="main" className="ml-sm-auto col-md-12 col-lg-10">
            {children}
          </Col>
        </Row>
      </Container>
      </>
  );
};

export default Layout;