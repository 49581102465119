import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Table, OverlayTrigger, ButtonGroup, Tooltip, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import ApiService from '../../Services/PlanningService';
import PalletApiService from '../../Services/PalletService';
import InpakopdrachtApiService from '../../Services/InpakopdrachtService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import { Link, useHistory } from 'react-router-dom';
import { FaFileDownload, FaPrint, FaEdit } from 'react-icons/fa';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import DefaultTooltip from '../../Helpers/DefaultTooltip';
import InspectButton from '../../Helpers/InspectButton';

const InpakopdrachtenList = () => {
  const [inpakopdrachten, setInpakopdrachten] = useState([]);
  const {
    notifications,
    addGenericErrorNotification,
    removeNotifications
  } = useAlertNotifications();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.getInpakopdrachten()
      .then(response => {
        const reversed = [...response.data].sort((a, b) => b.id - a.id);
        setInpakopdrachten(reversed);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  return (
    <>
      <BodyTitleBar title="Inpakopdrachten" />
      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      <Container fluid>
        <Row>
          <Col>
            <Table hover striped>
              <thead className="thead-dark">
                <tr>
                  <th>Ordernummer</th>
                  <th>Order status</th>
                  <th>Bezorgdatum</th>
                  <th>Pakketnaam</th>
                  <th>Klant</th>
                  <th>Handelingen</th>
                </tr>
              </thead>
              <tbody>
                {inpakopdrachten && inpakopdrachten.map((opdracht, index) => {
                  const formatter = new Intl.DateTimeFormat('nl-nl', {
                    day: '2-digit',
                    month: "2-digit",
                    year: "numeric"
                  });
                  const order = opdracht.verkooporder;
                  const bezorgdatum = formatter.format(new Date(order.bezorgdatum));
                  return (
                    <tr key={index}>
                      <td className="align-middle">
                        <InspectButton
                          to={`/verkooporders/edit/${order.ordernummer}`}
                        >#{opdracht.verkooporder.ordernummer}</InspectButton>
                      </td>
                      <td className="align-middle text-center">
                        <DefaultTooltip
                          text={opdracht.status_info ? opdracht.status_info.omschrijving : 'Er is geen status informatie beschikbaar.'}
                        >{opdracht.status_info.code ?? '?'}</DefaultTooltip>
                      </td>
                      <td className="align-middle">
                        {bezorgdatum}
                      </td>
                      <td className="align-middle">
                        {order.pakket_naam}
                      </td>
                      <td className="align-middle">
                        <InspectButton
                          to={`/klanten/edit/${order.klantnummer}`}
                        >{order.klant.bedrijfsnaam}</InspectButton>
                      </td>
                      <td className="align-middle">
                        <ButtonGroup>
                        <Link
                          className="btn btn-danger"
                          to={`/planning/inpakopdrachten/${opdracht.id}`}
                        ><FaEdit /></Link>
                        {" "}

                          <DropdownButton
                            variant="primary"
                            as={ButtonGroup}
                            title={<FaFileDownload />}
                          >
                            <Dropdown.Item
                              onClick={() => InpakopdrachtApiService.downloadPDF(opdracht.id, `Inpakopdracht-${opdracht.id}.pdf`)}
                            ><FaFileDownload /> Inpak-opdracht</Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => PalletApiService.downloadPDF(opdracht.id, `Inpakopdracht-${opdracht.id}.pdf`)}
                            ><FaFileDownload /> Palletformulieren</Dropdown.Item>
                          </DropdownButton>
                          {" "}
                          <DropdownButton
                            variant="primary"
                            as={ButtonGroup}
                            title={<FaPrint />}
                          >
                            <Dropdown.Item
                              onClick={() => InpakopdrachtApiService.printPDF(opdracht.id)}
                            ><FaPrint /> Inpak-opdracht</Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => PalletApiService.printPDF(opdracht.id)}
                            ><FaPrint /> Palletformulieren</Dropdown.Item>

                          </DropdownButton>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default InpakopdrachtenList;