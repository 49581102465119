import { useEffect, useReducer, useRef } from 'react';
import axios, { isCancel } from 'axios';
import ajax from '../http-common';

const useAxios = (url, memoized = false) => {
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    error: null,
    data: [],
    fromCache: false
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED_CACHE':
        return { ...initialState, status: 'fetched', data: action.payload, fromCache: true };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (!url) return;

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: 'FETCHED_CACHE', payload: data });
      } else {
        ajax.get(url, { cancelToken: source.token })
          .then(res => {
            const data = res.data;
            if (memoized) cache.current[url] = data;
            if (isCancel(res)) return;
            dispatch({ type: 'FETCHED', payload: data });
          })
          .catch(error => {
            if (isCancel(error)) return;
            dispatch({ type: 'FETCH_ERROR', payload: error.message });
          });
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };

  }, [url]);

  return state;
};

export default useAxios;