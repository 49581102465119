import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';

const DefaultModal = ({
  isShowing,
  hide,
  closeButton,
  headerClass = '',
  bodyClass = '',
  footerClass = '',
  header = null,
  body = null,
  footer = null,
}) => isShowing ? (
  <Fragment>
    <Modal
      size="lg"
      show={isShowing}
      onHide={hide}
    >
      {header && (
        <Modal.Header className={headerClass} closeButton={closeButton}>
          {header}
        </Modal.Header>
      )}
      {body && (
        <Modal.Body className={bodyClass}>
          {body}
        </Modal.Body>
      )}
      {footer && (
        <Modal.Footer className={footerClass}>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  </Fragment>
) : null;

export default DefaultModal;

