import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FaHandshake } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import ApiService from '../../Services/VerkooporderService';

const AantalVerkoopordersWidget = () => {
  const [aantal, setAantal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  let history = useHistory();

  const redirectTo = () => {
    history.push('/verkooporders');
  }

  useEffect(() => {
    ApiService.getStatistics()
      .then(response => {
        setAantal(response.data.aantal);
        setLoading(false);
        setFailed(false);
      })
      .catch(e => {
        setFailed(true);
        setLoading(false);
      });
  }, []);

  if (failed && !loading) {
    return (
      <Card
        bg="danger"
        text="light"
        className="text-center"
      >
        <Card.Body>
          Verkooporders verzamelen is mislukt.
        </Card.Body>
      </Card>
    );
  }

  if (loading && !failed) {
    return (
      <Card
        bg="secondary"
        text="light"
        className="text-center"
      >
        <Card.Body>
          <>Verkooporders bij elkaar rapen en tellen...</>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card
      bg={aantal > 0 ? 'success' : 'danger'}
      border={aantal === 0 ? 'danger' : ''}
      text={aantal > 0 ? 'light' : 'light'}
      className="text-center"
    >
      <Card.Body
        style={{ cursor: 'pointer' }}
        onClick={redirectTo}
      >
        <h1><FaHandshake /> {aantal}</h1>
        <h4>Verkooporders</h4>
      </Card.Body>
    </Card>
  );
};

export default AantalVerkoopordersWidget;