import React from 'react';
import DefaultTooltip from './DefaultTooltip';
import { hellip, truncate } from './Helpers';

const TruncateTooltip = ({ text = '', tooltipText = null, threshold = 15 }) => {
  return (
    <>
      <DefaultTooltip
        text={tooltipText ?? text}
      >
        {threshold === -1 ? (
          text
        ) : (
          <>{truncate(text, threshold)} {text.length > threshold ? (<span dangerouslySetInnerHTML={hellip()} />) : ''}</>
        )
      }
      </DefaultTooltip>
    </>
  );
};

export default TruncateTooltip;