import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FaHandshake } from 'react-icons/fa';
import { hellip } from './Helpers';

const ButtonTooltip = ({
  children = '',
  text = '',
  variant = "success",
  onClick = () => {},
  disabled = false
}) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<Tooltip>{text ?? hellip()}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          ref={ref}
          variant={variant}
          {...triggerHandler}
          onClick={onClick}
          disabled={disabled}
        >{children}</Button>
      )}
    </OverlayTrigger>
  );
};

export default ButtonTooltip;