import React, { useState, useEffect } from 'react';
import { Container, ButtonGroup, Button, Row, Col, Form, Accordion, Card, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router';
import { FaExclamationTriangle, FaSearch, FaFileDownload, FaPrint, FaArrowLeft, FaSave } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import api from '../../Services/OfferteService';
import OfferteConfirmButton from './OfferteConfirmButton';
import ContextAwareToggle from '../../Helpers/ContextAwareToggle';

import { OfferteDetails, OffertePrijsBerekening, ArtikelenTableCardList } from '../Verkooporders/AddVerkooporder';
import { formatCurrency } from '../../Helpers/Helpers';

const EditOfferte = () => {
  const [offerte, setOfferte] = useState(null);
  const [pakketAantal, setPakketAantal] = useState(0);
  const [mededeling, setMededeling] = useState('');
  const [fixedVerkoopprijs, setFixedVerkoopprijs] = useState('0,00');

  const { register, handleSubmit } = useForm();

  let { offertenummer } = useParams();
  let history = useHistory();

  const {
    notifications,
    addErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    if (offertenummer) {
      api.getByOffertenummer(offertenummer)
        .then(response => {
          setOfferte(response.data);
          setMededeling(response.data.mededeling);
          setPakketAantal(response.data.pakket_aantal);

          handleFixedVerkoopprijs(response.data.fixed_verkoopprijs);
        })
        .catch(e => {
          addErrorNotification(e.response.statusText, e.response.status);
        });
    } else {
      addErrorNotification('Het lijkt erop dat er geen offertenummer is gevonden.', 'Er is iets fout gegaan', false);
    }
  };

  const onSubmit = fieldData => {
    const dto = {
      offerteId: offerte.id,
      mededeling: fieldData.mededeling,
      pakketAantal: fieldData.pakketAantal,
      fixedVerkoopprijs: fieldData.fixedVerkoopprijs?.replace(',', '.')
    };

    if (offerte) {
      api.update(offerte.id, dto)
        .then(response => {
          addSuccessNotification('Offerte met succes aangepast.');
          setOfferte(prevState => {
            return {
              ...prevState,
              ...response.data
            }
          });
          setMededeling(response.data.mededeling);
          setPakketAantal(response.data.pakket_aantal);
          handleFixedVerkoopprijs(response.data.fixed_verkoopprijs);
        })
        .catch(e => {
          addErrorNotification(e.response.statusText, e.response.status);
        });
    }
  };

  const handleFixedVerkoopprijs = verkoopprijs => {
    if (verkoopprijs) {
      const formattedFixedVerkoopprijs = formatCurrency(parseFloat(verkoopprijs), false);
      setFixedVerkoopprijs(formattedFixedVerkoopprijs);
    } else {
      setFixedVerkoopprijs('0,00');
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BodyTitleBar
          title={offertenummer ? `Offerte #${offertenummer} bewerken` : 'Offerte bewerken'}
        >
          {offerte ? (
            <span>
              <>
                <ButtonGroup>
                  <Button
                    variant="primary"
                    type="submit"
                  ><FaSave /> Opslaan</Button>
                  <Button
                    variant="danger"
                    onClick={() => history.push(`/offertes/mutate/${offerte.id}`)}
                  >Muteer samenstelling <FaExclamationTriangle /></Button>
                </ButtonGroup>
                {' '}
                <OfferteConfirmButton offertenummer={offerte.offertenummer} label="Bevestigen"/>
                {' '}
                <Button
                  variant="secondary"
                  onClick={() => history.push(`/offertes/view/${offerte.offertenummer}`)}
                ><FaSearch /> Bekijken</Button>
              </>
            </span>
          ) : (
            <Button
              onClick={() => {
                history.push('/offertes');
              }}
            ><FaArrowLeft /> Terug naar offertes</Button>
          )}
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          showAmount={3}
          removeNotifications={removeNotifications}
        />
        {offerte && (

        <Container fluid>
          <Row>
            <Col sm="12" xs="12" lg="7">
              <Form.Group as={Form.Row}>
                <Form.Label column sm={2}>Mededeling</Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    defaultValue={mededeling}
                    name="mededeling"
                    id="mededeling"
                    ref={register()}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={2}>Aantal pakketten</Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={pakketAantal}
                    onChange={e => setPakketAantal(e.target.value)}
                    name="pakketAantal"
                    id="pakketAantal"
                    required
                    ref={register()}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={2}>Vaste verkoopprijs</Form.Label>
                <Col sm={10}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>&euro;</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      id="fixedVerkoopprijs"
                      value={fixedVerkoopprijs}
                      onChange={e => setFixedVerkoopprijs(e.target.value)}
                      name="fixedVerkoopprijs"
                      ref={register()}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Accordion defaultActiveKey="1">
                <Card className="border-dark">
                  <Card.Header
                    className={`bg-dark text-light d-flex
                    justify-content-between flex-wrap
                    flex-md-nowrap align-items-center`}
                  >
                    <span><FaExclamationTriangle /> Informatie</span>
                    <ButtonGroup>
                      <Button
                        variant='primary'
                        onClick={
                          () => api.downloadPDF(offerte.offertenummer,
                            `Offerte-${offerte.offertenummer}.pdf`
                          )
                        }
                      ><FaFileDownload /></Button>
                      <Button
                        variant='primary'
                        onClick={() => api.printPDF(offerte.offertenummer)}
                      ><FaPrint /></Button>
                    </ButtonGroup>

                  </Card.Header>
                </Card>
                <Card>
                  <ContextAwareToggle eventKey="1">
                    <span>Offerte details</span>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="1">
                    <OfferteDetails offerte={offerte} />
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <ContextAwareToggle eventKey="2">
                    <span>Prijs berekening</span>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="2">
                    <OffertePrijsBerekening offerte={offerte} />
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <ContextAwareToggle eventKey="3">
                    <span>
                      Pakket samenstelling <span className="text-muted">(Artikelen)</span>
                    </span>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="3">
                    <ArtikelenTableCardList
                      artikelen={offerte.artikelen}
                      offerte={offerte}
                      hideNotUsed={false}
                    />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
        )}
      </Form>
    </>
  );
};

export default EditOfferte;