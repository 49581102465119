import { useEffect, useState } from "react";
import {ListGroup, Table } from "react-bootstrap";
import { formatCurrency } from "../../../Helpers/Helpers";
import InspectButton from "../../../Helpers/InspectButton";
import TruncateTooltip from "../../../Helpers/TruncateTooltip";

const SamenstellingCard = ({ artikelen, verkooporder, originalVerkoopprijs }) => {
  const colSpan = 3;

  const [verkoopprijs, setVerkoopprijs] = useState({
    totaalExcl: '0,00',
    totaalIncl: '0,00',
    totaalBtwHoog: '0,00',
    totaalBtwLaag: '0,00',
    totaalBtwNul: '0,00',
    fixedVerkoopprijs: '0,00'
  });

  const [hasFixedPrijs, setHasFixedPrijs] = useState(false);
  const [btwRows, setBtwRows] = useState([
    {
      label: 'BTW-Hoog',
      calculated: '0,00',
      final: '0,00'
    },
    {
      label: 'BTW-Laag',
      calculated: '0,00',
      final: '0,00'
    },
    {
      label: 'BTW-Nul',
      calculated: '0,00',
      final: '0,00'
    }
  ]);

  useEffect(() => {
    setVerkoopprijs({
      totaalExcl: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal ?? '0.00')),
      totaalIncl: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_incl ?? '0.00')),
      totaalBtwHoog: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_hoog ?? '0.00')),
      totaalBtwLaag: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_laag ?? '0.00')),
      totaalBtwNul: formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_btw_nul ?? '0.00')),
      fixedVerkoopprijs: formatCurrency(parseFloat(verkooporder.fixed_verkoopprijs ?? '0.00'))
    });
  }, [verkooporder]);

  useEffect(() => {
    setHasFixedPrijs(originalVerkoopprijs?.fixedVerkoopprijs !== '0.00');
    setBtwRows([
      {
        label: 'BTW-Hoog',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwHoog ?? '0.00')),
        final: verkoopprijs.totaalBtwHoog
      },
      {
        label: 'BTW-Laag',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwLaag ?? '0.00')),
        final: verkoopprijs.totaalBtwLaag
      },
      {
        label: 'BTW-Nul',
        calculated: formatCurrency(parseFloat(originalVerkoopprijs.totaalBtwNul ?? '0.00')),
        final: verkoopprijs.totaalBtwNul
      }
    ]);
  }, [originalVerkoopprijs]);

  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <Table responsive style={{ display: "table" }} >
          <thead>
            <tr>
              <th>Artikelnr</th>
              <th>Omschrijving</th>
              <th>Prijs enkel</th>
              <th>Aantal</th>
              <th>Prijs</th>
            </tr>
          </thead>
          <tbody>
            {artikelen && artikelen.map((artikel, index) => {
              const artikelPrijs = parseFloat(artikel.verkoop_prijs ?? '0.00');
              const fixedArtikelPrijs = artikelPrijs - artikelPrijs * (originalVerkoopprijs.kortingPercentage ?? 0);
              return (
                <tr
                  key={index}
                  className={artikel.pivot.aantal > 0 ? '' : 'table-secondary strikeout text-muted'}
                >
                  <td className="align-middle">
                    <InspectButton
                      to={`/artikelen/show/${artikel.artikelnr}`}
                      variant={'outline-primary'}
                    >#{artikel.artikelnr}</InspectButton>
                  </td>
                  <td className="align-middle">
                    <TruncateTooltip
                      text={artikel.omschrijving}
                      threshold={20}
                    />
                  </td>
                  <td className="align-middle">
                    {hasFixedPrijs && (
                      <>
                        <span>
                          &euro; {formatCurrency(fixedArtikelPrijs)}
                        </span>
                        <span className="text-muted small">{' / '}</span>
                      </>
                    )}
                    <span
                      className={hasFixedPrijs ? `text-muted small` : ''}
                    >&euro; {formatCurrency(artikelPrijs)}</span>
                  </td>
                  <td className="align-middle">{artikel.pivot.aantal}</td>
                  <td className="align-middle">
                    {hasFixedPrijs && (
                      <>
                        <span>
                          &euro; {formatCurrency(fixedArtikelPrijs * artikel.pivot.aantal)}
                        </span>
                        <span className="text-muted small">{' / '}</span>
                      </>
                    )}
                    <span
                      className={hasFixedPrijs ? `text-muted small` : ''}
                    >&euro; {formatCurrency(artikelPrijs * artikel.pivot.aantal)}</span>
                  </td>
                </tr>
              )
            })}
            {btwRows && btwRows.map((btwRow, i) => {
              return (
                <tr key={`btwRow-${i}`}>
                  <td colSpan={colSpan}></td>
                  <td className="text-muted align-middle">{btwRow.label}</td>
                  <td className="align-middle">
                    <span><em>&euro; {btwRow.final}</em></span>
                    {hasFixedPrijs && (
                      <>
                        <span className="text-muted small">
                          {' / '} <em>&euro; {btwRow.calculated}</em>
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            <tr className="table-secondary">
              <th colSpan={colSpan}></th>
              <th>Totaal</th>
              <th>
                <span>&euro; {verkoopprijs.totaalExcl}</span>
                {hasFixedPrijs && (
                  <>
                    <span className="text-muted small">
                      {' / '} &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalExcl))}
                    </span>
                  </>
                )}
                <hr />
                <span>&euro; {verkoopprijs.totaalIncl}</span>
                {hasFixedPrijs && (
                  <>
                    <span className="text-muted small">
                      {' / '} &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalIncl))}
                    </span>
                  </>
                )}
              </th>
            </tr>
          </tbody>
        </Table>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default SamenstellingCard;