import React, { Fragment } from 'react';
import { Table, Button, ButtonGroup } from 'react-bootstrap';
import apiService from '../../Services/VrachtbriefService';
import { FaFileDownload, FaPrint } from 'react-icons/fa';
import InspectButton from '../../Helpers/InspectButton';

const VrachtbriefTable = ({ vrachtbrieven }) => {
  return (
    <Table bordered>
      <thead className="thead-dark">
        <tr>
          <th>Leverdatum</th>
          <th>Mededeling</th>
          <th>Klant</th>
          <th>Acties</th>
        </tr>
      </thead>
      <tbody>
        {vrachtbrieven?.map((brief, index) => {
          const leverdatum = new Date(brief.leverdatum);
          const formatter = new Intl.DateTimeFormat('nl-nl', {
            day: '2-digit',
            month: "2-digit",
            year: "numeric"
          });
          return (
            <Fragment key={index}>
              <tr>
                <td className="align-middle">{formatter.format(leverdatum)}</td>
                <td className="align-middle">{brief.mededeling ?? '-'}</td>
                <td className="align-middle">
                  <InspectButton
                    to={`/verkooporders/edit/${brief.klant.nummer}`}
                  >#{brief.klant.bedrijfsnaam}</InspectButton>
                </td>
                <td className="align-middle">
                  <ButtonGroup>
                    <InspectButton
                      to={`/verkooporders/edit/${brief.verkooporder.ordernummer}`}
                      variant="outline-secondary"
                    >#{brief.verkooporder.ordernummer}</InspectButton>
                    <Button
                      size="sm"
                      onClick={() => apiService.downloadPDF(brief.id, `Vrachtbrief-${brief.verkooporder.ordernummer}.pdf`)}
                    ><FaFileDownload /></Button>
                    <Button
                      size="sm"
                      onClick={() => apiService.printPDF(brief.id)}
                    ><FaPrint /></Button>
                  </ButtonGroup>
                </td>
              </tr>
              <tr>
                <th className="align-middle">Pallets</th>
                <td colSpan={3} className="align-middle">
                  {brief.pallets.map((pallet, palletIndex) => {
                    return (
                      <Fragment key={palletIndex}>
                        <ButtonGroup>
                          <Button
                            size="sm"
                            variant="dark"
                            disabled
                          >{pallet.id}</Button>
                          <Button
                            size="sm"
                            variant="outline-dark"
                            disabled
                          >Locatie @ {pallet.locatie}</Button>
                        </ButtonGroup>
                        {' '}
                      </Fragment>
                    )
                  })}
                </td>
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </Table>

  )
};

export default VrachtbriefTable;