import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';

const BodyTitleBar = ({ title, btnTitle, btnTo, children }) => {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">{title}</h1>
      {btnTo && btnTitle ? (<>
        <div className="btn-toolbar mb-2 mb-md-0">
          <LinkContainer exact to={btnTo}>
            <Nav.Link className="btn btn-sm btn-outline-primary">
              {btnTitle}
            </Nav.Link>
          </LinkContainer>
        </div>
      </>) : (<></>)}
      {children}
    </div>
  );
}
export default BodyTitleBar;