import React from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Card, ListGroup, Button, ButtonGroup } from 'react-bootstrap';
import { FaCopy, FaEdit, FaTimes } from 'react-icons/fa';
import { formatCurrency } from '../../Helpers/Helpers';
import InspectButton from '../../Helpers/InspectButton';
import ButtonTooltip from '../../Helpers/ButtonTooltip';

const ArtikelListCardInfo = ({ artikel, onClose }) => {
  let history = useHistory();
  const listGroupClass = "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center";
  if (!artikel) {
    return (
      <Card
        className="bg-info sticky-top text-white"
      >
        <Card.Header>
          Artikel informatie
        </Card.Header>
        <Card.Body>
          <p>Voor meer informatie, selecteer een artikel links in de tabel.</p>
        </Card.Body>
      </Card>
    )
  }
  return (
    <Card>
      <Card.Header
        className={`bg-dark text-light ${listGroupClass}`}
      >
        <span>Artikel informatie</span>
        <span>
          <ButtonGroup>
            <Button
              variant="danger"
              onClick={() => history.push(`/artikelen/edit/${artikel.artikelnr}`)}
            ><FaEdit /></Button>
            <ButtonTooltip
              variant="secondary"
              text="Dupliceer. Gebruik als basis voor een nieuw artikel."
              onClick={() => history.push(`/artikelen/add/${artikel.artikelnr}`)}
            ><FaCopy /></ButtonTooltip>
          </ButtonGroup>
          {onClose && (
            <span
              onClick={onClose}
              className="ml-3"
              style={{ cursor: 'pointer' }}
            ><FaTimes /></span>
          )}
        </span>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item className={listGroupClass}>
          <strong>Artikelnummer</strong>
          <InspectButton
            variant="link"
            to={`/artikelen/edit/${artikel.artikelnr}`}
          >#{artikel.artikelnr}</InspectButton>
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClass}>
          <strong>Omschrijving</strong>
          <span>{artikel.omschrijving}</span>
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClass}>
          <strong>Verkoopprijs</strong>
          <span>&euro; {formatCurrency(`${artikel.verkoop_prijs}`)}</span>
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClass}>
          <strong>Leverancier</strong>
          <InspectButton
            variant="link"
            to={`/leveranciers/edit/${artikel.leverancier.nummer}`}
          >{artikel.leverancier.naam ?? <>#{artikel.leverancier.nummer}</>}</InspectButton>
        </ListGroup.Item>

        <ListGroup.Item className={listGroupClass}>
          <strong>QR-code</strong>
          <span><QRCode value={artikel.artikelnr} /></span>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default ArtikelListCardInfo;