import ajax from '../Helpers/http-common';

const csrf = () => {
  return ajax.get('/sanctum/csrf-cookie');
};

const register = (data, config) => {
  return ajax.post('/api/auth/register', data);
}

const login = (email, password) => {
  return ajax.post('/api/auth/login', {
    email,
    password
  });
}
const logout = (config) => {
  return ajax.post('/api/auth/logout', null, config);
}

export default {
  csrf,
  register,
  login,
  logout
};