import React, { forwardRef, useRef, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';

const TableCheckbox = forwardRef(({ indeterminate, ...rest}, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <FormCheck
        ref={resolvedRef}
        {...rest}
      />
    </>
  );
});

export default TableCheckbox;