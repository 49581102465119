

import BrightSquares from '../../images/patterns/bright_squares.png';
import Cubes from '../../images/patterns/cubes.png';
import DotGrid from '../../images/patterns/dot-grid.png';
import Email from '../../images/patterns/email-pattern.png';
import Geometry from '../../images/patterns/geometry.png';
import Geometry2 from '../../images/patterns/geometry2.png';
import GPlay from '../../images/patterns/gplaypattern.png';
import Grey from '../../images/patterns/grey.png';
import LightWool from '../../images/patterns/light_wool.png';
import MemphisColorful from '../../images/patterns/memphis-colorful.png';
import Plaid from '../../images/patterns/plaid.png';
import ProjectPapper from '../../images/patterns/project_papper.png';
import RoughCloth from '../../images/patterns/roughcloth.png';
import Seigaiha from '../../images/patterns/seigaiha.png';
import SoWhite from '../../images/patterns/so-white.png';
import Sports from '../../images/patterns/sports.png';
import SquareBG from '../../images/patterns/square_bg.png';
import SubtleDots from '../../images/patterns/subtle_dots.png';
import Symfony from '../../images/patterns/symphony.png';
import TinyGrid from '../../images/patterns/tiny_grid.png';
import Trees from '../../images/patterns/trees.png';
import Vichy from '../../images/patterns/vichy.png';
import WornDots from '../../images/patterns/worn_dots.png';
import XV from '../../images/patterns/xv.png';

const BrightSquaresPattern = {
  pattern: BrightSquares,
  name: 'bright_squares',
  display_name: 'Bright squares',
};

const CubesPattern = {
  pattern: Cubes,
  name: 'cubes',
  display_name: 'Cubes',
};
const DotGridPattern = {
  pattern: DotGrid,
  name: 'dot-grid',
  display_name: 'Dot grid',
};
const EmailPattern = {
  pattern: Email,
  name: 'email-pattern',
  display_name: 'Email',
};
const GeometryPattern = {
  pattern: Geometry,
  name: 'geometry',
  display_name: 'Geometry',
};
const Geometry2Pattern = {
  pattern: Geometry2,
  name: 'geometry2',
  display_name: 'Geometry 2',
};
const GPlayPattern = {
  pattern: GPlay,
  name: 'gplaypattern',
  display_name: 'Gplay',
};
const GreyPattern = {
  pattern: Grey,
  name: 'grey',
  display_name: 'Grey',
};
const LightWoolPattern = {
  pattern: LightWool,
  name: 'light_wool',
  display_name: 'Light wool',
};
const MemphisColorfulPattern = {
  pattern: MemphisColorful,
  name: 'memphis-colorful',
  display_name: 'Memphis colorful',
};
const PlaidPattern = {
  pattern: Plaid,
  name: 'plaid',
  display_name: 'Plaid',
};
const ProjectPapperPattern = {
  pattern: ProjectPapper,
  name: 'project_papper',
  display_name: 'Project papper',
};
const RoughClothPattern = {
  pattern: RoughCloth,
  name: 'roughcloth',
  display_name: 'Rough cloth',
};
const SeigaihaPattern = {
  pattern: Seigaiha,
  name: 'seigaiha',
  display_name: 'Seigaiha',
};
const SoWhitePattern = {
  pattern: SoWhite,
  name: 'so-white',
  display_name: 'So white',
};
const SportsPattern = {
  pattern: Sports,
  name: 'sports',
  display_name: 'Sports',
};
const SquareBGPattern = {
  pattern: SquareBG,
  name: 'square_bg',
  display_name: 'Square bg',
};
const SubtleDotsPattern = {
  pattern: SubtleDots,
  name: 'subtle_dots',
  display_name: 'Subtle dots',
};
const SymfonyPattern = {
  pattern: Symfony,
  name: 'symphony',
  display_name: 'Symphony',
};
const TinyGridPattern = {
  pattern: TinyGrid,
  name: 'tiny_grid',
  display_name: 'Tiny grid',
};
const TreesPattern = {
  pattern: Trees,
  name: 'trees',
  display_name: 'Trees',
};
const VichyPattern = {
  pattern: Vichy,
  name: 'vichy',
  display_name: 'Vichy',
};
const WornDotsPattern = {
  pattern: WornDots,
  name: 'worn_dots',
  display_name: 'Worn dots',
};
const XVPattern = {
  pattern: XV,
  name: 'xv',
  display_name: 'XV',
};
const NoPattern = {
  pattern: null,
  name: 'none',
  display_name: 'Geen patroon',
};

const background_patterns = {
  NoPattern,
  BrightSquaresPattern,
  CubesPattern,
  DotGridPattern,
  EmailPattern,
  GeometryPattern,
  Geometry2Pattern,
  GPlayPattern,
  GreyPattern,
  LightWoolPattern,
  MemphisColorfulPattern,
  PlaidPattern,
  ProjectPapperPattern,
  RoughClothPattern,
  SeigaihaPattern,
  SoWhitePattern,
  SportsPattern,
  SquareBGPattern,
  SubtleDotsPattern,
  SymfonyPattern,
  TinyGridPattern,
  TreesPattern,
  VichyPattern,
  WornDotsPattern,
  XVPattern,
}

export default background_patterns;