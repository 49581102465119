import React from 'react';
import { Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router';

const InspectButton = ({
  variant = 'link',
  to = '/404',
  external = false,
  icon = <FaSearch />,
  size = 'sm',
  iconPosition = 'left',
  children,
  onCustomClick = null,
  ...props
}) => {
  let history = useHistory();
  return (
    <Button
      size={size}
      variant={variant}
      onClick={() => {
        if (onCustomClick) {
          onCustomClick();
        } else {
          history.push(to);
        }
      }}
      {...props}
    >
      {iconPosition === 'left' && <>{icon}{' '}</>}
      {children}
      {iconPosition === 'right' && <>{' '}{icon}</>}
    </Button>
  );
};

export default InspectButton;