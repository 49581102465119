import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="NotFound text-center">
      <span className="exclamation"><FaExclamation /></span>
      <h3><strong>Er is iets misgegaan</strong></h3>
      <h5>De pagina is niet gevonden</h5>
    </div>
  )
}

export default NotFound;