import { FaEdit } from "react-icons/fa";
import { formatCurrency } from "../../../Helpers/Helpers";
import InspectButton from "../../../Helpers/InspectButton";

const tableColumns = [
  // {
  //   Header: '#id',
  //   accessor: 'id',
  //   sortType: 'basic',
  //   id: 'id'
  // },
  {
    Header: 'Pakket naam',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/pakketten/samenstellen/${row.id}`}
            variant="outline-danger"
            icon={<FaEdit />}
          >{row.naam}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'naam'
  },
  {
    Header: 'Totaal prijs excl.',
    accessor: (row, i) => {
      if (row.verkoopprijs_totaal === null) {
        return (
          <>&euro; 0,00</>
        )
      }
      return (
        <>&euro; {formatCurrency(row.verkoopprijs_totaal)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal'
  },
  {
    Header: '# Artikelen',
    accessor: (row, i) => {
      const aantalArtikelen = row.artikelen?.reduce((sum, x) => sum + parseInt(x.pivot.aantal), 0);
      return (
        <>
          {aantalArtikelen}
        </>
      )
    },
    sortType: 'basic',
    id: 'artikelen_aantal'
  },
  {
    Header: '# Offertes',
    accessor: (row, i) => {
      return (
        <>
          {
            row.offertes
              ? row.offertes.length > 0
                ? row.offertes.length
                : '-'
              : '-'
          }
        </>
      )
    },
    sortType: 'basic',
    id: 'offertes_aantal'
  },
  {
    Header: '# Verkooporders',
    accessor: (row, i) => {
      return (
        <>
          {
            row.verkooporders
              ? row.verkooporders.length > 0
                ? row.verkooporders.length
                : '-'
              : '-'
          }
        </>
      )
    },
    sortType: 'basic',
    id: 'verkooporders_aantal'
  },
  {
    Header: 'Klantnummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/klanten/edit/${row.klant.nummer}`}
            variant="link"
          >#{row.klant.nummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'klantnummer'
  },
  {
    Header: 'Klant',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/klanten/edit/${row.klant.nummer}`}
            variant="link"
          >{row.klant.bedrijfsnaam}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'klant_bedrijfsnaam'
  },
  {
    Header: 'Totaal prijs incl.',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.verkoopprijs_totaal_incl)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_incl'
  },
  {
    Header: 'Prijs BTW Hoog',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.verkoopprijs_totaal_btw_hoog)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_hoog'
  },
  {
    Header: 'Prijs BTW Laag',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.verkoopprijs_totaal_btw_laag)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_laag'
  },
  {
    Header: 'Prijs BTW Nul',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(row.verkoopprijs_totaal_btw_nul)}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_nul'
  },
];

export default tableColumns;