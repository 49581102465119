import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

import inkooporderStatuses from '../../data/inkooporderStatuses';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/InkoopService';
import DropdownActionsPurchaseOrder from './DropdownActionsPurchaseOrder';

const InkooporderStatusTd = ({ status }) => {
  const [currentStatus, setStatus] = useState(inkooporderStatuses.default);

  useEffect(() => {
    for (const [key, value] of Object.entries(inkooporderStatuses)) {
      if (key === status) {
        setStatus(value);
        break;
      }
    }
  }, [status]);

  return (
    <td
      className={`table-${currentStatus.variant}`}
    >
      {currentStatus.icon}
    </td>
  );
};

const InkooporderHistoryTable = ({ inkooporders }) => {
  let history = useHistory();

  return (
    <Table>
      <thead className="thead-dark">
        <tr>
          <th>#</th>
          <th>Leverancier</th>
          <th>Orderdatum</th>
          <th>Status</th>
          <th>Handelingen</th>
        </tr>
      </thead>
      <tbody>
        {inkooporders && inkooporders.map((io, index) => {
          return (
            <tr key={index}>
              <td>
                <Link
                  to={`/inkoop/order/${io.ordernummer}`}
                >{io.ordernummer}</Link>
              </td>
              <td>
                <Link
                  to={`/leveranciers/edit/${io.leverancier.nummer}`}
                >{io.leverancier.naam}</Link>
              </td>
              <td>{io.orderdatum}</td>
              <InkooporderStatusTd status={io.status} />

              <td>
                <Button
                  variant={'danger'}
                  onClick={() => history.push(`/inkoop/order/${io.ordernummer}`)}
                ><FaEdit /></Button>
                {" "}
                <DropdownActionsPurchaseOrder purchaseOrder={io} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  )
};

const InkooporderHistoryList = () => {

  const [inkooporders, setInkooporders] = useState([]);

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.getAllHistory()
      .then(response => {
        setInkooporders(response.data.reverse());
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Container fluid>
      <BodyTitleBar title="Inkooporders"/>
      <Row>
        <Col>
          <InkooporderHistoryTable inkooporders={inkooporders} />
        </Col>
      </Row>
    </Container>
  );
};

export default InkooporderHistoryList;