import React from 'react';
import { Container} from 'react-bootstrap';

import BodyTitleBar from '../Helpers/Layouts/BodyTitleBar';

const Test = () => {
  return (
    <Container fluid>
      <BodyTitleBar title="Test page" />
    </Container>
  );
};
export default Test;