import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import axios, { isCancel } from 'axios';
import {
  Button,
  Container,
  ButtonGroup,
  Form,
  Badge,
  Col,
  InputGroup,
  Row,
} from 'react-bootstrap';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import apiService from '../../Services/ArtikelService';
import LeverancierService from '../../Services/LeverancierService';
import ArtikelMutations from './ArtikelMutations';

import useEventListener from '../../Helpers/Hooks/useEventListener';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import { formatCurrency, serverPriceFormatToEURFormat } from '../../Helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight, faEdit, faCopy } from '@fortawesome/free-solid-svg-icons';
import MutateStock from '../Mutations/MutateStock';
import StockLocationDetails from '../Mutations/StockLocationDetails';
import locationQuantityReducer from '../../Helpers/Reducers/locationQuantityReducer';
import filledLocationFilter from '../../Helpers/Filters/filledLocationFilter';

const ShowArtikel = () => {
  const initialArtikelState = {
    id: null,
    artikelnr: '',
    omschrijving: '',
    inkoop_prijs: '',
    btw: 2,
    besteleenheid: 'ST',
    inhoud: 1,
    leverancier_artikel_nummer: '',
    leveranciers_nummer: '',
    inpakopdrachten: [],
    pakketten: [],
    verkooporders: [],
    offertes: [],
    inkooporders: [],
    nodig_mutations: [],
    voorraad_mutations: [],
    inkoop_mutations: [],
    breuk_mutations: []
  };

  const initialVerkoopprijs = {
    excl: '0,00',
    incl: '0,00'
  };

  const [leveranciers, setLeveranciers] = useState([]);
  const [artikel, setArtikel] = useState(initialArtikelState);
  const [surrounding, setSurrounding] = useState({
    next: null,
    previous: null
  });
  const [pressedKeys, setPressedKeys] = useState([]);

  const [errors, setErrors] = useState({});
  const [verkoopprijs, setVerkoopprijs] = useState(initialVerkoopprijs);
  const [locationsWithQuantity, setLocationsWithQuantity] = useState([]);

  const {
    notifications,
    addGenericErrorNotification,
    addErrorNotification,
    removeNotifications
  } = useAlertNotifications();

  let { artikelnr } = useParams();
  let history = useHistory();

  useEffect(() => {
    if (pressedKeys.includes('Escape') || pressedKeys.includes('27')) {
      removeNotifications();
    }
    if (pressedKeys.includes('Shift') && pressedKeys.includes('ArrowLeft')) {
      goToPreviousArtikel();
    }
    if (pressedKeys.includes('Shift') && pressedKeys.includes('ArrowRight')) {
      goToNextArtikel();
    }
  }, [pressedKeys]);

  const updateStockMutations = (mutations) => {
    setArtikel(prevState => {
      return {
        ...prevState,
        voorraad_mutations: mutations,
      };
    });

    setLocationsWithQuantity(Object.entries(mutations.reduce(locationQuantityReducer, {})).filter(filledLocationFilter))
  };

  const keyDownHandler = ({ key }) => {
    setPressedKeys([
      ...pressedKeys,
      String(key)
    ]);
  }

  const goToNextArtikel = () => {
    if (surrounding.next !== null) {
      history.push(`/artikelen/show/${surrounding.next}`);
    } else {
      addErrorNotification('Er is geen volgende artikel om naar toe te gaan.', 'Geen volgende artikel gevonden');
    }
  }

  const goToPreviousArtikel = () => {
    if (surrounding.previous !== null) {
      history.push(`/artikelen/show/${surrounding.previous}`);
    } else {
      addErrorNotification('Er is geen vorige artikel om naar toe te gaan.', 'Geen vorige artikel gevonden');
    }
  }


  const keyUpHandler = ({ key }) => {
    setPressedKeys(pressedKeys.filter(pk => pk !== String(key)));
  }

  useEventListener('keydown', keyDownHandler);
  useEventListener('keyup', keyUpHandler);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setArtikel({ ...artikel, [name]: value });
    resetFormError(name);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async (prijs, btw) => {
      apiService.getCalculatedVerkoopprijs(prijs, btw, 1, { cancelToken: source.token })
        .then(response => {
          let excl = response.data.verkoopprijs.excl;
          let incl = response.data.verkoopprijs.incl;
          if (!isNaN(excl)) {
            excl = formatCurrency(excl);
          } else {
            excl = '0,00';
          }
          if (!isNaN(incl)) {
            incl = formatCurrency(incl);
          } else {
            incl = '0,00';
          }

          setVerkoopprijs({
            excl: excl,
            incl: incl
          });
          if (isCancel(response)) return;
        })
        .catch(e => {
          if (isCancel(e)) return;
          if (e.response?.status === 500) {
            addGenericErrorNotification(e);
          }
        });
    };

    if (artikel.btw && artikel.inkoop_prijs) {
      const prijs = `${artikel.inkoop_prijs}`.replace(/,/g, '.');
      fetchData(prijs, artikel.btw);
    }

    return () => {
      setVerkoopprijs(initialVerkoopprijs);
      source.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikel.btw, artikel.inkoop_prijs]);

  const resetFormError = (field) => {
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    });
  };

  useEffect(() => {
    retrieveLeveranciers();
  }, []);

  useEffect(() => {
    if (!artikelnr) {
      addErrorNotification('Er is geen artikel om te bekijken.', null, false);
      return;
    }
    apiService.get(artikelnr)
      .then(response => {
        setArtikel({
          ...response.data,
          inkoop_prijs: serverPriceFormatToEURFormat(response.data.inkoop_prijs)
        });
      })
      .catch(e => {
        addGenericErrorNotification(e);
        setArtikel({
          ...artikel,
          artikelnr: artikelnr
        });
      });

    apiService.getSurrounding(artikelnr)
      .then(response => {
        setSurrounding({
          next: response.data.next,
          previous: response.data.previous
        })
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelnr]);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (artikel.artikelnr !== '') {
        setArtikel({
          ...initialArtikelState,
          artikelnr: artikel.artikelnr,
        });
        history.push(`/artikelen/show/${artikel.artikelnr}`);
      }
    }
  };

  const retrieveLeveranciers = () => {
    LeverancierService.getAll()
      .then(response => {
        setLeveranciers(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  return (
    <>
      <Container fluid className="edit-form">
        <BodyTitleBar title={`Artikel #${artikel.artikelnr}`}>
          {artikel && (
            <ButtonGroup>
              <Button
                variant="danger"
                disabled={!artikel.id}
                onClick={() => history.push(`/artikelen/edit/${artikel.artikelnr}`)}
              ><FontAwesomeIcon icon={faEdit} /> Bewerken</Button>
              <Button
                variant="outline-secondary"
                disabled={!artikel.id}
                onClick={() => history.push(`/artikelen/add/${artikel.artikelnr}`)}
              ><FontAwesomeIcon icon={faCopy} /> Dupliceren</Button>
            </ButtonGroup>
          )}
        </BodyTitleBar>
        <AlertNotifications
          notifications={notifications}
          removeNotifications={removeNotifications}
        />
          <>
            <Form.Group as={Form.Row}>
              <Col sm={5} md={4}>
                <Form.Label>Artikelnummer <Badge variant="info">Intern</Badge></Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button
                      disabled={surrounding.previous === null}
                      variant="outline-secondary"
                      onClick={() => goToPreviousArtikel()}
                    ><FontAwesomeIcon icon={faArrowLeft} /></Button>
                    <Button
                      disabled={surrounding.next === null}
                      variant="outline-secondary"
                      onClick={() => goToNextArtikel()}
                    ><FontAwesomeIcon icon={faArrowRight} /></Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    noValidate
                    type="text"
                    className="form-control"
                    id="artikelnr"
                    value={artikel.artikelnr}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    name="artikelnr"
                    isInvalid={!!errors.artikelnr}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.artikelnr}
                  </Form.Control.Feedback>
                  <InputGroup.Append>
                    <Button
                      variant="outline-primary"
                      onClick={() => history.push(`/artikelen/show/${artikel.artikelnr}`)}
                    ><FontAwesomeIcon icon={faSearch} /></Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col sm={7} md={7}>
                <Form.Label>Omschrijving</Form.Label>
                <Form.Control
                  type="text"
                  id="omschrijving"
                  value={artikel.omschrijving}
                  name="omschrijving"
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Form.Row}>
              <Col sm={3}>
                <Form.Label>Inkoopprijs</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>&euro;</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    id="inkoop_prijs"
                    required
                    value={artikel.inkoop_prijs}
                    name="inkoop_prijs"
                    isInvalid={!!errors.inkoop_prijs}
                    autoComplete="off"
                    disabled
                    placeholder='0,00'
                  />
                </InputGroup>
              </Col>
              <Col sm={3}>
                <Form.Label>Verkoopprijs excl.</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>&euro;</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    disabled
                    value={verkoopprijs.excl}
                  />
                </InputGroup>
              </Col>
              <Col sm={3}>
                <Form.Label>Verkoopprijs incl.</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>&euro;</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    disabled
                    value={verkoopprijs.incl}
                  />
                </InputGroup>
              </Col>
              <Col sm={3}>
                <Form.Label>BTW</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="btw"
                    value={artikel.btw}
                    name="btw"
                    disabled
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>BTW-groep</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
            </Form.Group>
            <Form.Group as={Form.Row}>
              <Col md={3}>
                <Form.Label>Leveranciersnummer</Form.Label>
                <Form.Control
                  type="text"
                  id="leveranciers_nummer"
                  value={artikel.leveranciers_nummer}
                  disabled
                  name="leveranciers_nummer"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.leveranciers_nummer}
                </Form.Control.Feedback>
              </Col>
              <Col md={9}>
                <Form.Label>Leverancier</Form.Label>
                <Form.Control
                  type="text"
                  id="leveranciers_nummer"
                  value={leveranciers.filter(leverancier => leverancier.nummer === artikel.leveranciers_nummer)[0]?.naam}
                  disabled
                  name="leveranciers_nummer"
                />
              </Col>
            </Form.Group>
            <Form.Group>
            </Form.Group>
            <Form.Group as={Form.Row}>
              <Col>
                <Form.Label>Artikelnummer <Badge variant="info">Leverancier</Badge></Form.Label>
                <Form.Control
                  type="text"
                  id="leverancier_artikel_nummer"
                  required
                  value={artikel.leverancier_artikel_nummer}
                  name="leverancier_artikel_nummer"
                  disabled
                />
              </Col>
            </Form.Group>
            <Form.Group as={Form.Row}>
              <Col md={2}>
                <Form.Label>Besteleenheid</Form.Label>
                <Form.Control
                  type='text'
                  id="besteleenheid"
                  value={artikel.besteleenheid}
                  name="besteleenheid"
                  disabled
                />
              </Col>
              <Col md={10}>
                <Form.Label>Inhoud / Aantal</Form.Label>
                <Form.Control
                  type="text"
                  id="inhoud"
                  value={artikel.inhoud}
                  name="inhoud"
                  disabled
                />
              </Col>
            </Form.Group>
            {artikel.id && (
              <>
                <Row>
                  <Col md={2}>
                    Voorraad {' '}
                    <MutateStock
                      product={artikel}
                      mutations={artikel.voorraad_mutations}
                      updateMutations={updateStockMutations}
                      locationsWithQuantity={locationsWithQuantity}
                      setLocationsWithQuantity={setLocationsWithQuantity}
                    />
                  </Col>
                  <Col>
                    {locationsWithQuantity.map((entry, index) =>
                      <StockLocationDetails key={index} location={entry[0] ?? null} quantity={entry[1] ?? null}/>
                    )}
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <ArtikelMutations
                  nodigMutations={artikel.nodig_mutations}
                  inkoopMutations={artikel.inkoop_mutations}
                  voorraadMutations={artikel.voorraad_mutations}
                  breukMutations={artikel.breuk_mutations}
                />
              </>
            )}
          </>
      </Container>
    </>
  );
};

export default ShowArtikel;