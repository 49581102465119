import React from 'react';
import {
  FaArchive,
  FaGifts,
  FaHome,
  FaTruck,
  FaUserFriends,
  FaHandshake,
  FaBarcode,
  FaHistory,
  FaWarehouse,
  FaTasks,
  FaBoxOpen,
  FaListUl,
  FaPallet,
  FaEuroSign,
  FaPlus,
  FaTruckLoading,
  FaImage
} from 'react-icons/fa';

const menuItems = [
  {
    groupName: null,
    slug: null,
    items: [
      {
        pathTo: '/',
        label: 'Dashboard',
        icon: <FaHome />,
        exact: true
      }
    ]
  },
  {
    groupName: 'Verkoop',
    slug: 'verkoop',
    items: [
      {
        label: "Verkooporders",
        icon: <FaHandshake />,
        pathTo: "/verkooporders"
      },
      {
        label: "Pakketten",
        icon: <FaGifts />,
        pathTo: "/pakketten"
      },
      {
        label: "Offertes",
        icon: <FaArchive />,
        pathTo: "/offertes"
      }
    ]
  },
  {
    groupName: 'Beheer',
    slug: 'beheer',
    items: [
      {
        pathTo: '/artikelen',
        label: 'Artikelen',
        icon: <FaBarcode />
      },
      {
        pathTo: '/klanten',
        label: 'Klanten',
        icon: <FaUserFriends />,
        subItems: [
          {
            pathTo: '/klanten/add',
            label: 'Nieuwe klant toevoegen',
            icon: <FaPlus />,
            position: 'right',
          }
        ]
      },
      {
        pathTo: '/leveranciers',
        label: 'Leveranciers',
        icon: <FaTruck />
      }
    ]
  },
  {
    groupName: 'Magazijn',
    slug: 'magazijn',
    items: [
      {
        pathTo: '/besteladvies',
        label: 'Besteladvies',
        icon: <FaEuroSign />
      },
      {
        pathTo: '/inkoop',
        label: 'Inkooporders',
        icon: <FaHistory />
      },
      {
        pathTo: '/voorraad',
        label: 'Voorraad',
        icon: <FaWarehouse />
      },
      {
        pathTo: '/pallets',
        label: 'Pallets',
        icon: <FaPallet />
      }
    ]
  },
  {
    groupName: 'Planning',
    slug: 'planning',
    items: [
      {
        pathTo: '/planning/orders-op-voorraad',
        label: 'Orders op voorraad',
        icon: <FaTasks />
      },
      {
        pathTo: '/planning/orders-niet-op-voorraad',
        label: 'Orders niet op voorraad',
        icon: <FaListUl />
      },
      // {
      //   pathTo: '/planning/orders-incompleet',
      //   label: 'Orders \'incompleet\'',
      //   icon: <FaExclamationTriangle />
      // },
      {
        pathTo: '/planning/inpakopdrachten',
        label: 'Inpakopdrachten',
        icon: <FaBoxOpen />
      },
      {
        pathTo: '/planning/vrachtbrieven',
        label: 'Vrachtbrieven',
        icon: <FaTruckLoading />
      }
    ]
  },
  {
    groupName: 'Instellingen',
    slug: 'config',
    items: [
      {
        pathTo: '/config/sidebar',
        icon: <FaImage />,
        label: 'Achtergrond'
      }
    ]
  },

];

export default menuItems;