import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Form, Col, FormControl } from 'react-bootstrap';
import { FaRegCopy, FaCopy, FaRegArrowAltCircleLeft } from 'react-icons/fa';
import useShowing from '../../Helpers/Hooks/useShowing';
import GlobalModal from '../../Helpers/PaginatedTable/GlobalModal';

const DuplicatePakketModal = ({
  pakket,
  klanten,
  variant = "primary",
  isDropdownItem = true,
  onSubmit,
  disabled = false
}) => {
  const { isShowing, toggle } = useShowing();
  const [newPakket, setNewPakket] = useState({ ...pakket});
  const [placeholderPakketnaam, setPlaceholderPakketnaam] = useState('...');
  const [currentPakket, setCurrentPakket] = useState(pakket);

  useEffect(() => {
    const klant = klanten?.find(k => k.nummer === newPakket.klantnummer);

    setPlaceholderPakketnaam(klant ? klant.bedrijfsnaam : '...');
    setNewPakket({ ...newPakket, ['naam']: klant ? klant.bedrijfsnaam : '' });
    if (klant) {
      setNewPakket(prevState => {
        return {
          ...prevState,
          klant: klant
        }
      });
    }
  }, [newPakket.klantnummer]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setNewPakket({ ...newPakket, [name]: value });
  };

  const handleSubmit = () => {
    const data = {
      klantnummer: newPakket.klantnummer,
      naam: newPakket.naam
    };
    if (onSubmit) {
      onSubmit(data);
    }
    toggle(!isShowing);
  };
  return (
    <>
      {isDropdownItem
        ? (
          <Dropdown.Item
            disabled={disabled}
            onClick={() => toggle(!isShowing)}
          ><FaCopy /> Dupliceer</Dropdown.Item>
        )
        : (
          <Button
            disabled={disabled}
            variant={variant}
            onClick={() => toggle(!isShowing)}
          ><FaCopy /> Dupliceer</Button>
        )
      }
      <GlobalModal
        isShowing={isShowing}
        hide={toggle}
        closeButton
        headerClass={'bg-primary text-light'}
        header={<span><FaRegCopy /> <strong>Pakket dupliceren</strong></span>}
        body={
          <>
            <Form>
              <Form.Group as={Form.Row}>
                <Form.Label column sm={2}>Naam</Form.Label>
                <Col sm={10}>
                  <FormControl
                    type="text"
                    placeholder={placeholderPakketnaam}
                    id="naam"
                    required
                    value={newPakket.naam}
                    onChange={handleInputChange}
                    name="naam"
                    autoComplete="off"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column md={2}>Klantnummer</Form.Label>
                <Col md={10}>
                  <Form.Control
                    type="text"
                    placeholder="...."
                    required
                    value={newPakket.klantnummer}
                    onChange={handleInputChange}
                    name="klantnummer"
                    autoComplete="off"
                  />
                  <Form.Control.Feedback
                    type="text"
                    className="text-muted"
                  >Origineel klantnummer: <strong><em>{currentPakket?.klantnummer}</em></strong></Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Form.Label column md={2}>Klant</Form.Label>
                <Col md={10}>
                  <Form.Control as="select"
                    custom
                    name="klantnummer"
                    onChange={handleInputChange}
                    value={newPakket.klantnummer}
                  >
                    <option value="" disabled>Kies een klant</option>
                    {klanten.map((klant) => {
                      return (
                        <option
                          key={klant.nummer}
                          value={klant.nummer}
                        >
                          {klant.bedrijfsnaam} {klant.nummer === currentPakket.klantnummer ? ' < Originele klant' : ''}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <Form.Control.Feedback
                    type="text"
                    className="text-muted"
                  >Originele klant: <strong><em>{currentPakket?.klant?.bedrijfsnaam}</em></strong></Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </>
        }
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => toggle(!isShowing)}
            >Sluit</Button>
            <Button
              variant="success"
              onClick={handleSubmit}
            ><FaCopy /> Dupliceren</Button>
          </>
        }
      />
    </>
  )
};

export default DuplicatePakketModal;