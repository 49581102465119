import React from 'react';
import {
  Button,
  Table,
  ButtonGroup,
  Alert,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';
import { formatCurrency } from '../../../Helpers/Helpers';
import InspectButton from '../../../Helpers/InspectButton';
import sortByProductNumber from '../../../Helpers/Sorts/sortByProductNumber';
import ConfirmDeleteAllArtikelenFromPakketModal from '../ConfirmDeleteAllArtikelenFromPakketModal';

export const ArtikelenTable = ({
  artikelen,
  pakketId,
  size = "md",
  onIncrement = () => { },
  onDecrement = () => { },
  onDetach = () => { }
}) => {
  return (
    <Table
      bordered
      striped
      size={size}
      responsive style={{ display: "table" }}
    >
      <thead className="thead-dark">
        <tr>
          <th>Artikelnr</th>
          <th>Omschrijving</th>
          <th>Prijs</th>
          <th>Aantal</th>
          <th>Totaal prijs</th>
          <th>Aanpassen</th>
        </tr>
      </thead>
      <tbody>
        {artikelen && artikelen.sort(sortByProductNumber).map((artikel, index) => {
            return (
              <tr key={index}>
                <td className="align-middle">
                  <InspectButton
                    to={`/artikelen/show/${artikel.artikelnr}`}
                    variant="outline-primary"
                  >#{artikel.artikelnr}</InspectButton>
                </td>
                <td className="align-middle">{artikel.omschrijving}</td>
                <td className="align-middle">&euro; {formatCurrency(artikel.verkoop_prijs)}</td>
                <td className="align-middle">{artikel.pivot.aantal}</td>
                <td className="align-middle">&euro; {formatCurrency(artikel.pivot.aantal * parseFloat(artikel.verkoop_prijs))}</td>
                <td className="align-middle">
                  <ButtonGroup>
                    <Button
                      size={size === 'sm' ? 'sm' : ''}
                      onClick={() => onIncrement(artikel.id, pakketId)}
                    ><FaPlus /></Button>
                    <Button
                      size={size === 'sm' ? 'sm' : ''}
                      disabled={artikel.pivot.aantal === 0}
                      onClick={() => onDecrement(artikel.id, pakketId)}
                    ><FaMinus /></Button>
                  </ButtonGroup>
                  {" "}
                  <Button
                    size={size === 'sm' ? 'sm' : ''}
                    variant="danger"
                    onClick={() => onDetach(pakketId, artikel.id)}
                  ><FaTrash /></Button>
                </td>
              </tr>
            )
        })}
      </tbody>
    </Table>
  )
};

const PakketSamenstellingTab = ({
  pakket,
  onDetach,
  onIncrement,
  onDecrement,
  switchToArtikelenTab,
  switchToQuickAddTab,
  detachAllArtikelen,
  detachAllUnusedArtikelen
}) => {
  const inactiveArtikelenTable = () => {
    const artikelen = pakket.artikelen.filter(a => a.pivot?.aantal === 0);
    return (
      <>
        {artikelen.length > 0
          ? (
            <>
              <Card>
                <Card.Header as="h6">Inactieve artikelen</Card.Header>
                <Card.Body style={{ padding: 0 }}>
                  <ArtikelenTable
                    size="md"
                    artikelen={artikelen}
                    onDecrement={onDecrement}
                    onIncrement={onIncrement}
                    onDetach={onDetach}
                    pakketId={pakket.id}
                  />
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    onClick={detachAllUnusedArtikelen}
                  ><FaTrash /> Verwijder inactief</Button>
                </Card.Footer>
              </Card>
            </>
          )
          : (
            <Alert variant="info">
              Er zijn geen inactieve artikelen.
            </Alert>
          )
        }
      </>
    );
  };

  const selectedArtikelenTable = () => {
    const artikelen = pakket.artikelen.filter(a => a.pivot?.aantal !== 0);
    return (
      <>
        {artikelen.length > 0
          ? (
            <>
              <Card>
                <Card.Header as="h6">Gekozen artikelen</Card.Header>
                <Card.Body style={{ padding: 0 }}>
                  <ArtikelenTable
                    size="md"
                    artikelen={artikelen}
                    onDecrement={onDecrement}
                    onIncrement={onIncrement}
                    onDetach={onDetach}
                    pakketId={pakket.id}
                  />
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  <ConfirmDeleteAllArtikelenFromPakketModal
                    onConfirm={detachAllArtikelen}
                    isDropdownItem={false}
                  />
                </Card.Footer>
              </Card>
            </>
          )
          : (
            <Alert variant="primary">
              <Alert.Heading>Informatie</Alert.Heading>
              <hr />
              <p>Er zijn nog geen artikelen gekozen voor dit pakket.</p>
              <div className="d-flex justify-content-end">
                <span>
                  <Button
                    variant="primary"
                    onClick={switchToArtikelenTab}
                  >Ga naar alle artikelen</Button>
                  {' '}
                  <Button
                    variant="success"
                    onClick={switchToQuickAddTab}
                  >Snel toevoegen</Button>
                </span>
              </div>
            </Alert>
          )
        }
      </>
    );
  };
  return (
    <>
      <Row>
        <Col>
          {selectedArtikelenTable()}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {inactiveArtikelenTable()}
        </Col>
      </Row>
    </>
  );
};

export default PakketSamenstellingTab;