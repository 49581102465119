import ajax, { api } from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';
import { getPaginatedUrl } from '../Helpers/Helpers';

const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl('/offertes', page, perPage, search, sort, operator);
};

const getAll = (page = 1, perPage = 10, searchKey = '', query = '', sortKey = null, sortDir = 'asc', operator = 'LIKE') => {
  const url = getAllUrl(page, perPage, { key: searchKey, query }, { key: sortKey, dir: sortDir }, operator);
  return ajax.get(url);
};

const getStatistics = () => {
  return ajax.get(`${api}/statistics/offertes`);
};

const getAllFromPakket = (pakketId) => {
  return ajax.get(`/offertes/pakket/${pakketId}`);
}

const getByOffertenummer = offertenummer => {
  return ajax.get(`${api}/offertes/${offertenummer}`)
}

const get = id => {
  return ajax.get(`/offertes/${id}`);
};

const create = data => {
  return ajax.post('/offertes', data);
};

const update = (id, data) => {
  return ajax.put(`/offertes/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/offertes/${id}`);
};

const removeAll = () => {
  return ajax.delete('/offertes');
};

const findBy = (key, value) => {
  return ajax.get(`/offertes/find/${key}/${value}`);
};

const getBase64PDF = (id) => {
  return ajax.get(`/offertes/pdf/base64/${id}`);
}

const downloadPDF = async (id, filename) => {
  const response = await ajax.get(`/offertes/pdf/download/${id}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id) => {
  try {
    const response = await getBase64PDF(id);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
}

const mutateArtikel = (id, data) => {
  return ajax.post(`${api}/offertes/${id}/mutate/artikel`, data);
};

const mutateArtikelRemove = (id, artikelId) => {
  return ajax.put(`${api}/offertes/${id}/mutate/artikel/${artikelId}`);
};

export default {
  getStatistics,
  getAllUrl,
  getAll,
  getAllFromPakket,
  get,
  getByOffertenummer,
  create,
  update,
  remove,
  removeAll,
  findBy,
  getBase64PDF,
  downloadPDF,
  printPDF,
  mutateArtikel,
  mutateArtikelRemove
};