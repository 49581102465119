import { getPaginatedUrl } from '../Helpers/Helpers';
import ajax, { api } from '../Helpers/http-common';

const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl('/pakketten', page, perPage, search, sort, operator);
};

const getAll = (page = 1, perPage = 10, searchKey = '', query = '', sortKey = null, sortDir = 'asc', operator = 'LIKE') => {
  return ajax.get('/pakketten');
};

const get = id => {
  return ajax.get(`/pakketten/${id}`);
};

const create = data => {
  return ajax.post('/pakketten', data);
};

const duplicate = (id, data) => {
  return ajax.post(`${api}/pakketten/${id}/duplicate`, data);
};

const update = (id, data) => {
  return ajax.put(`/pakketten/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/pakketten/${id}`);
};

const removeAll = () => {
  return ajax.delete('/pakketten');
};

const findBy = (key, value) => {
  return ajax.get(`/pakketten/find/${key}/${value}`);
};

const addArtikelToPakket = (id, data) => {
  return ajax.post(`/pakketten/${id}/attach`, data);
}
const detachArtikel = (pakketId, artikelId) => {
  return ajax.delete(`/pakketten/${pakketId}/detach/${artikelId}`);
}
const detachAllArtikelen = (pakketId) => {
  return ajax.delete(`/pakketten/detach/${pakketId}`);
}

const detachAllUnusedArtikelen = (pakketId) => {
  return ajax.delete(`/pakketten/detach/${pakketId}/unused`);
}

const getStatistics = () => {
  return ajax.get(`${api}/statistics/pakketten`);
};

export default {
  getStatistics,
  getAllUrl,
  getAll,
  get,
  create,
  update,
  duplicate,
  remove,
  removeAll,
  findBy,
  addArtikelToPakket,
  detachArtikel,
  detachAllArtikelen,
  detachAllUnusedArtikelen
};