import { useState, useEffect } from "react";
import { ListGroup, Button, ButtonGroup } from "react-bootstrap";
import { FaExclamationCircle, FaFileDownload, FaPrint, FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../Helpers/Helpers";
import AddVrachtbriefButton from "../../Vrachtbrieven/AddVrachtbriefButton";

import vrachtbriefApiService from "../../../Services/VrachtbriefService";
import InspectButton from "../../../Helpers/InspectButton";

const VerkooporderDetailsCard = ({
  verkooporder,
  originalVerkoopprijs,
  addSuccessNotification = () => {},
  addErrorNotification = () => {},
  refresh = () => {}
}) => {
  const listGroupClasses = 'd-flex justify-content-between flex-wrap flex-md-nowrap align-items-center'
  const [inpakopdracht, setInpakopdracht] = useState(null);
  const [vrachtbrief, setVrachtbrief] = useState(null);
  const [hasFixedPrijs, setHasFixedPrijs] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (verkooporder.inpakopdrachten) {
      const inpakopdrachtIndex = verkooporder.inpakopdrachten.length - 1;
      setInpakopdracht(verkooporder.inpakopdrachten[inpakopdrachtIndex]);
    }
    if (verkooporder.vrachtbrieven) {
      const vrachtbriefIndex = verkooporder.vrachtbrieven.length - 1;
      setVrachtbrief(verkooporder.vrachtbrieven[vrachtbriefIndex]);
    }
  }, [verkooporder]);

  useEffect(() => {
    setHasFixedPrijs(originalVerkoopprijs?.fixedVerkoopprijs !== '0.00');
  }, [originalVerkoopprijs]);

  const infoRows = [
    {
      label: 'Ordernummer',
      button: {
        label: <>#{verkooporder?.ordernummer}</>,
        to: `/verkooporders/edit/${verkooporder?.ordernummer}`
      },
      show: true
    },
    {
      label: 'Offerte',
      button: {
        label: <>#{verkooporder?.offerte?.offertenummer}</>,
        to: `/offertes/view/${verkooporder?.offerte?.offertenummer}`
      },
      show: verkooporder.offerte !== null
    },
    {
      label: 'Klant',
      button: {
        label: <>{verkooporder?.klant?.bedrijfsnaam}</>,
        to: `/klanten/edit/${verkooporder?.klant?.nummer}`
      },
      show: verkooporder.klant !== null
    },
    {
      label: 'Pakket',
      button: {
        label: <>{verkooporder?.pakket_naam}</>,
        to: `/pakketten/edit/${verkooporder?.pakket_id}`
      },
      show: true
    },
  ];

  return (
    <ListGroup variant="flush">
      {verkooporder.status >= 11 && verkooporder.status < 14 && (
          <AddVrachtbriefButton
            onSuccess={(data) => {
              refresh();
            }}
            onError={(error) => {
              addErrorNotification('Er is iets fout gegaan bij het maken van de vrachtbrief.');
            }}
            verkooporderId={verkooporder.id}
          />
      )}
      {infoRows && infoRows.filter(ir => ir.show).map((infoRow, i) => {
        return (
          <ListGroup.Item className={listGroupClasses} key={i}>
            <span>{infoRow.label}</span>
            <span>
              <InspectButton
                to={infoRow.button.to}
              >{infoRow.button.label}</InspectButton>
            </span>
          </ListGroup.Item>
        )
      })}
      <ListGroup.Item
        className={`${listGroupClasses} ${verkooporder.pakket_aantal <= 0 && 'bg-warning text-dark'}`}
      >
        <span>Aantal pakketten</span>
        <span>{verkooporder.pakket_aantal}</span>
      </ListGroup.Item>
      <ListGroup.Item
        className={`${listGroupClasses} ${verkooporder.artikelen_aantal <= 0 && 'bg-danger text-white'}`}
      >
        <span>Aantal artikelen</span>
        <span>{verkooporder.artikelen_aantal}</span>
      </ListGroup.Item>

      <ListGroup.Item className={listGroupClasses}>
        <span>Pakket prijs</span>
        {hasFixedPrijs && (
          <span>
            <strong>Berekend</strong>
            <hr />
            &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalExcl))}
            <span className="text-muted">{" "}excl.</span>
            <br />
            &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalIncl))}
            <span className="text-muted">{" "}incl.</span>
          </span>
        )}
        <span>
          <strong>{hasFixedPrijs ? 'Vastgezet' : 'Berekend'}</strong>
          <hr />
          &euro; {formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal))}
          <span className="text-muted">{" "}excl.</span>
          <br />
          &euro; {formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_incl))}
          <span className="text-muted">{" "}incl.</span>
        </span>
      </ListGroup.Item>
      <ListGroup.Item className={listGroupClasses}>
        <span>Totaal prijs</span>
        {hasFixedPrijs && (
          <span>
            <strong>Berekend</strong>
            <hr />
            &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalExcl * verkooporder.pakket_aantal))}
            <span className="text-muted">{" "}excl.</span>
            <br />
            &euro; {formatCurrency(parseFloat(originalVerkoopprijs.totaalIncl * verkooporder.pakket_aantal))}
            <span className="text-muted">{" "}incl.</span>
          </span>
        )}
        <span>
          <strong>{hasFixedPrijs ? 'Vastgezet' : 'Berekend'}</strong>
          <hr />
          &euro; {formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal * verkooporder.pakket_aantal))}
          <span className="text-muted">{" "}excl.</span>
          <br />
          &euro; {formatCurrency(parseFloat(verkooporder.verkoopprijs_totaal_incl * verkooporder.pakket_aantal))}
          <span className="text-muted">{" "}incl.</span>
        </span>
      </ListGroup.Item>
      <ListGroup.Item className={listGroupClasses}>
        <span>Mededeling</span>
        {verkooporder.mededeling ? (
          <span>{verkooporder.mededeling}</span>
        ) : (
          <span className="text-muted">
            <FaExclamationCircle /> {" "}
            <strong>GEEN</strong>
          </span>
        )}
      </ListGroup.Item>

      {inpakopdracht && (
        <ListGroup.Item className={listGroupClasses}>
          <span>Inpakopdracht</span>
          <InspectButton
            to={`/planning/inpakopdrachten/${inpakopdracht.id}`}
            variant="outline-primary"
            icon={<FaArrowRight />}
            iconPosition="right"
          >Inpakopdracht</InspectButton>
          {/* <Button
            size="sm"
            variant="outline-danger"
            onClick={() => history.push(`/planning/inpakopdrachten/${inpakopdracht.id}`)}
          ><FaEdit /> #{verkooporder.ordernummer}</Button> */}
        </ListGroup.Item>
      )}

      {vrachtbrief && (
        <ListGroup.Item className={listGroupClasses}>
          <span>Vrachtbrief</span>
          <ButtonGroup>
          {verkooporder.status === 14 && (
            <AddVrachtbriefButton
              size="sm"
              variant="outline-primary"
              label="Vrachtbrief"
              onSuccess={(data) => {
                refresh();
              }}
              onError={(error) => {
                addErrorNotification('Er is iets fout gegaan bij het maken van de vrachtbrief.');
              }}
              verkooporderId={verkooporder.id}
            />
          )}
            <Button
              size="sm"
              onClick={() => vrachtbriefApiService.downloadPDF(vrachtbrief.id, `Vrachtbrief-${vrachtbrief.id}.pdf`)}
            ><FaFileDownload /></Button>
            <Button
              size="sm"
              onClick={() => vrachtbriefApiService.printPDF(vrachtbrief.id)}
            ><FaPrint /></Button>
          </ButtonGroup>
        </ListGroup.Item>
      )}

    </ListGroup>
  );
};

export default VerkooporderDetailsCard;