const searchKeys = [
  {
    value: 'offertenummer',
    label: 'Offertenummer'
  },
  {
    value: 'pakket_naam',
    label: 'Pakket naam'
  },
  {
    value: 'klantnummer',
    label: 'Klantnummer'
  }
];

export default {
  default: 'offertenummer',
  searchKeys
};