import {
  FaRegClock,
  FaCheck,
  FaExclamationTriangle,
  FaRegQuestionCircle
} from 'react-icons/fa';

const inkooporderStatuses = {
  waiting:
  {
    status: 'waiting',
    variant: 'info',
    message: 'Besteld',
    icon: <FaRegClock />
  },
  partial: {
    status: 'partial',
    variant: 'warning',
    message: 'Gedeeltelijk ontvangen',
    icon: <FaExclamationTriangle />
  },
  done: {
    status: 'done',
    variant: 'success',
    message: 'Alles is ontvangen',
    icon: <FaCheck />
  },
  default: {
    status: '',
    variant: '',
    message: '',
    icon: <FaRegQuestionCircle />
  }
};

export default inkooporderStatuses;