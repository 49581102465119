import { useEffect, useState } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { FaFileImport, FaTimes } from 'react-icons/fa';

import GlobalModal from '../PaginatedTable/GlobalModal';

const ConfirmImportModal = ({ isShowing, hide, onConfirm }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isShowing) {
      setFile(null);
    }
  }, [isShowing]);

  const onCancel = () => {
    if (!isLoading) {
      setFile(null);
      hide();
    }
  };

  const selectFile = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleConfirm = () => {
    if (file && !isLoading) {
      // Show that it is in progress, not to shut the window..
      onConfirm({file});
      setLoading(true);
    };
  };

  return (
    <GlobalModal
      isShowing={isShowing}
      hide={onCancel}
      closeButton
      headerClass={'bg-dark text-light'}
      header={<span><strong>Artikelenbestand importeren</strong></span>}
      body={
        <>
          {!isLoading && (
            <>
              <Alert variant="danger">
                Artikelen in het artikelenbestand zullen worden overschreven.
              </Alert>
              <Form>
                <Form.Group as={Form.Row}>
                  <Form.Label as="legend" column sm={2}>
                    Artikelenbestand
                  </Form.Label>
                  <Col>
                    <Form.File
                      id="artikelen-import"
                      label={file ? file.name : "Voeg je document toe"}
                      data-browse="Bestand kiezen"
                      multiple={false}
                      onChange={selectFile}
                      custom
                    />
                    <Form.Text muted>Alleen <strong><em>.xlsx</em></strong> bestanden worden ondersteund.</Form.Text>
                  </Col>
                </Form.Group>
              </Form>
            </>
          )}
          {isLoading && (
            <Alert variant="info">Bezig met importeren... Blijf op deze pagina.</Alert>
          )}
        </>
      }
      footer={
        <>
          <Button
            variant="outline-secondary"
            onClick={onCancel}
            disabled={isLoading}
          ><FaTimes /> Sluiten</Button>

          <Button
            type="submit"
            variant="success"
            onClick={handleConfirm}
            disabled={!file || isLoading}
          ><FaFileImport /> Importeren</Button>
        </>
      }
    />
  )
}

export default ConfirmImportModal;