import React, { useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, ListGroup, Table } from 'react-bootstrap';
import { FaMinus, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { truncate, hellip, formatCurrency } from '../../Helpers/Helpers';
import InspectButton from '../../Helpers/InspectButton';

const states = {
  default: {
    order: 2,
    variant: '',
    icon: ''
  },
  new: {
    order: 0,
    variant: 'success',
    icon: 'new'
  },
  removed: {
    order: 3,
    variant: 'danger',
    icon: 'del'
  },
  changed: {
    order: 1,
    variant: 'primary',
    icon: 'changed'
  }
};

const getMutationStatus = (aantal, original, diff) => {
  if ((original === 0 && aantal === 0) || (diff < 0 && aantal === 0)) {
    return states.removed;
  }
  if (diff > 0) {
    if (original === 0) {
      return states.new;
    } else {
      return states.changed;
    }
  }
  if (diff < 0) {
    if (aantal > 0) {
      return states.changed;
    }
  }

  return states.default;
}

const ArtikelenTableCardList = ({ artikelen, mutate, remove }) => {
  const [sortedArtikelen, setSortedArtikelen] = useState([]);

  useEffect(() => {
    setSortedArtikelen(artikelen.map(artikel => {
      const aantal = artikel.pivot.aantal;
      const original = artikel.pivot.original_aantal;
      const diff = aantal - original;
      const status = getMutationStatus(aantal, original, diff);

      return {
        ...artikel,
        status: {
          ...status,
          diff
        }
      };
    }).sort((a, b) => {
      if (a.status.order < b.status.order)
        return -1;
      if (a.status.order > b.status.order)
        return 1;
      return 0;
    }));
  }, [artikelen]);

  return (
    <ListGroup variant="flush">
      <ListGroup.Item
        key={'headers'}
        style={{ padding: 0 }}
      >
        <Table responsive style={{ display: "table" }} >
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Omschrijving</th>
              <th>Prijs</th>
              <th>Aantal</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>
            {sortedArtikelen && sortedArtikelen.map((artikel, index) => {
              const status = artikel.status;
              return (
                <tr
                  key={index}
                  className={`table-${status.variant}`}
                >
                  <td>
                    <InspectButton
                      to={`/artikelen/show/${artikel.artikelnr}`}
                      variant="outline-primary"
                    >#{artikel.artikelnr}</InspectButton>
                  </td>
                  <td>
                    {truncate(artikel.omschrijving, 20)}{artikel.omschrijving.length > 20 ? (<span dangerouslySetInnerHTML={hellip()} />) : ''}
                  </td>
                  <td>
                    <span style={{ whiteSpace: 'nowrap'}}>&euro; {formatCurrency(parseFloat(artikel.verkoop_prijs) * artikel.pivot.aantal)}</span>
                    <br />
                    {
                      status.diff === 0
                        ? ''
                        : status.diff > 0
                          ? (
                            <Badge
                              variant="info"
                            >+ &euro; {`${formatCurrency(parseFloat(artikel.verkoop_prijs) * status.diff)}`}</Badge>
                          )
                          : (
                            <Badge
                              variant="info"
                            >- &euro; {`${formatCurrency(parseFloat(artikel.verkoop_prijs) * Math.abs(status.diff))}`}</Badge>
                          )
                    }
                  </td>
                  <td>
                    {artikel.pivot.aantal} / <span className="text-muted small">{artikel.pivot.original_aantal}</span>
                    <br />
                    {
                      status.diff === 0
                        ? ''
                        : status.diff > 0
                          ? (
                            <Badge
                              variant="info"
                            >{`+ ${status.diff}`}</Badge>)
                          : (
                            <Badge
                              variant="info"
                            >{`- ${Math.abs(status.diff)}`}</Badge>
                          )
                    }
                  </td>
                  <td>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        onClick={() => mutate && mutate(artikel.id, 1)}
                      ><FaPlus /></Button>
                      <Button
                        size="sm"
                        onClick={() => mutate && mutate(artikel.id, -1)}
                      ><FaMinus /></Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => remove && remove(artikel.id)}
                    ><FaTrashAlt /></Button>
                    </ButtonGroup>
                    {" "}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ListGroup.Item>
    </ListGroup>
  );
};
export default ArtikelenTableCardList;