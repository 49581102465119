import React, { useState, useEffect } from 'react';
import { Table, Button, ButtonGroup } from 'react-bootstrap';
import { FaEdit, FaFileDownload, FaPrint } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { formatCurrency } from '../../Helpers/Helpers';
import DefaultTooltip from '../../Helpers/DefaultTooltip';
import ApiService from '../../Services/VerkooporderService';
import InspectButton from '../../Helpers/InspectButton';

const VerkooporderTableRow = ({ verkooporder, statussen }) => {
  let history = useHistory();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const newStatus = statussen && statussen.find(s => s.id === verkooporder.status);
    setStatus(newStatus);
  }, [statussen]);

  return (
    <tr>
      <td className="align-middle">
        <InspectButton
          variant="link"
          to={`/verkooporder/edit/${verkooporder.ordernummer}`}
        >{verkooporder.ordernummer}</InspectButton>
      </td>
      <td className="align-middle">
        {verkooporder.pakket_naam}
      </td>
      <td className="align-middle text-center">
        {status && (
          <DefaultTooltip
            text={status.omschrijving}
          >{status.code}</DefaultTooltip>
        )}
      </td>
      <td className="align-middle">
        <InspectButton
          variant="link"
          to={`/klanten/edit/${verkooporder.klant.nummer}`}
        >{verkooporder.klant.bedrijfsnaam}</InspectButton>
      </td>
      <td className="align-middle">{verkooporder.bezorgdatum ? verkooporder.bezorgdatum : 'ONBEKEND' }</td>
      <td className="align-middle">&euro; {formatCurrency(verkooporder.verkoopprijs_totaal)}</td>
      <td className="align-middle">
        <ButtonGroup>
          <Button
            variant="danger"
            onClick={() => history.push(`/verkooporders/edit/${verkooporder.ordernummer}`)}
          ><FaEdit /></Button>
          <Button onClick={() => ApiService.downloadPDF(verkooporder.id, `Orderbevestiging-${verkooporder.ordernummer}.pdf`)}>
            <FaFileDownload />
          </Button>
          <Button onClick={() => ApiService.printPDF(verkooporder.id)}>
            <FaPrint />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

const VerkooporderTable = ({ verkooporders }) => {
  const [statussen, setStatussen] = useState([]);

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    ApiService.getStatussen()
      .then(response => {
        setStatussen(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Table striped responsive bordered>
      <thead className="thead-dark">
        <tr>
          <th>Ordernummer</th>
          <th>Pakketnaam</th>
          <th>Status</th>
          <th>Klant</th>
          <th>Bezorgdatum</th>
          <th>Prijs</th>
          <th>Acties</th>
        </tr>
      </thead>
      <tbody>
        {verkooporders && verkooporders.map((verkooporder, index) => {
          return (
            <VerkooporderTableRow key={index} verkooporder={verkooporder} statussen={statussen} />
          )
        })}
      </tbody>
    </Table>
  );
};

export default VerkooporderTable;