import { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { FaFileCsv, FaFileExport, FaFileExcel, FaFileImport } from 'react-icons/fa';

import useShowing from './Hooks/useShowing';
import GlobalModal from './PaginatedTable/GlobalModal';
import ConfirmImportModal from './ExportImport/ConfirmImportModal';

const ExportImportComponent = ({
  api,
  onImportSuccess
}) => {
  const { isShowing: showConfirm, toggle: toggleShowConfirm } = useShowing();
  const [isDone, setIsDone] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const toggle = (state = false) => {
    setIsDone(state);
  };

  const onConfirm = data => {
    api.uploadImport(data)
      .then(response => {
        setIsDone(true);
      })
      .catch (e => {
        if (e.statusCode !== 500) {
          setHasErrors(true);
          setIsDone(true);
        }
      });
  };

  return (
    <>
      {!isDone && (
        <ConfirmImportModal
          isShowing={showConfirm}
          hide={toggleShowConfirm}
          onConfirm={(data) => {
            onConfirm(data);
            onImportSuccess();
          }}
        />
      )}

      <GlobalModal
        isShowing={isDone && !hasErrors}
        hide={() => {
          toggle(false);
        }}
        closeButton
        headerClass={'bg-success text-light'}
        header={<span><strong>Importeren afgerond</strong></span>}
        body={
          <>
            Artikelenbestand bijgewerkt.
          </>
        }
      />
      <GlobalModal
        isShowing={isDone && hasErrors}
        hide={() => {
          toggle(false);
        }}
        closeButton
        headerClass={'bg-danger text-light'}
        header={<span><strong>Importeren afgerond</strong></span>}
        body={
          <>
            Er zijn fouten opgetreden bij het importeren. Neem contact op met de ontwikkelaar.
          </>
        }
      />

      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          variant="outline-danger"
          id="acties"
        ><FaFileImport />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={toggleShowConfirm}
          ><FaFileExcel /> Importeren <span className="text-muted small">(.xlsx)</span></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          variant="outline-primary"
          id="acties"
        ><FaFileExport /></Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              api.downloadExport(`artikelen_bestand.xlsx`, 'xlsx');
            }}
          ><FaFileExcel /> Exporteren <span className="text-muted small">(.xlsx)</span></Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              api.downloadExport(`artikelen_bestand.csv`, 'csv');
            }}
          ><FaFileCsv /> Exporteren <span className="text-muted small">(.csv)</span></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ExportImportComponent;