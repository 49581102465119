import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Table, Button, ButtonGroup, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaExclamationTriangle, FaPallet, FaSearch, FaFileDownload, FaPrint, FaEdit } from 'react-icons/fa';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/PlanningService';
import inpakopdrachtApiService from '../../Services/InpakopdrachtService';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';

const OrdersOpVoorraadList = () => {
  const [selection, setSelection] = useState([]);
  const [orders, setOrders] = useState([]);
  const [inpakopdrachten, setInpakopdrachten] = useState([]);
  const [selectionIsValid, setSelectionIsValid] = useState(false);
  const { register, errors, handleSubmit, reset, getValues, formState = { errors } } = useForm();
  const {
    notifications,
    addGenericErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();
  let history = useHistory();

  const onSubmit = fieldData => {
    const selectedOrders = !Array.isArray(fieldData['orders']) ? [fieldData['orders']] : fieldData['orders'];
    const bezorgadressen = fieldData['bezorgadressen'];
    const data = [];

    selectedOrders.map((order) => {
      const iterator = bezorgadressen[order].keys();

      const pallets = [];
      for (const key of iterator) {
        if (bezorgadressen[order][key]) {
          const palletData = {
            'order_id': order,
            'bezorgadres_id': key,
            'aantal': bezorgadressen[order][key]
          };
          pallets.push(palletData);
        }
      }

      const orderData = {
        'order_id': order,
        'pallets': pallets
      }
      data.push(orderData);
    });

    ApiService.makeInpakopdrachten(data)
      .then(response => {
        addSuccessNotification(
          <>
            De inpakopdrachten voor de volgende verkooporders zijn aangemaakt.
            <hr />
            <ListGroup>
              {response.data?.map((opdracht, i) => {
                return (
                  <ListGroup.Item key={`${opdracht.id}-${i}`}>
                    <ButtonGroup>
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          history.push(`/verkooporders/edit/${opdracht.verkooporder.ordernummer}`);
                        }}
                      ><FaSearch /> #{opdracht.verkooporder.ordernummer}</Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          history.push(`/planning/inpakopdrachten/${opdracht.verkooporder.ordernummer}`);
                        }}
                      ><FaEdit /></Button>
                    </ButtonGroup>
                    {' '}
                    <ButtonGroup>
                      <Button
                        onClick={() => inpakopdrachtApiService.downloadPDF(opdracht.id, `Inpakopdracht-${opdracht.verkooporder.ordernummer}.pdf`)}
                      ><FaFileDownload /></Button>
                      <Button
                        onClick={() => inpakopdrachtApiService.printPDF(opdracht.id)}
                      ><FaPrint /></Button>

                    </ButtonGroup>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </>,
          'Inpakopdrachten succesvol aangemaakt!'
        );
        setSelectionIsValid(false);
        reset();
        retrieveFromApi();
      })
      .catch(e => {
        addGenericErrorNotification(e);
      })
  }

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const hasVoorraad = (selection) => {
    ApiService.hasVoorraadForSelection(selection)
      .then(response => {
        setSelectionIsValid(response.data.valid);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  }
  useEffect(() => {
    const values = getValues('orders');
    if (!values) {
      setSelectionIsValid(false);
    }

    if (!Array.isArray(values)) {
      if (values) {
        hasVoorraad(values);
      }
    } else {
      if (values.length > 0) {
        const joined = values.join(',');
        hasVoorraad(joined);
      } else {
        setSelectionIsValid(false);
      }
    }
  }, [selection]);


  const retrieveFromApi = () => {
    ApiService.getAll()
      .then(response => {
        setOrders(response.data.reverse());
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  }

  const handleSelection = (event) => {
    const { name } = event.target;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setSelection({ ...selection, [name]: [value]});
  }

  const isOrderSelected = (values, id) => {
    let isSelected = false;
    if (!Array.isArray(values)) {
      if (values) {
        isSelected = values == id;
      }
    } else {
      isSelected = values.filter(o => o.includes(id)).length > 0;
    }
    return isSelected;
  }

  return (
    <>
      <Container fluid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BodyTitleBar title="Orders op voorraad">
            <Button
              type="submit"
              disabled={!selectionIsValid}
              variant={selectionIsValid ? 'success' : 'outline-success'}
            >Maak inpakopdrachten</Button>
          </BodyTitleBar>
          <AlertNotifications
            notifications={notifications}
            removeNotifications={removeNotifications}
          />
          <Row>
            <Col>
              <Table striped hover bordered>
                <thead className="thead-dark">
                  <tr>
                    <th>&nbsp;</th>
                    <th>#</th>
                    <th>Klant</th>
                    <th>Bezorgdatum</th>
                    <th>Aantal pakketten</th>
                    <th>Bezorgadressen</th>
                    <th>Pallets</th>
                  </tr>
                </thead>
                <tbody>
                  {orders && orders.map((order, index) => {
                    const formatter = new Intl.DateTimeFormat('nl-nl', {
                      day: '2-digit',
                      month: "2-digit",
                      year: "numeric"
                    });
                    const bezorgdatum = new Date(order.bezorgdatum);
                    const values = getValues('orders');
                    let isSelected = isOrderSelected(values, order.id);
                    return (
                      <Fragment key={index}>
                        <tr
                          className={`
                            ${(
                              isSelected
                                ? selectionIsValid
                                  ? 'table-success'
                                  : 'table-danger'
                                : order.bezorgadressen.length === 0
                                  ? 'table-warning'
                                  : ''
                              )
                            }
                          `}
                        >
                          <td rowSpan={order.bezorgadressen.length + 1}>
                            <input
                              type="hidden"
                              name={`orderIds[${index}]`}
                              value={order.id}
                              ref={register}
                            />
                            {
                              order.bezorgadressen.length === 0
                                ? <FaExclamationTriangle />
                                : (
                                  <input
                                    disabled={order.bezorgadressen.length === 0}
                                    type="checkbox"
                                    name="orders"
                                    value={order.id}
                                    ref={register}
                                    onChange={handleSelection}
                                  />
                                )
                            }
                          </td>
                          <td>
                            <Link
                              to={`/verkooporders/edit/${order.ordernummer}`}
                            >{order.ordernummer}</Link>
                          </td>
                          <td>
                            <Link
                              to={`/klanten/edit/${order.klantnummer}`}
                            >{order.klant.bedrijfsnaam}</Link>
                          </td>
                          <td>{formatter.format(bezorgdatum)}</td>
                          <td>Totaal: <strong>{order.pakket_aantal}</strong></td>
                          <td colSpan={2}>{order.bezorgadressen.length}</td>
                        </tr>
                        {order.bezorgadressen && order.bezorgadressen.map((adres, bIndex) => {
                          if (adres.pivot.aantal <= 0) {
                            return <tr key={bIndex}></tr>;
                          }
                          return (
                            <tr
                              className={`${(isSelected ? (selectionIsValid ? "table-success" : "table-danger") : "")}`}
                              key={bIndex}
                            >
                              <td colSpan={3}>&nbsp;</td>
                              <td>{adres.pivot.aantal}</td>
                              <td>{adres.adres} </td>
                              <td>
                                <input
                                  type="hidden"
                                  name={`adresIndices[${order.id}][${adres.id}]`}
                                  value={adres.id}
                                  ref={register}
                                />
                                <InputGroup>
                                  <FormControl
                                    name={`bezorgadressen[${order.id}][${adres.id}]`}
                                    defaultValue={adres.pivot.pallet_aantal}
                                    ref={register({
                                      validate: {
                                        required: (value) => {
                                          const checkedOrders = getValues()['orderIds'];
                                          const orderSelected = checkedOrders.filter(co => co == order.id).length;
                                          if (orderSelected && value) {
                                            return true;
                                          } else if (orderSelected && !value) {
                                            return "Verplicht veld";
                                          }
                                          return true;
                                        }
                                      }
                                    })}
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text><FaPallet /></InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  )
};

export default OrdersOpVoorraadList;