import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap';
import apiService from '../../Services/VrachtbriefService';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import VrachtbriefTable from './VrachtbriefTable';

const VrachtbriefList = () => {
  const [key, setKey] = useState('openstaande');
  const [vrachtbrieven, setVrachtbrieven] = useState([]);
  const {
    notifications,
    addGenericErrorNotification,
    removeNotifications
  } = useAlertNotifications();

  useEffect(() => {
    retrieveFromApi();
  }, []);

  const retrieveFromApi = () => {
    apiService.getAll()
      .then(response => {
        setVrachtbrieven(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  }

  return (
    <>
      <BodyTitleBar title="Vrachtbrieven" />
      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey={'openstaande'}
              activeKey={key}
              onSelect={(k) => setKey(k)}
              transition={false}
            >
              <Tab eventKey="openstaande" title="Openstaande">
                <VrachtbriefTable
                  vrachtbrieven={vrachtbrieven?.filter(b => b.verkooporder?.status <= 14)}
                />
              </Tab>
              <Tab eventKey="verzonden" title="Verzonden">
                <VrachtbriefTable
                  vrachtbrieven={vrachtbrieven?.filter(b => b.verkooporder?.status > 14)}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default VrachtbriefList;