import { formatCurrency, getBtwStringShorthand } from '../../../Helpers/Helpers';
import InspectButton from '../../../Helpers/InspectButton';

const artikelColumns = [
  {
    Header: 'Artikelnr',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            variant="outline-primary"
            to={`/artikelen/show/${row.artikelnr}`}
          >#{row.artikelnr}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'artikelnr'
  },
  {
    Header: 'Omschrijving',
    accessor: 'omschrijving',
    sortType: 'basic',
    id: 'omschrijving'
  },
  {
    Header: 'Prijs (inkoop)',
    accessor: (row, i) => {
      return (
        <>&euro; {formatCurrency(parseFloat(row.inkoop_prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'inkoop_prijs'
  },
  {
    Header: 'Prijs (verkoop)',
      accessor: (row, i) => {
        return (
          <>&euro; {formatCurrency(parseFloat(row.verkoop_prijs))}</>
        )
      },
    sortType: 'basic',
    id: 'verkoop_prijs'
  },
  {
    Header: 'BTW',
    accessor: (row, i) => {
      return (
        <>{getBtwStringShorthand(row.btw)}</>
      )
    },
    sortType: 'basic',
    id: 'btw'
  },
  {
    Header: 'Leverancier',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            variant="link"
            to={`/leveranciers/edit/${row.leverancier.nummer}`}
          >{row.leverancier.naam}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'leverancier_bedrijfsnaam'
  },
  {
    Header: 'Leveranciersnummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            variant="link"
            to={`/leveranciers/edit/${row.leverancier.nummer}`}
          >#{row.leverancier.nummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'leveranciersnummer'
  },
  {
    Header: 'Artikelnr (leverancier)',
    accessor: (row, i) => {
      return (
        <>{row.leverancier_artikel_nummer}</>
      )
    },
    sortType: 'basic',
    id: 'leverancier_artikelnr'
  },
  {
    Header: 'Verpakking',
    accessor: (row, i) => {
      return (
        <>
          {row.besteleenheid} à {row.inhoud}
        </>
      )
    },
    disableSortBy: true,
    id: 'besteleenheid_inhoud'
  },
  {
    Header: 'Besteleenheid',
    accessor: (row, i) => {
      return (
        <>{row.besteleenheid}</>
      )
    },
    sortType: 'basic',
    id: 'besteleenheid'
  },
  {
    Header: 'Inhoud',
    accessor: (row, i) => {
      return (
        <>{row.inhoud}</>
      )
    },
    sortType: 'basic',
    id: 'inhoud'
  }
];

export default artikelColumns;