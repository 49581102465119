import { Badge } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { formatCurrency } from "../../../Helpers/Helpers";
import InspectButton from "../../../Helpers/InspectButton";

const tableColumns = [
  {
    Header: 'Ordernummer',
    accessor: (row, i) => {
      return (
        <InspectButton
            variant="outline-danger"
            icon={<FaEdit />}
            to={`/verkooporders/edit/${row.ordernummer}`}
          >#{row.ordernummer}</InspectButton>
      )
    },
    sortType: 'basic',
    id: 'ordernummer'
  },
  {
    Header: 'Pakket naam',
    accessor: (row, i) => {
      return (
        <InspectButton
          variant="link"
          to={`/pakketten/samenstellen/${row.pakket.id}`}
        >{row.pakket_naam}</InspectButton>
      )
    },
    sortType: 'basic',
    id: 'pakket_naam'
  },
  {
    Header: 'Totaal prijs excl.',
    accessor: (row, i) => {
      const prijs = row.verkoopprijs_totaal ?? '0.00';
      return (
        <>&euro; {formatCurrency(parseFloat(prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal'
  },
  {
    Header: '# Pakketten',
    accessor: 'pakket_aantal',
    sortType: 'basic',
    id: 'pakket_aantal'
  },
  {
    Header: '# Artikelen',
    accessor: 'artikelen_aantal',
    sortType: 'basic',
    id: 'artikelen_aantal'
  },
  {
    Header: 'Bezorgdatum',
    accessor: (row, i) => {
      const formatter = new Intl.DateTimeFormat('nl-nl', {
        day: '2-digit',
        month: "2-digit",
        year: "numeric"
      });
      const bezorgdatum = new Date(row.bezorgdatum);
      return (
        <>
          {formatter.format(bezorgdatum)}
        </>
      )
    },
    sortType: 'basic',
    id: 'bezorgdatum'
  },
  {
    Header: 'Mededeling',
    accessor: 'mededeling',
    sortType: 'basic',
    id: 'mededeling'
  },
  {
    Header: 'Betaald',
    accessor: (row, i) => {
      return (
        <>
          <Badge
            variant={row.betaald ? 'success' : 'danger'}
          >{row.betaald ? 'Betaald' : 'Niet betaald'}</Badge>
        </>
      )
    },
    sortType: 'basic',
    id: 'betaald'
  },
  {
    Header: 'Klantnummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/klanten/edit/${row.klantnummer}`}
            variant="link"
          >#{row.klantnummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'klantnummer'
  },
  {
    Header: 'Klant',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/klanten/edit/${row.klant.nummer}`}
            variant="link"
          >{row.klant.bedrijfsnaam}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'klant_bedrijfsnaam'
  },
  {
    Header: 'Offertenummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            to={`/offertes/edit/${row.offerte.offertenummer}`}
            variant="link"
          >#{row.offerte.offertenummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'offertenummer'
  },
  {
    Header: 'Totaal prijs incl.',
    accessor: (row, i) => {
      const prijs = row.verkoopprijs_totaal_incl ?? '0.00';
      return (
        <>&euro; {formatCurrency(parseFloat(prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_incl'
  },
  {
    Header: 'Prijs BTW Hoog',
    accessor: (row, i) => {
      const prijs = row.verkoopprijs_totaal_btw_hoog ?? '0.00';
      return (
        <>&euro; {formatCurrency(parseFloat(prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_hoog'
  },
  {
    Header: 'Prijs BTW Laag',
    accessor: (row, i) => {
      const prijs = row.verkoopprijs_totaal_btw_laag ?? '0.00';
      return (
        <>&euro; {formatCurrency(parseFloat(prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_laag'
  },
  {
    Header: 'Prijs BTW Nul',
    accessor: (row, i) => {
      const prijs = row.verkoopprijs_totaal_btw_nul ?? '0.00';
      return (
        <>&euro; {formatCurrency(parseFloat(prijs))}</>
      )
    },
    sortType: 'basic',
    id: 'verkoopprijs_totaal_btw_nul'
  },
];

export default tableColumns;