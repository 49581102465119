import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Pagination } from 'react-bootstrap';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import ApiService from '../../Services/VoorraadService';
import { getPageFromUrl } from '../../Helpers/Helpers';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import InspectButton from '../../Helpers/InspectButton';

const VoorraadList = () => {
  const {
    notifications,
    addGenericErrorNotification,
    removeNotifications
  } = useAlertNotifications();

  const initialPagination = {
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: null,
    links: [],
    next_page_url: null,
    path: 0,
    prev_page_url: null,
    to: 0,
    total: 0
  };
  const [pagination, setPagination] = useState(initialPagination);

  useEffect(() => {
    retrieveFromApi(1);
  }, []);

  const retrieveFromApi = (page) => {
    ApiService.getAll(page)
      .then(response => {
        setPagination(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  }

  const goTo = (url) => {
    retrieveFromApi(getPageFromUrl(url));
  };

  return (
    <>
      <BodyTitleBar title="Voorraad" />
      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      <Container fluid>
        {pagination && (
          <Pagination>

            <Pagination.First
              onClick={() => goTo(pagination.first_page_url)}
              disabled={pagination.prev_page_url === null}
            />

            {pagination.links && pagination.links.map((link, index) => {
              if (link.label === '&laquo; Previous') {
                return (
                  <Pagination.Prev
                    key={`pagination-${index}`}
                    disabled={pagination.prev_page_url === null}
                    onClick={() => goTo(link.url)}
                  />
                );
              }
              if (link.label === 'Next &raquo;') {
                return (
                  <Pagination.Next
                    key={`pagination-${index}`}
                    disabled={pagination.next_page_url === null}
                    onClick={() => goTo(link.url)}
                  />
                );
              }
              return (
                <Pagination.Item
                  key={`pagination-${index}`}
                  active={link.active}
                  onClick={() => goTo(link.url)}
                >{link.label}</Pagination.Item>
              )
            })}
            <Pagination.Last
              onClick={() => goTo(pagination.last_page_url)}
              disabled={pagination.next_page_url === null}
            />
          </Pagination>
        )}
        <Row className="mt-2">
          <Col>
            <Table striped bordered hover>
              <thead className="thead-dark">
                <tr>
                  <th>Artikelnr</th>
                  <th>Omschrijving</th>
                  <th>Besteld</th>
                  <th>Breuk</th>
                  <th>Voorraad</th>
                  <th>Nodig</th>
                  <th>Totaal</th>
                  <th>Te kort</th>
                  <th>Restant</th>
                </tr>
              </thead>
              <tbody>
                {pagination.data && pagination.data.map((artikel, index) => {
                  let totaal = (artikel.besteld + artikel.voorraad);
                  let tekort = artikel.vraag - totaal;
                  let rest = totaal - artikel.vraag;
                  return (
                    <tr key={`artikel-${index}`}>
                      <td>
                        <InspectButton
                          variant='outline-primary'
                          to={`/artikelen/show/${artikel.artikelnr}`}
                        >#{artikel.artikelnr}</InspectButton>
                      </td>
                      <td>{artikel.omschrijving}</td>
                      <td>{artikel.besteld <= 0 ? '-' : artikel.besteld}</td>
                      <td>{artikel.breuk <= 0 ? '-' : artikel.breuk}</td>
                      <td>{artikel.voorraad <= 0 ? '-' : artikel.voorraad}</td>
                      <td>{artikel.vraag <= 0 ? '-' : artikel.vraag}</td>
                      <td>{totaal <= 0 ? '-' : totaal}</td>
                      <td>{tekort <= 0 ? '-' : tekort}</td>
                      <td>{rest <= 0 ? '-' : rest}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default VoorraadList;