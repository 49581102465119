import React, { useState, useRef } from 'react';
import { FaBars, FaGifts } from 'react-icons/fa';
import './Navbar.css';
import { ReactComponent as BellIcon } from '../../../images/icons/bell.svg';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import DropdownMenu from '../Nav/DropdownMenu';
import NotificationsDropdownMenu from '../Nav/NotificationsDropdownMenu';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import mergeRefs from '../../mergeRefs';
import NavItem from '../Nav/NavItem';
import { Container, Navbar as NavbarBootstrap, Nav } from 'react-bootstrap';

const Navbar = () => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const notificationsRef = useRef();
  const dropdownRef = useRef();

  const onClickOutsideHandlerNotifications = e => {
    setNotificationsOpen(false);
  }

  const onClickOutsideHandlerDropdown = e => {
    setDropdownOpen(false);
  }

  useOnClickOutside(dropdownRef, onClickOutsideHandlerDropdown);
  useOnClickOutside(notificationsRef, onClickOutsideHandlerNotifications);

  function handleOpen (nextNotificationOpenState, nextDropdownOpenState) {
    if (nextNotificationOpenState) {
      setDropdownOpen(false);
      setNotificationsOpen(true);
    } else if (nextDropdownOpenState) {
      setNotificationsOpen(false);
      setDropdownOpen(true);
    } else {
      setNotificationsOpen(false);
      setDropdownOpen(false);
    }
  }

  return (
    <NavbarBootstrap className="p-0 topbar-navbar flex-md-nowrap" variant="dark" bg="dark" sticky="top">
      <Container fluid>
        <NavbarBootstrap.Brand
          className="col-sm-4 col-md-4 col-lg-2 mr-0 px-3"
          href="/"
        >Pakket Perfect <span className="icon"><FaGifts /></span></NavbarBootstrap.Brand>
        <NavbarBootstrap.Toggle />
        <NavbarBootstrap.Collapse className="justify-content-end">

          <Nav className="me-auto">
            <NavItem
              pathTo={'/pakketten/add'} icon={<PlusIcon />} />
            <NavItem
              ref={mergeRefs(notificationsRef, dropdownRef)}
              open={dropdownOpen}
              setOpen={() => handleOpen(false, !dropdownOpen)}
              icon={<FaBars />}>
              <DropdownMenu
                setOpen={() => handleOpen(false, !dropdownOpen)}
              />
            </NavItem>
          </Nav>
        </NavbarBootstrap.Collapse>
      </Container>
    </NavbarBootstrap>
  )
};

export default Navbar;