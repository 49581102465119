import React, { useState } from 'react';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import LeverancierService from '../../Services/LeverancierService';
import { Button, Form, FormCheck } from 'react-bootstrap';

const AddLeverancier = () => {
  const initialLeverancierState = {
    id: null,
    nummer: '',
    naam: '',
    adres: '',
    postcode: '',
    plaatsnaam: '',
    land: 'NL',
    contactpersoon: '',
    telefoonnummer: '',
    mobielnummer: '',
    emailadres: '',
    emailadres_order: '',
    website: '',
    webshop: false,
  };
  const [leverancier, setLeverancier] = useState(initialLeverancierState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = event => {
    const {name, value} = event.target;
    setLeverancier({...leverancier, [name]: value});
  };

  const saveLeverancier = () => {
    LeverancierService.create(leverancier)
      .then(response => {
        setLeverancier({
          id: response.data.id,
          nummer: response.data.nummer,
          naam: response.data.naam,
          adres: response.data.adres,
          postcode: response.data.postcode,
          plaatsnaam: response.data.plaatsnaam,
          land: response.data.land,
          contactpersoon: response.data.contactpersoon,
          telefoonnummer: response.data.telefoonnummer,
          mobielnummer: response.data.mobielnummer,
          emailadres: response.data.emailadres,
          emailadres_order: response.data.emailadres_order,
          website: response.data.website,
          webshop: response.data.webshop,
        });
        setSubmitted(true);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const newLeverancier = () => {
    setLeverancier(initialLeverancierState);
    setSubmitted(false);
  };

  return (<>
    <div className="submit-form container-fluid">
      <BodyTitleBar title="Leverancier toevoegen">
        <Button
          onClick={saveLeverancier}
        >Toevoegen</Button>
      </BodyTitleBar>
      {submitted ? (
        <>
          <div className="alert alert-success" role="alert">
            Leverancier toevoegen is gelukt!
          </div>
          <button className="btn btn-success" onClick={newLeverancier}>Leeg formulier</button>
        </>
      ) : (<></>)}
        <div>
            <div className="form-group form-row">
              <div className="col">
                <label htmlFor="nummer">Leveranciernummer</label>
                <input
                  type="text"
                  className="form-control"
                  id="nummer"
                  required
                  value={leverancier.nummer}
                  onChange={handleInputChange}
                  name="nummer"
                />
              </div>
              <div className="col-md-10">
                <label htmlFor="naam">Naam</label>
                <input
                  type="text"
                  className="form-control"
                  id="naam"
                  required
                  value={leverancier.naam}
                  onChange={handleInputChange}
                  name="naam"
                />
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col">
                <label htmlFor="adres">Adres + nummer</label>
                <div className="input-group mb-3">
                  {/* <div className="input-group-prepend">
                    <span className="input-group-text">&euro;</span>
                  </div> */}
                  <input
                    type="text"
                    className="form-control"
                    id="adres"
                    required
                    value={leverancier.adres}
                    onChange={handleInputChange}
                    name="adres"
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col-md-2">
                <label htmlFor="postcode">Postcode</label>
                <input
                  type="text"
                  className="form-control"
                  id="postcode"
                  required
                  value={leverancier.postcode}
                  onChange={handleInputChange}
                  name="postcode"
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="plaatsnaam">Plaatsnaam</label>
                <input
                  type="text"
                  className="form-control"
                  id="plaatsnaam"
                  required
                  value={leverancier.plaatsnaam}
                  onChange={handleInputChange}
                  name="plaatsnaam"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="land">Land</label>
                <div className="input-group mb-3">
                  <select
                    className="custom-select"
                    id="land"
                    onChange={handleInputChange}
                    defaultValue={leverancier.land}
                    name="land"
                  >
                    <option value="NL">Nederland</option>
                    <option value="BE">België</option>
                    <option value="DE">Duitsland</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="leveranciers_nummer">Contactpersoon</label>
              <input
                type="text"
                className="form-control"
                id="contactpersoon"
                required
                value={leverancier.contactpersoon}
                onChange={handleInputChange}
                name="contactpersoon"
              />
            </div>
            <div className="form-group">
              <label htmlFor="telefoonnummer">Telefoonnummer</label>
              <input
                type="text"
                className="form-control"
                id="telefoonnummer"
                required
                value={leverancier.telefoonnummer}
                onChange={handleInputChange}
                name="telefoonnummer"
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobielnummer">Mobielnummer</label>
              <input
                type="text"
                className="form-control"
                id="mobielnummer"
                required
                value={leverancier.mobielnummer}
                onChange={handleInputChange}
                name="mobielnummer"
              />
            </div>
            <div className="form-group">
              <label htmlFor="emailadres">Contact e-mail</label>
              <input
                type="text"
                className="form-control"
                id="emailadres"
                required
                value={leverancier.emailadres}
                onChange={handleInputChange}
                name="emailadres"
              />
            </div>
            <div className="form-group">
              <label htmlFor="emailadres_order">Order e-mail</label>
              <input
                type="text"
                className="form-control"
                id="emailadres_order"
                required
                value={leverancier.emailadres_order}
                onChange={handleInputChange}
                name="emailadres_order"
              />
            </div>
            <div className="form-group">
              <label htmlFor="website">Website</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">https://</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  required
                  value={leverancier.website}
                  onChange={handleInputChange}
                  name="website"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="webshop">Heeft een webshop?</label>
              <div className="input-group mb-3">
                <FormCheck
                  id="webshop"
                  required
                  type="switch"
                  label={leverancier.webshop ? 'Ja' : 'Nee'}
                  value={leverancier.webshop}
                  onChange={() => setLeverancier(prevState => {
                    return {
                      ...prevState,
                      webshop: !prevState.webshop
                    }
                  })}
                  name="webshop"
                />
              </div>
            </div>
          </div>
        </div>
  </>);
};

export default AddLeverancier;