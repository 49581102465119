import { Badge, Row, Col } from "react-bootstrap";
import { FaPhone, FaMobileAlt, FaAt, FaUser, FaUserAlt, FaGlobeEurope, FaEdit } from "react-icons/fa";
import { formatUrl } from "../../../Helpers/Helpers";
import InspectButton from "../../../Helpers/InspectButton";


const tableColumns = [
  {
    Header: 'Leveranciersnummer',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            variant="outline-danger"
            icon={<FaEdit />}
            to={`/leveranciers/edit/${row.nummer}`}
          >#{row.nummer}</InspectButton>
        </>
      )
    },
    sortType: 'basic',
    id: 'nummer'
  },
  {
    Header: 'Naam',
    accessor: 'naam',
    sortType: 'basic',
    id: 'naam'
  },
  {
    Header: 'Contactpersoon',
    accessor: 'contactpersoon',
    sortType: 'basic',
    id: 'contactpersoon'
  },
  {
    Header: 'Telefoonnummer',
    accessor: (row, i) => {
      return (
        <>
          <FaPhone /> {row.telefoonnummer}
        </>
      )
    },
    sortType: 'basic',
    id: 'telefoonnummer'
  },
  {
    Header: 'Mobielnummer',
    accessor: (row, i) => {
      return (
        <>
          <FaMobileAlt /> {row.telefoonnummer}
        </>
      )
    },
    sortType: 'basic',
    id: 'mobielnummer'
  },
  {
    Header: 'Emailadres (contact)',
    accessor: (row, i) => {
      return (
        <>
          <FaAt /> {row.emailadres}
        </>
      )
    },
    sortType: 'basic',
    id: 'emailadres'
  },
  {
    Header: 'Emailadres (order)',
    accessor: (row, i) => {
      return (
        <>
          <FaAt /> {row.emailadres_order}
        </>
      )
    },
    sortType: 'basic',
    id: 'emailadres_order'
  },
  {
    Header: 'Adres',
    accessor: 'adres',
    sortType: 'basic',
    id: 'adres'
  },
  {
    Header: 'Postcode',
    accessor: 'postcode',
    sortType: 'basic',
    id: 'postcode'
  },
  {
    Header: 'Plaatsnaam',
    accessor: 'plaatsnaam',
    sortType: 'basic',
    id: 'plaatsnaam'
  },
  {
    Header: 'Land',
    accessor: 'land',
    sortType: 'basic',
    id: 'land'
  },
  {
    Header: 'Website',
    accessor: (row, i) => {
      return (
        <>
          <InspectButton
            icon={<FaGlobeEurope />}
            to={row.website}
          >{row.website ? formatUrl(row.website) : ''}</InspectButton>
        </>
      );
    },
    sortType: 'basic',
    id: 'website'
  },
  {
    Header: 'Webshop',
    accessor: (row, i) => {
      return (
        <Badge
          variant={row.webshop ? 'success' : 'danger'}
        >
          {row.webshop ? 'Ja' : 'Nee'}
        </Badge>
      )
    },
    sortType: 'basic',
    id: 'webshop'
  },
  {
    Header: <><FaUser /> Contact</>,
    accessor: (row, i) => {
      return (
        <>
          <Row>
            <Col sm="1">
              <FaUserAlt />
            </Col>
            <Col>
              {row.contactpersoon}
            </Col>
          </Row>
          <Row>
            <Col sm="1">
              <FaMobileAlt />
            </Col>
            <Col>
              {row.mobielnummer}
            </Col>
          </Row>
          <Row>
            <Col sm="1">
              <FaAt />
            </Col>
            <Col>
              {row.emailadres}
            </Col>
          </Row>
          <Row>
            <Col sm="1">
              <FaGlobeEurope />
            </Col>
            <Col>
              {row.website ? formatUrl(row.website) : ''}
            </Col>
          </Row>
        </>
      )
    },
    disableSortBy: true,
    id: 'contact'
  },
];

export default tableColumns;