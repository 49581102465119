import ajax from '../Helpers/http-common';

const getAll = () => {
  return ajax.get(`/planning/orders`);
};

const getAllNotOnVoorraad = () => {
  return ajax.get(`/planning/orders/no-voorraad`);
}

const makeInpakopdrachten = (data) => {
  return ajax.post(`/inpakopdrachten`, data);
}

const hasVoorraadForSelection = (selection) => {
  return ajax.get(`/planning/orders/check-valid-selection?selection=${selection}`);
}

const getInpakopdrachten = () => {
  return ajax.get('/inpakopdrachten');
}

export default {
  getAll,
  getAllNotOnVoorraad,
  makeInpakopdrachten,
  hasVoorraadForSelection,
  getInpakopdrachten,
};