import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';

import PaginatedTable from '../../Helpers/PaginatedTable/PaginatedTable';
import api from '../../Services/PalletService';

import {
  tableColumns,
  hiddenColumns,
  searchKeys,
  apiSortKeys
} from './data/tableData';

import {
  FaExclamationTriangle,
  FaSearch,
} from 'react-icons/fa';
import DropdownActionsPallet from './DropdownActionsPallet';
import TruncateTooltip from '../../Helpers/TruncateTooltip';
import InspectButton from '../../Helpers/InspectButton';

const PalletList = () => {

  const customColumns = [
    ...tableColumns,
    {
      Header: 'Klant',
      accessor: (row, i) => {
        return (
          <>
            <InspectButton
              variant="link"
              to={`/klanten/edit/${row.verkooporder?.klant?.nummer}`}
              size="sm"
            >
              <TruncateTooltip
                text={row.verkooporder?.klant?.bedrijfsnaam}
                threshold={25}
              />
            </InspectButton>
          </>
        )
      },
      sortType: 'basic',
      id: 'klantnummer'
    },
    {
      Header: 'Acties',
      accessor: (row, i) => {
        if (!row.verkooporder) {
          return (
            <>
              <FaExclamationTriangle />
            </>
          )
        }
        return (
          <>
            <ButtonGroup>
              <InspectButton
                to={`/verkooporders/edit/${row.verkooporder.ordernummer}`}
                variant="outline-secondary"
              >#{row.verkooporder.ordernummer}</InspectButton>
              <DropdownActionsPallet
                inpakopdracht={row.inpakopdracht}
                verkooporder={row.verkooporder}
                vrachtbrieven={row.vrachtbrief}
                size="sm"
              />
            </ButtonGroup>
          </>
        )
      },
      disableSortBy: true,
      id: 'acties'
    }
  ];

  return (
    <>
      <BodyTitleBar title="Pallets" />
      <Container fluid>
        <Row>
          <Col>
             <PaginatedTable
              api={api}
              tableColumns={customColumns}
              hiddenColumns={hiddenColumns}
              searchKeys={searchKeys}
              apiSortKeys={apiSortKeys}
              responsive={false}
              localStorageId={'pallets_list'}
            // onRowSelect={(dataObj, index) => setActiveArtikel(dataObj, index)}
            // showSearchInfo={searchInfo.showSearchInfo}
            // setSearchInfo={info => setSearchInfo(info)}
            // getRowProps={row => {
            //   let variant = row.original.id === currentIndex ? 'table-active' : '';
            //   return ({
            //     className: variant
            //   });
            // }}
            />

          </Col>
        </Row>
      </Container>
    </>
  )
};

export default PalletList;