import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationElement = ({ pagination, goTo }) => {
  return (
    <Pagination className="justify-content-center mb-1 flex-wrap">
      <Pagination.First
        onClick={() => goTo(pagination.first_page_url)}
        disabled={pagination.prev_page_url === null}
      />

      {pagination.links && pagination.links.map((link, index) => {
        if (link.label === '&laquo; Previous') {
          return (
            <Pagination.Prev
              key={`pagination-${index}`}
              disabled={pagination.prev_page_url === null}
              onClick={() => goTo(link.url)}
            />
          );
        }
        if (link.label === 'Next &raquo;') {
          return (
            <Pagination.Next
              key={`pagination-${index}`}
              disabled={pagination.next_page_url === null}
              onClick={() => goTo(link.url)}
            />
          );
        }
        if (link.label === '...') {
          return (
            <Pagination.Ellipsis
              key={`pagination-${index}`}
              disabled
            />
          );
        }

        return (
          <Pagination.Item
            key={`pagination-${index}`}
            active={link.active}
            onClick={() => goTo(link.url)}
          >{link.label}</Pagination.Item>
        )
      })}
      <Pagination.Last
        onClick={() => goTo(pagination.last_page_url)}
        disabled={pagination.next_page_url === null}
      />
    </Pagination>
  );
};

export default PaginationElement;