import ajax from '../Helpers/http-common';
import fileDownload from 'js-file-download';
import printJS from 'print-js';

const getAll = () => {
  return ajax.get('/inkoop/advies');
};

const bestel = (data) => {
  return ajax.post('/inkoop/bestel', data);
}

const getAllHistory = () => {
  return ajax.get('/inkooporders');
}

const getInkooporder = (ordernummer) => {
  return ajax.get(`/inkooporders/${ordernummer}`);
}

const updateVoorraad = (ordernummer, data) => {
  return ajax.put(`/voorraad/ontvangen/${ordernummer}`, data);
};

const getBase64PDF = (id, pageType = 'both') => {
  return ajax.get(`/inkooporders/pdf/base64/${id}/${pageType}`);
}

const downloadPDF = async (id, filename, pageType = 'both') => {
  const response = await ajax.get(`/inkooporders/pdf/download/${id}/${pageType}`, {
    responseType: 'blob',
  });
  fileDownload(response.data, filename);
};

const printPDF = async (id, pageType = 'both') => {
  try {
    const response = await getBase64PDF(id, pageType);
    printJS({
      printable: response.data,
      type: 'pdf',
      base64: true
    });
  } catch (e) {
    console.log(e);
  }
};

export default {
  getAll,
  getAllHistory,
  getInkooporder,
  bestel,
  getBase64PDF,
  downloadPDF,
  printPDF,
  updateVoorraad,
};