import { getPaginatedUrl } from '../Helpers/Helpers';
import ajax, { api } from '../Helpers/http-common';

const getAllUrl = (page = 1, perPage = 10, search = null, sort = null, operator = 'LIKE') => {
  return getPaginatedUrl('/leveranciers', page, perPage, search, sort, operator);
};

const getAll = () => {
  return ajax.get(`${api}/leveranciers/all`);
};

const get = id => {
  return ajax.get(`/leveranciers/${id}`);
};

const create = data => {
  return ajax.post('/leveranciers', data);
};

const update = (id, data) => {
  return ajax.put(`/leveranciers/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/leveranciers/${id}`);
};

const removeAll = () => {
  return ajax.delete('/leveranciers');
};

export default {
  getAllUrl,
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
};