import ajax from '../Helpers/http-common';

const getAll = () => {
  return ajax.get('/klanten');
};

const get = id => {
  return ajax.get(`/klanten/${id}`);
};

const create = data => {
  return ajax.post('/klanten', data);
};

const update = (id, data) => {
  return ajax.put(`/klanten/${id}`, data);
};

const remove = id => {
  return ajax.delete(`/klanten/${id}`);
};

const removeAll = () => {
  return ajax.delete('/klanten');
};

const findBy = (key, value) => {
  return ajax.get(`/klanten/find/${key}/${value}`);
};

const getBezorgadressen = (id) => {
  return ajax.get(`/klanten/${id}/bezorgadressen`);
}

const getBezorgadres = (id) => {
  return ajax.get(`/klanten/bezorgadres/${id}`);
}

const addBezorgadres = (id, data) => {
  return ajax.post(`/klanten/${id}/bezorgadres`, data);
};

const removeBezorgadres = (id) => {
  return ajax.delete(`/api/klanten/bezorgadres/${id}`);
};

const addBezorgadresWithCb = async (id, data, onSuccess, onError = () => {}) => {
  try {
    const response = await ajax.post(`/klanten/${id}/bezorgadres`, data);
    if (onSuccess) onSuccess(response);
  } catch (e) {
    if (onError) onError(e);
    else console.log(e);
  }
};

const editBezorgadres = async (bezorgadresId, data, onSuccess, onError) => {
  try {
    const response = await ajax.put(`/klanten/bezorgadres/${bezorgadresId}`, data);
    onSuccess(response);
  } catch (e) {
    onError(e);
  }
};

const exists = nummer => {
  return ajax.get(`/klanten/exists/${nummer}`);
};


export default {
  exists,
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findBy,
  addBezorgadres,
  addBezorgadresWithCb,
  editBezorgadres,
  removeBezorgadres,
  getBezorgadressen,
  getBezorgadres
};