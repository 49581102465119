import React, { useState, useRef } from 'react';

import { ReactComponent as CogIcon } from '../../../images/icons/cog.svg';
import { ReactComponent as ChevronIcon } from '../../../images/icons/chevron.svg';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrow.svg';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import AuthManager from '../../Auth/AuthManager';
import menuItems from '../../../menuItems';

const DropdownMenu = ({ setOpen = () => {}}) => {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  let history = useHistory();
  const user = AuthManager.hasUser() ? AuthManager.getUser() : null

  const calcHeight = el => {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  const DropDownTextItem = ({ leftIcon, rightIcon, children }) => {
    return (
      <span className="topbar-menu-item topbar-menu-text">
        <span className="topbar-icon-button" style={{ visibility: !leftIcon ? 'hidden' : '' }}>{leftIcon}</span>
        {children}
        <span className="topbar-icon-button" style={{ visibility: !rightIcon ? 'hidden' : '' }}>{rightIcon}</span>
      </span>
    )
  }

  const DropdownItem = ({ onClick, leftIcon, rightIcon, children }) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        className="topbar-menu-item"
        onClick={(e) => {
          e.preventDefault()
          onClick && onClick()
        }}
      >
        <span className="topbar-icon-button" style={{ visibility: !leftIcon ? 'hidden' : '' }}>{leftIcon}</span>
        {children}
        <span className="topbar-icon-button" style={{ visibility: !rightIcon ? 'hidden' : '' }}>{rightIcon}</span>
      </a>
    )
  };

  return (
    <div
      className="topbar-dropdown"
      style={{ height: menuHeight }}
    >
      <CSSTransition
        in={activeMenu === 'main'}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="topbar-menu">
          {menuItems && menuItems.map((group, index) => {
            if (group.groupName) {
              return (
                <DropdownItem
                  // leftIcon={<CogIcon />}
                  rightIcon={<ChevronIcon />}
                  onClick={() => setActiveMenu(group.slug)}
                  key={`dropdown-item-${index}`}
                >{group.groupName}</DropdownItem>
              );
            } else {
              if (group.items.length > 0) {
                return (
                  group.items.map((item, i) => {
                    return (
                      <DropdownItem
                        key={`dropdown-item-${index}-${i}`}
                        leftIcon={item.icon}
                        onClick={() => {
                          history.push(item.pathTo);
                        }}
                      >{item.label}</DropdownItem>
                    )
                  })
                );
              }
              return null;
            }
          })}
          <DropdownItem
            leftIcon={<FaSignOutAlt />}
            onClick={() => {
              AuthManager.logout();
            }}
          >Uitloggen</DropdownItem>

          <DropDownTextItem
            leftIcon={<FaUser />}
          >Ingelogd als: {user ? user.name : 'Gebruiker'}</DropDownTextItem>
        </div>
      </CSSTransition>
      {menuItems && menuItems.filter(mi => mi.groupName).map((group, index) => {
        return (
          <CSSTransition
            key={`transition-${index}`}
            in={activeMenu === group.slug}
            unmountOnExit
            timeout={500}
            classNames="menu-secondary"
            onEnter={calcHeight}
          >
            <div className={group.slug}>
              <DropdownItem
                leftIcon={<ArrowIcon />}
                onClick={() => setActiveMenu('main')}
              >Back</DropdownItem>
              {group.items && group.items.map((item, i) => {
                return (
                  <DropdownItem
                    key={`dropdown-item-${index}-${i}`}
                    leftIcon={item.icon}
                    onClick={() => {
                      history.push(item.pathTo);
                      setOpen();
                    }}
                  >{item.label}</DropdownItem>
                )
              })}
            </div>
          </CSSTransition>
        );
      })}
    </div >
  );
};

export default DropdownMenu;