import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Badge, InputGroup, ButtonGroup } from 'react-bootstrap';
import { FaArrowLeft, FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';

import BodyTitleBar from '../../Helpers/Layouts/BodyTitleBar';
import apiService from '../../Services/ArtikelService';
import LeverancierService from '../../Services/LeverancierService';
import AlertNotifications, { useAlertNotifications } from '../../Helpers/AlertNotifications';
import { EURPriceFormatToServerFormat, formatCurrency, serverPriceFormatToEURFormat } from '../../Helpers/Helpers';
import axios, { isCancel } from 'axios';

const AddArtikel = () => {
  const initialArtikelState  = {
    id: null,
    artikelnr: '',
    omschrijving: '',
    inkoop_prijs: '',
    btw: 2,
    leveranciers_nummer: '',
    leverancier_artikel_nummer: '',
    besteleenheid: 'ST',
    inhoud: 1
  };

  const initialVerkoopprijs = {
    excl: '0,00',
    incl: '0,00'
  };

  const [leveranciers, setLeveranciers] = useState([]);
  const [artikel, setArtikel] = useState(initialArtikelState);
  const [errors, setErrors] = useState({});
  const [verkoopprijs, setVerkoopprijs] = useState(initialVerkoopprijs);

  const {
    notifications,
    addGenericErrorNotification,
    addErrorNotification,
    addSuccessNotification,
    removeNotifications
  } = useAlertNotifications();

  let { artikelnr } = useParams();
  let history = useHistory();

  const handleInputChange = event => {
    const {name, value} = event.target;
    setArtikel({...artikel, [name]: value});
    resetFormError(name);
  };

  const resetFormError = (field) => {
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    });
  };

  useEffect(() => {
    retrieveFromApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelnr]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async (prijs, btw) => {
      apiService.getCalculatedVerkoopprijs(prijs, btw, 1, { cancelToken: source.token })
        .then(response => {
          let excl = response.data.verkoopprijs.excl;
          let incl = response.data.verkoopprijs.incl;
          if (!isNaN(excl)) {
            excl = formatCurrency(excl);
          } else {
            excl = '0,00';
          }
          if (!isNaN(incl)) {
            incl = formatCurrency(incl);
          } else {
            incl = '0,00';
          }

          setVerkoopprijs({
            excl: excl,
            incl: incl
          });
          if (isCancel(response)) return;
        })
        .catch(e => {
          if (isCancel(e)) return;
          if (e.response?.status === 500) {
            addGenericErrorNotification(e);
          }
        });
    };

    if (artikel.btw && artikel.inkoop_prijs) {
      const prijs = parseFloat(`${artikel.inkoop_prijs}`.replace(/,/g, '.')).toFixed(2);
      fetchData(prijs, artikel.btw);
    }

    return () => {
      setVerkoopprijs(initialVerkoopprijs);
      source.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikel.btw, artikel.inkoop_prijs]);

  const findFormErrors = () => {
    const {
      artikelnr,
      omschrijving,
      inkoop_prijs,
      // btw,
      leveranciers_nummer,
      leverancier_artikel_nummer,
      inhoud
    } = {...artikel};

    const newErrors = {};

    if ( !artikelnr || artikelnr === '') newErrors.artikelnr = 'Verplicht veld!';

    if (!omschrijving || omschrijving === '') newErrors.omschrijving = 'Verplicht veld!';
    if (!inkoop_prijs || inkoop_prijs === '') newErrors.inkoop_prijs = 'Verplicht veld!';
    // if (!btw || btw === '') newErrors.btw = 'Verplicht veld!';
    if (!leverancier_artikel_nummer || leverancier_artikel_nummer === '') newErrors.leverancier_artikel_nummer = 'Verplicht veld!';
    if (!inhoud || inhoud === '') newErrors.inhoud = 'Verplicht veld!';
    if (!leveranciers_nummer || leveranciers_nummer === '') {
      newErrors.leveranciers_nummer = 'Verplicht veld!';
    } else {
      if (leveranciers) {
        const leverancier = leveranciers.find(l => l.nummer === leveranciers_nummer);
        if (!leverancier) newErrors.leveranciers_nummer = 'Deze leverancier bestaat niet.';
      }
    }

    return newErrors;
  };

  const retrieveFromApi = () => {
    retrieveArtikel();
    retrieveLeveranciers();
  };

  const retrieveArtikel = () => {
    if (!artikelnr) {
      return;
    }
    apiService.get(artikelnr)
      .then(response => {
        setArtikel({
          artikelnr: '',
          omschrijving: response.data.omschrijving,
          inkoop_prijs: serverPriceFormatToEURFormat(response.data.inkoop_prijs),
          btw: response.data.btw,
          leveranciers_nummer: response.data.leveranciers_nummer,
          leverancier_artikel_nummer: response.data.leverancier_artikel_nummer,
          besteleenheid: response.data.besteleenheid,
          inhoud: response.data.inhoud
        });
      })
      .catch(e => {
        if (e.response?.status === 404) {
          addErrorNotification((
            <>
              Artikel met artikelnummer <strong><em>'{artikelnr}'</em></strong> is niet gevonden
            </>
          ), 'Artikel niet gevonden');
        } else {
          addGenericErrorNotification(e);
        }
      })
  };

  const retrieveLeveranciers = () => {
    LeverancierService.getAll()
      .then(response => {
        setLeveranciers(response.data);
      })
      .catch(e => {
        addGenericErrorNotification(e);
      });
  };

  const createArtikel = () => {
    let cleanArtikel = {
      ...artikel,
      inkoop_prijs: EURPriceFormatToServerFormat(artikel.inkoop_prijs),
    };

    apiService.create(cleanArtikel)
      .then(response => {
        setArtikel({
          artikelnr: '',
          omschrijving: response.data.omschrijving,
          inkoop_prijs: serverPriceFormatToEURFormat(response.data.inkoop_prijs),
          btw: response.data.btw,
          leveranciers_nummer: response.data.leveranciers_nummer,
          leverancier_artikel_nummer: response.data.leverancier_artikel_nummer,
          besteleenheid: response.data.besteleenheid,
          inhoud: response.data.inhoud
        });

        addSuccessNotification((
          <>
            <p>
              Het artikel is met succes aangemaakt.
            </p>
            {response.data.id && (
              <p>
                Bekijk en bewerk het artikel <Link to={`/artikelen/edit/${response.data.artikelnr}`}>hier</Link>. Of ga hieronder verder om nog een artikel toe te voegen.
                <hr />
                <span className="d-flex justify-content-end">
                  <Button
                    variant="success"
                    onClick={() => history.push(`/artikelen/edit/${response.data.artikelnr}`)}
                  ><FaSearch /> Naar artikel #{response.data.artikelnr}</Button>
                </span>
              </p>
            )}
          </>
        ), 'Artikel toegevoegd');
      })
      .catch(e => {
        if (e.response?.status === 400) {
          e.response?.data?.forEach(message => {
            if (message === 'The artikelnr has already been taken.') {
              setErrors({...errors, 'artikelnr': 'Artikelnummer al in gebruik.'});
            } else {
              addErrorNotification('Er is iets fout gegaan.', 'Fout!');
            }
          });
        } else {
          addGenericErrorNotification(e);
        }
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      createArtikel();
    }
  };

  const newArtikel = () => {
    setArtikel(initialArtikelState);
    history.push('/artikelen/add');
  };

  return (
    <>
      <BodyTitleBar title={`Artikel ${artikelnr ? 'dupliceren' : 'toevoegen'}`}>
        <ButtonGroup>
          <Button
            variant="success"
            onClick={handleSubmit}
          ><FaPlus /> Toevoegen</Button>
          {artikelnr && (
            <Button
              variant="outline-secondary"
              onClick={() => history.push(`/artikelen/show/${artikelnr}`)}
            ><FaArrowLeft /> Naar artikel</Button>
          )}
        </ButtonGroup>
      </BodyTitleBar>
      <AlertNotifications
        notifications={notifications}
        removeNotifications={removeNotifications}
      />
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="danger"
              onClick={newArtikel}
            ><FaTimes /> Invoervelden legen</Button>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Form.Row}>
            <Col sm={3} md={3}>
              <Form.Label>Artikelnummer <Badge variant="info">Intern</Badge></Form.Label>
              <Form.Control
                noValidate
                type="text"
                className="form-control"
                required
                id="artikelnr"
                value={artikel.artikelnr}
                onChange={handleInputChange}
                name="artikelnr"
                isInvalid={ !!errors.artikelnr }
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.artikelnr}
              </Form.Control.Feedback>
            </Col>
            <Col sm={9} md={9}>
              <Form.Label>Omschrijving</Form.Label>
              <Form.Control
                type="text"
                id="omschrijving"
                required
                value={artikel.omschrijving}
                onChange={handleInputChange}
                name="omschrijving"
                isInvalid={ !!errors.omschrijving}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.omschrijving}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Col sm={3}>
              <Form.Label>Inkoopprijs</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>&euro;</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  id="inkoop_prijs"
                  required
                  value={artikel.inkoop_prijs}
                  onChange={handleInputChange}
                  name="inkoop_prijs"
                  isInvalid={!!errors.inkoop_prijs}
                  autoComplete="off"
                  placeholder='0,00'
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.inkoop_prijs}
              </Form.Control.Feedback>
            </Col>
            <Col sm={3}>
              <Form.Label>Verkoopprijs excl.</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>&euro;</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  disabled
                  value={verkoopprijs.excl}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <Form.Label>Verkoopprijs incl.</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>&euro;</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  disabled
                  value={verkoopprijs.incl}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <Form.Label>BTW</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  custom
                  id="btw"
                  onChange={handleInputChange}
                  value={artikel.btw}
                  name="btw"
                  isInvalid={!!errors.btw}
                >
                  <option value="0">Nul</option>
                  <option value="1">Laag</option>
                  <option value="2">Hoog</option>
                </Form.Control>
                <InputGroup.Append>
                  <InputGroup.Text>BTW-groep</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Form.Row}>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Col md={3}>
              <Form.Label>Leveranciersnummer</Form.Label>
              <Form.Control
                type="text"
                id="leveranciers_nummer"
                required
                value={artikel.leveranciers_nummer}
                onChange={handleInputChange}
                name="leveranciers_nummer"
                isInvalid={!!errors.leveranciers_nummer}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.leveranciers_nummer}
              </Form.Control.Feedback>
            </Col>
            <Col md={9}>
              <Form.Label>Leverancier</Form.Label>
              <Form.Control
                as="select"
                custom
                id="leveranciers"
                onChange={handleInputChange}
                value={artikel.leveranciers_nummer}
                name="leveranciers_nummer"
                isInvalid={!!errors.leveranciers_nummer}
              >
                <option value="">Kies een leverancier</option>
                {leveranciers.map((leverancier, i) => {
                  return (
                    <option
                      key={`${leverancier.nummer}-${i}`}
                      value={leverancier.nummer}>{leverancier.naam}</option>
                  )
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.leveranciers_nummer}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Col>
              <Form.Label>Artikelnummer <Badge variant="info">Leverancier</Badge></Form.Label>
              <Form.Control
                type="text"
                id="leverancier_artikel_nummer"
                required
                value={artikel.leverancier_artikel_nummer}
                onChange={handleInputChange}
                name="leverancier_artikel_nummer"
                isInvalid={!!errors.leverancier_artikel_nummer}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.leverancier_artikel_nummer}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Col md={2}>
              <Form.Label>Besteleenheid</Form.Label>
              <Form.Control
                as='select'
                id="besteleenheid"
                onChange={handleInputChange}
                value={artikel.besteleenheid}
                name="besteleenheid"
                required
                isInvalid={!!errors.besteleenheid}
              >
                <option value="ST">ST</option>
                <option value="DS">DS</option>
                <option value="KR">KR</option>
                <option value="TR">TR</option>
              </Form.Control>
              <Form.Control.Feedback>
                {errors.besteleenheid}
              </Form.Control.Feedback>
            </Col>
            <Col md={10}>
              <Form.Label>Inhoud / Aantal</Form.Label>
              <Form.Control
                type="text"
                id="inhoud"
                isInvalid={!!errors.inhoud}
                value={artikel.inhoud}
                onChange={handleInputChange}
                name="inhoud"
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.inhoud}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Form>
      </Container>
  </>
  );
};

export default AddArtikel;