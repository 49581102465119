import React, { useEffect, useState } from 'react';
import {
  Alert,
  Row,
  Col,
  Button,
  FormControl
} from 'react-bootstrap';
import { FaExclamation, FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { makeId } from './Helpers';

export const useAlertNotifications = () => {
  const defaultAutoDismissTime = 10000;
  const [notifications, setNotifications] = useState({
    notifications: [],
  });

  function removeNotifications() {
    setNotifications(prevState => {
      return {
        ...prevState,
        notifications: prevState.notifications.filter(n => !n.isDismissable)
      }
    });
  };

  function removeNotification(id) {
    setNotifications(prevState => {
      return {
        ...prevState,
        notifications: [...prevState.notifications.filter(n => n.id !== id)]
      };
    });
  };

  function addGenericErrorNotification(error, isDismissable = true, autoDismiss = false, autoDismissTime = defaultAutoDismissTime) {
    if (error.response?.statusText && error.response?.status) {
      addErrorNotification(error.response.statusText, error.response.status, isDismissable, autoDismiss, autoDismissTime);
    } else {
      addErrorNotification('Er ging iets mis.', 'Fout!', isDismissable, autoDismiss, autoDismissTime);
    }
  };

  function addErrorNotification(message, title = null, isDismissable = true, autoDismiss = false, autoDismissTime = defaultAutoDismissTime) {
    addNotification('danger', message, title, isDismissable, autoDismiss, autoDismissTime);
  };

  function addSuccessNotification(message, title = null, isDismissable = true, autoDismiss = false, autoDismissTime = defaultAutoDismissTime) {
    addNotification('success', message, title, isDismissable, autoDismiss, autoDismissTime);
  };

  function addNotification(variant, message, title = null, isDismissable = true, autoDismiss = false, autoDismissTime = defaultAutoDismissTime) {
    const id = `${new Date().getUTCMilliseconds()}-${makeId()}`;
    const newAlert = {
      id,
      variant,
      message,
      title,
      autoDismiss,
      autoDismissTime,
      isDismissable,
      onDismiss: () => { removeNotification(id) }
    }

    setNotifications(prevState => {
      return {
        ...prevState,
        notifications: [...prevState.notifications, newAlert]
      };
    });
  };

  return {
    notifications: notifications.notifications,
    addNotification,
    addErrorNotification,
    addGenericErrorNotification,
    addSuccessNotification,
    removeNotification,
    removeNotifications
  };
};

export const notificationVariants = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  light: 'light',
  dark: 'dark'
};

const Notification = ({notification, numNotifications, index}) => {
  useEffect(() => {
    if (notification) {
      if (notification.autoDismiss) {
        const timer = setTimeout(() => {
          notification.onDismiss();
        }, notification.autoDismissTime);

        return () => clearTimeout(timer);
      }
    }
  }, [notification]);

  return (
    <Alert
      variant={notification.variant}
      onClose={notification.onDismiss}
      dismissible={notification.isDismissable}
    >
      {notification.title && (
        <>
          <Alert.Heading>
            <FaExclamationCircle /> {notification.title}
          </Alert.Heading>
          {notification.message}
        </>
      )}
      {!notification.title && (
        <>
          <FaExclamation /> {notification.message}
        </>
      )}

    </Alert>
  )
}

const AlertNotifications = ({
  notifications,
  showAmount = 1,
  removeNotifications
}) => {
  const [amount, setAmount] = useState(showAmount);
  if (!notifications || notifications.length === 0) {
    return <></>;
  }
  return (
    <>
      <Row className="mb-2">
        <Col md={9} className="d-flex flex-row justify-content-end flex-wrap flex-md-nowrap align-items-center">
          {removeNotifications && (notifications.filter(n => !n.isDismissable).length < notifications.length) && (
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                removeNotifications();
              }}
            ><FaTimes /> Sluit alle <span className="text-small">({notifications.length})</span> meldingen</Button>
          )}
          </Col>
        <Col className="d-flex flex-row justify-content-end flex-wrap flex-md-nowrap align-items-center">
          <FormControl
            as={'select'}
            size="sm"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          >
            {notifications.filter(n => !n.isDismissable).length < notifications.length && (
              <option value={-1}>Verberg meldingen</option>
            )}

            {notifications.map((_, i) => {
              if (i + 1 === notifications.length) {
                return (
                  <option key={i} value={notifications.length}>Toon alle ({notifications.length}) meldingen</option>
                )
              }
              return (
                <option
                  key={i}
                  value={(i + 1)}
                >Toon {(i + 1)} van de {notifications.length} melding{notifications.length > 1 && ('en')}</option>
              )
            })}
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          {notifications?.slice(Math.max(notifications.length - amount, 0)).map((notification, index) => {
            return <Notification notification={notification} key={index} index={index} numNotifications={notifications.length} />;
          })}
        </Col>
      </Row>
    </>
  );
};

export default AlertNotifications;